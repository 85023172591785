/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

// SELECTOR
import { assetsSelector } from '../../../store/old/Assets/Assets.selector';
import { Result } from 'antd';
import { blocksSelector } from '../../../store/old/Blocks/Blocks.selector';
import withRouter from '../../Hooks/withRouter';

class UseAsset extends React.Component {
    constructor(props) {
        super(props);

        this.state = { asset: null }
    }

    componentDidMount() {
        this.setCurrentAsset(this.props.router.params.blockId)
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.params.blockId !== prevProps.router.params.blockId) {
            this.setCurrentAsset(this.props.router.params.blockId);
        }
    }

    setCurrentAsset(paramsBlockId) {
        const block = this.props.blocks[+paramsBlockId];
        this.setState({ asset: block ? block.asset : null });
    }

    render() {
        const { asset } = this.state;
        return (
            <React.Fragment key={asset ? asset.block_id : -1}>
                {asset ? (
                    this.props.render({ asset })
                ) : (
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <Result
                            status="404"
                            title="404"
                            subTitle="Sorry, the page you visited does not exist."
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (appState, props) => {
    return {
        assets: assetsSelector(appState),
        blocks: blocksSelector(appState),
    };
};

const UseAssetConnected = withRouter(
    connect(
        mapStateToProps,
        null
    )(UseAsset)
);

const withAsset = (Component) => {
    return (componentProps) => {
        return (
            <UseAssetConnected
                render={(props) => <Component {...componentProps} {...props} />}
            />
        );
    };
};

export default withAsset;
