/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import * as d3 from 'd3';
import { OEELegend, OEEWaterfallChart } from '../../Charts';

import './ParetoWaterfall.scss';

const xAssessor = (d) => d.duration;
const yAssessor = (d) => d.name;

const ParetoWaterfall = (props) => {
    const { data } = props;
    const waterfallProps = useMemo(
        () => ({
            margin: { top: 35, left: 60, right: 20, bottom: 20 },
            xScale: d3.scaleLinear().domain([0, d3.max(data, xAssessor)]),
            yScale: d3.scaleOrdinal().domain(data.map(yAssessor)),
            xAssessor,
            yAssessor,
        }),
        [data]
    );

    return (
        <div className="pareto-waterfall">
            <OEEWaterfallChart {...props} {...waterfallProps} />
            <OEELegend />
        </div>
    );
};

export default ParetoWaterfall;
