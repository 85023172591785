/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
// import withNavigation from "../../Wrappers/HOCs/withNavigation"
import BlocksPresentation from './Blocks.component';
import withStreaming from '../../Wrappers/HOCs/withStreaming';
import { Permission } from '../../components/Permission';
import CONSTANTS from '../../Constants';
import { blocksState } from '../../../store/old/Blocks/Blocks.selector';
import { dataApiQueryParams } from '../../utils/controls';
import { eventEmitter } from '../../auxStore';
import { fetchBlockOeeData } from '../../../store/old/Data/Data.action';
import withRouter from '../../Hooks/withRouter';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

class BlocksContainer extends React.Component {
  refreshEvent;

  constructor(props) {
    super(props);

    this.refreshEvent = eventEmitter.on(CONSTANTS.EVENTS.REFRESH, () => this.refreshData());
  }

  componentWillUnmount() {
    this.refreshEvent();
    this.props.cancelTask();
  }

  refreshData() {
    this.getData(this.payload());
  }

  getData(payload) {
    const { blocks, getBlocksOEEData, entity } = this.props;
    const { block_id } = this;

    const block = blocks[block_id];
    if (!block) return;

    getBlocksOEEData(entity.entity_id, block_id, payload);
  }

  payload() {
    return dataApiQueryParams(this.props.controls);
  }

  get block_id() {
    return +this.props.router.params.blockId;
  }

  render() {
    const { authUser, blocks, controls, data, window } = this.props;
    const { block_id } = this;

    const block = blocks[block_id];

    return block ? (
      <Permission
        resource="blocks"
        forBlock
        tile={block} // use url block_id here (SET_CURRENT_BLOCK not completed before render)
      >
        <BlocksPresentation
          block={block}
          data={data}
          oee2={controls.oee2}
          authUser={authUser}
          controls={controls}
          window={window}
          onRefreshData={() => this.refreshData()}
        />
      </Permission>
    ) : null;
  }
}

const mapStateToProps = (appState) => ({
    blocks: blocksState(appState).blocks,
    data: appState.data.blocks,
    authUser: appState.auth.user,
    entity: currentEntitySelector(appState)
});

const mapDispatchToProps = (dispatch) => ({
  cancelTask: () => dispatch({ type: CONSTANTS.EVENTS.CANCEL_TASK }),
  getBlocksOEEData: (entityId, blockId, query) => dispatch(fetchBlockOeeData(entityId, blockId, query)),
});

const ConnectedBlockView = connect(mapStateToProps, mapDispatchToProps)(withRouter(BlocksContainer));

export default withStreaming(ConnectedBlockView);
