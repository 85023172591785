import { createTheme } from "@mui/material"

const theme = createTheme({
    palette: {
        primary: { main: "#C00000" },
        secondary: { main: "#006874" },
    },
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    display: "block",
                    height: "32px",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    color: "var(--secondary)",
                    textDecoration: "none",
                },
            },
        },
    },
})

export default theme
