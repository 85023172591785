export const saveCSV = (() => {
    // https://stackoverflow.com/questions/46638343/download-csv-file-as-response-on-ajax-request
    // https://stackoverflow.com/questions/24007073/open-links-made-by-createobjecturl-in-ie11

    return function (data, fileName, multiple) {
        let blob = new Blob([data], {
            type: 'octet/stream',
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // for IE
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            // for Non-IE (chrome, firefox etc.)
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.style.cssText = 'display: none';
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }
    };
})();

export const multiSaveCSV = (dataArray) => {
    function download_next(i) {
        if (i >= dataArray.length) return;
        const { data, fileName } = dataArray[i];
        saveCSV(data, fileName);
        setTimeout(() => download_next(i + 1), 500);
    }

    download_next(0);
};
