import moment from 'moment';
import { csvDatetimeFormat } from '../../utils/helpers';
import { oeeCSV } from '../../utils/oee';

const paretoCSV = (oeeWaterfallData, oeeParetoData, block) => {
    let concurrentProductionLabels = [];

    const lossesBlock = oeeCSV(oeeWaterfallData);

    const paretoHeaders = 'Issue, Total Effective Duration (hrs), Occurrence\n';
    const paretoBlock =
    paretoHeaders +
    oeeParetoData
        .map((d) => {
            const issueName = d.issue && d.issue.name ? d.issue.name : 'DELETED';
            const totalEfDuration = (d.total_effective_duration / 3600).toFixed(2);
            return `${issueName}, ${totalEfDuration}, ${d.occurrence}`;
        })
        .join('\n');

    const labels = block.labels
        .map((d) => {
            const { from, to, issue, output } = d;

            return [
                issue && issue.name ? issue.name : 'DELETED',
                moment(from).format(csvDatetimeFormat),
                d.isComplete ? moment(to).format(csvDatetimeFormat) : '-',
                d.isComplete ? d.getDuration('hours').toFixed(2) : '-',
                output,
                concurrentProductionLabels.join(','),
            ];
        })
        .join('\n');

    const labelBlock =
    '(OEE) Cause,Start,End,Duration (hrs),Output,' + '\n' + labels;

    return [lossesBlock, paretoBlock, labelBlock].join('\n\n');
};

export default paretoCSV;
