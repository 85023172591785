import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { buildUiControlState, isControlledView } from '../../utils/controls';
import { generateUrlQuery } from '../../utils/url';
import { omit, pick, pickBy } from 'lodash';
import { useAppDispatch } from '../../../store/hooks';
import { SetControls, initControlPanel } from '../../../store/old/UI/Controls/Controls.action';
import { getQueryParams, queryString } from '../../utils/service';
import { changeEntityFlow } from '../../../store/old/Entity/Entity.action';
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';
import { targetsUISetStore } from '../../../store/old/UI/TargetsUI/TargetsUI.action';
import { buildTargetsUIState } from '../../utils/targets';

const withUrlQuery = (Component) => {
    return function WrapperComponent(props) {
        const dispatch = useAppDispatch();
        const location = useLocation();
        const navigate = useNavigate();
        const entity = useSelector(currentEntitySelector);
        const app = useSelector((rootState) => rootState.app);
        const controls = useSelector(controlsState);
        const query = getQueryParams(location.search);
        const controlsSubstring = queryString(omit(query, ["entity_id"])).substring(1);
        const entitySubstring = queryString(pick(query, ["entity_id"])).substring(1);

        useEffect(() => {
            const initControlsState = controlsSubstring && isControlledView() ? 
                buildUiControlState(pickBy(query, (v) => !!v)) : 
                null;

            dispatch(initControlPanel(initControlsState, (initializedControls) => {
                if (isControlledView(location.pathname)) {
                    const query = generateUrlQuery(initializedControls, entity.entity_id);
                    navigate(`${location.pathname}${query}`, { replace: true });
                } else if (location.pathname.match('target_setting')) {
                    dispatch(targetsUISetStore(buildTargetsUIState(query)))
                } else if (location.pathname.match('user_guide')) {
                    // do nothing
                } else if (!(location.pathname.match('utilization'))) {
                    navigate(`${location.pathname}?entity_id=${entity.entity_id}`, { replace: true });
                }
            }));
        }, []);

        useEffect(() => {
            if (!app.initialized) return;
            if (!controls.initialized) return;
            if (location.search !== '') return;
            if (isControlledView(location.pathname)) {
                const query = generateUrlQuery(controls, entity.entity_id);
                navigate(`${location.pathname}${query}`, { replace: true });
            } else if (!(
                location.pathname.match('utilization') 
                || location.pathname.match('target_setting') 
                || location.pathname.match('user_guide')
            )) {
                navigate(`${location.pathname}?entity_id=${entity.entity_id}`, { replace: true });
            }
        }, [location.pathname]);

        useEffect(() => {
            if (!app.initialized) return;
            if (!controls.initialized) return;
            if (!controlsSubstring.length) return;
            const query = getQueryParams(controlsSubstring);
            if (isControlledView(location.pathname)) {
                const controlsState =  buildUiControlState(pickBy(query, (v) => !!v));
                dispatch(SetControls({ ...controlsState }));
            }
        }, [controlsSubstring]);

        useEffect(() => {
            if (!app.initialized) return;
            if (!controls.initialized) return;
            if (location.pathname.match('user_guide')) return;
            if (!entitySubstring.length) {
                if (!isControlledView()) {
                    navigate(`${location.pathname}?entity_id=${entity.entity_id}`, { replace: true });
                }
                return;
            };

            const query = getQueryParams(entitySubstring);
            if (+query.entity_id !== entity.entity_id) {
                dispatch(changeEntityFlow(Number(query.entity_id)));
            }
        }, [entitySubstring]);

        return <Component {...props} />;
    };
};

export default withUrlQuery;
