/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import { round } from 'lodash';

import AukTooltip from '../../components/AukTooltip';
import CONSTANTS from '../../Constants';
import { PercentBar } from '../../Charts/v1';
import { CUSTOM_SPAN } from '../../../store/old/UI/Controls/Controls.constants';

import { getAppropriateResolution } from '../../utils/controls';
import { generateUrlQuery } from '../../utils/url';

export const ShopfloorAssetCard = ({ data }) => {
    const navigate = useNavigate();
    const { asset, label } = data;
    const { primary, block_id } = asset;

    if (!primary) {
        return (
            <div
                className={classNames({
                    'shopfloor-asset-card': true,
                    'shopfloor-asset-card--disabled': true,
                })}
            >
                <div className="shopfloor-asset-card__label">{label}</div>
                <div className="shopfloor-asset-card__body">Output undefined.</div>
                <div className="shopfloor-asset-card__footer">
          No primary chart defined.
                </div>
            </div>
        );
    }

    if (primary.mode !== '1a') {
        return (
            <div className="shopfloor-asset-card">
                <div className="shopfloor-asset-card__label">{label}</div>
                <div className="shopfloor-asset-card__body shopfloor-asset-card__bar">
                    <PercentBar width={'0%'} color={'#367556'} />
                </div>
                <div className="shopfloor-asset-card__footer">
                    <div>Not supported</div>
                    <Popover
                        placement="right"
                        content={
                            <div style={{ width: 200 }}>
                                <p>
                  We're working on it — we aren't able to provide production
                  counts for analog inputs yet.
                                </p>
                                <p>Thank you for your patience. Stay tuned! ☺</p>
                            </div>
                        }
                    >
                        <span className="shopfloor-asset-card__footer__link">
                            Learn More
                        </span>
                    </Popover>
                </div>
            </div>
        );
    }

    const { currentShift } = data;
    const hasShift = currentShift;
    const hasShiftTarget =
    hasShift && currentShift.targets && currentShift.targets.length;
    const isLastShift = hasShift && !currentShift.is_live;

    if (!hasShift) {
        return (
            <div className="shopfloor-asset-card">
                <div className="shopfloor-asset-card__label">{label}</div>
                <div className="shopfloor-asset-card__body">No shifts found!</div>
                <div className="shopfloor-asset-card__footer">
                    <span>
                    Click here to <span className="shopfloor-asset-card__footer__link" onClick={() => navigate('/scheduling')}>add shifts</span>
                    </span>
                </div>
            </div>
        );
    }

    const { from, to } = currentShift;
    const output = currentShift.output ? currentShift.output[0].value : 0;

    const assetUrl = (() => {
        const startDate = moment(from);
        const endDate = moment(to);
        const durationSeconds = endDate.diff(startDate, 'seconds');
        const resolution = getAppropriateResolution(durationSeconds, {
            res_x: 5,
            res_period: 'minutes',
        });

        const query = generateUrlQuery({ startDate, endDate, resolution, span: CUSTOM_SPAN });
        return `${CONSTANTS.URLS.ASSET}/${block_id}${query}`
    })();

    if (!hasShiftTarget) {
        return (
            <AukTooltip.Info
                title={isLastShift ? 'Last shift' : ''}
                placement="bottom"
            >
                <Link
                    to={assetUrl}
                    className={classNames({
                        'shopfloor-asset-card': true,
                        'shopfloor-asset-card--with-transparency': isLastShift,
                    })}
                >
                    <div className="shopfloor-asset-card__label">{label}</div>
                    <div className="shopfloor-asset-card__body shopfloor-asset-card__bar">
                        <PercentBar width={'0%'} color={'#367556'} />
                    </div>
                    <div className="shopfloor-asset-card__footer">
                        <div>{output.toLocaleString()} units</div>
                        {!isLastShift ? (
                            <div>
                                <span
                                    className="shopfloor-asset-card__footer__link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        navigate(`/asset/${data.asset.block_id}/planner`)
                                    }}
                                >
                                    + Set target
                                </span>
                            </div>
                        ) : null}
                    </div>
                </Link>
            </AukTooltip.Info>
        );
    }

    const targetValue = currentShift.targets[0].value;
    const percent = round((output / targetValue) * 100, 2);
    return (
        <AukTooltip.Info title={isLastShift ? 'Last shift' : ''} placement="bottom">
            <Link
                className={classNames({
                    'shopfloor-asset-card': true,
                    'shopfloor-asset-card--with-transparency': isLastShift,
                })}
                to={assetUrl}
            >
                <div className="shopfloor-asset-card__label">{label}</div>
                <div className="shopfloor-asset-card__body shopfloor-asset-card__bar">
                    <PercentBar
                        width={`${percent > 100 ? 100 : percent}%`}
                        color="#367556"
                    />
                </div>
                <div className="shopfloor-asset-card__footer">
                    {`${output.toLocaleString()} / ${targetValue.toLocaleString()} units`}
                </div>
            </Link>
        </AukTooltip.Info>
    );
};

export const ShopfloorLineCard = ({ data }) => {
    const { line, assets: _assets, production } = data;
    const assets = useMemo(
        () => _assets.map((asset) => ({ label: asset.asset_name, asset })),
        [_assets]
    );

    return (
        <div className="shopfloor-line-card">
            <div className="shopfloor-line-card__label">{line.label}</div>
            <div className="shopfloor-line-card__content">
                {assets.map((a) => (
                    <ShopfloorAssetCard
                        key={a.asset.asset_id}
                        data={{ ...a, currentShift: production[a.asset.asset_id] }}
                    />
                ))}
            </div>
        </div>
    );
};

export const calcLineCardHeight = (containerWidth, numAssets) => {
    const lineLabelHeight = 16;
    const lineLabelMarginBottom = 8;
    const assetCardWidth = 276;
    const assetCardHeight = 95;
    const assetCardMarginRight = 8;
    const assetCardMarginBottom = 8;
    const lineCardMarginLeft = 20;
    const lineCardMarginBottom = 40;

    const numAssetsPerRow = Math.floor(
        (containerWidth - lineCardMarginLeft) /
      (assetCardWidth + assetCardMarginRight)
    );
    const numRows = Math.ceil(numAssets / numAssetsPerRow);
    return (
        lineLabelHeight +
    lineLabelMarginBottom +
    lineCardMarginBottom +
    numRows * (assetCardHeight + assetCardMarginBottom)
    );
};
