/* eslint-disable react/prop-types */

import React, { useMemo, useState } from 'react';
import { keys } from 'lodash';
import { useDispatch } from 'react-redux';
import { Empty, Form, Input, TreeSelect } from 'antd';

// TYPES / INTERFACE
// COMPONENTS
import AukButton from '../../components/AukButton';
import ConfirmationModal from '../../components/ConfirmationModal';
import ModalPanel from '../../components/ModalPanel';
import SearchableTree from '../../components/SearchableTree';
import { PanelBody, PanelFooter, PanelHeader } from '../../components/Panel';
import { defaultFilterOption, getAllChildNodes } from '../../utils/helpers';

// HELPERS
import { generateIssuesTreeData } from './IssuesTreeData';

// ACTIONS
import { updateIssues } from '../../../store/old/Issues/Issues.action';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { suspiciousCharactersRegex } from '../../utils/validate';

const ISSUE_VALIDATE = {
    NAME: [
        {
            required: true,
            message: 'Issue name is required.',
        },
        {
            whitespace: true,
            message: 'Issue name is required.',
        },
        {
            regex: suspiciousCharactersRegex,
            message: 'Name contains invalid characters (#%&*?|<>\\).',
        },
    ],
};

const getIssueKey = (issue) => {
    const childKeys = issue.children.map(i => i.issue_id).join('-');
    return `${issue.issue_id}-${childKeys}`
}


const IssueEditor = (props) => {
    const { issues, rootIssues, toggle } = props;

    const [selectedIssue, setSelectedIssue] = useState([]);

    const issuesTreeData = useMemo(
        () =>
            rootIssues.map((node) =>
                generateIssuesTreeData(node, {
                    disabled: (node) => node.hierarchy_level === 1,
                })
            ),
        [rootIssues]
    );

    const issue = useMemo(() => selectedIssue ? issues[selectedIssue[0]] : null, [selectedIssue])

    return (
        <ModalPanel className="issue-editor">
            <PanelHeader>Edit Issue</PanelHeader>
            <PanelBody
                className="d-flex justify-content-center align-items-center"
                style={{ overflow: 'hidden', height: '60vh' }}
            >
                <div className="pr-3 w-50 h-100">
                    <SearchableTree
                        search={{
                            addonBefore: <div style={{ width: 70 }}>Issues</div>,
                        }}
                        tree={{
                            treeData: issuesTreeData,
                            onSelect: (val) => setSelectedIssue(val),
                            selectedKeys: selectedIssue,
                        }}
                        data={issues}
                        parentKeyAccessor={(i) => i.parent_issue_id}
                        nodeKeyAccessor={(treeNode) => +treeNode.key}
                        defaultExpanded={keys(issues).map((k) => +k)}
                    />
                </div>
                <div
                    className="d-flex justify-content-center align-items-center flex-column w-50 h-100"
                    style={{ border: '1px solid #ddd', borderRadius: 2 }}
                >
                    <IssueForm
                        key={issue ? getIssueKey(issue) : null}
                        issue={issue}
                        close={() => setSelectedIssue([])}
                    />
                </div>
            </PanelBody>
            <PanelFooter className="p-3">
                <AukButton.Close onClick={toggle} />
            </PanelFooter>
        </ModalPanel>
    );
};

export default IssueEditor;

const singleNode = (node) => ({
    label: node.name,
    value: node.issue_id,
    key: node.issue_id,
});

const IssueForm = (props) => {
    const { issue, close } = props;

    if (!issue) return <Empty description="Select an issue to begin" />;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const {
        issue: name,
        parent_issue_id,
        children,
        oeeCategory,
        issue_id,
        parent,
        hierarchy_level,
    } = issue;

    const isPresetOeeCategory = hierarchy_level === 2;

    const [confirmation, setConfirmation] = useState(false);
    const [formChanged, setFormChanged] = useState(false);

    const descendants = new Set(getAllChildNodes(issue).map((n) => n.issue_id));
    const treeData = useMemo(() => {
        return isPresetOeeCategory
            ? [singleNode(parent)]
            : [
                generateIssuesTreeData(oeeCategory, {
                    disabled: (node) => {
                        const isSelf = node.issue_id === issue_id;
                        const isDescendant = descendants.has(node.issue_id);
                        return isSelf || isDescendant;
                    },
                }),
            ];
    }, [issue]);

    const save = (d, cb) => dispatch(updateIssues(d, () => cb && cb()));

    const handleSubmit = (data) => {
        const create = data.create
            ? data.create.map((item) => ({
                issue: item,
                parent_issue_id: issue_id,
            }))
            : [];

        const payload = {};
        create.length && (payload.create = create);

        const shouldUpdate =
      data.issue !== name || data.parent_issue_id != parent_issue_id;
        shouldUpdate &&
      (payload.update = [
          { issue: data.issue, parent_issue_id: data.parent_issue_id, issue_id },
      ]);

        save(payload);
    };

    const handleDelete = () => {
        save({ delete: [{ issue_id }] }, () => {
            setConfirmation(false); 
            close()
        });
    };

    const checkFormChanged = (allValues) => {
        return setFormChanged(
            (allValues.create && allValues.create.length) ||
        allValues.issue !== name ||
        allValues.parent_issue_id !== parent_issue_id
        );
    };

    return (
        <>
            <Form
                form={form}
                className="d-flex w-100 h-100 flex-column justify-content-between"
                key={issue_id}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                onValuesChange={(changed, all) => checkFormChanged(all)}
            >
                <div
                    className="w-100 mb-3"
                    style={{ height: 32, background: oeeCategory.color }}
                />
                {formChanged && (
                    <div
                        className="w-100 mb-3"
                        style={{ color: 'red', textAlign: 'center' }}
                    >
                        <i>--- You have unsaved changes ---</i>
                    </div>
                )}
                <div style={{ flexGrow: 1, overflow: 'auto' }} className="p-3">
                    <Form.Item
                        name="issue"
                        label="Name"
                        initialValue={name}
                        rules={ISSUE_VALIDATE.NAME}
                    >
                        <Input maxLength={80} disabled={isPresetOeeCategory} />
                    </Form.Item>
                    <Form.Item
                        name="parent_issue_id"
                        label="Parent Issue"
                        initialValue={parent_issue_id}
                        rules={[{ required: true, message: 'Parent issue is required' }]}
                    >
                        <TreeSelect
                            disabled={isPresetOeeCategory}
                            treeData={treeData}
                            treeDefaultExpandAll
                            showSearch
                            filterOption={defaultFilterOption}
                        />
                    </Form.Item>
                    <Form.List name="create" initialValue={[]}>
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    <Form.Item label="Child Issues">
                                        {children.map((child, i) => {
                                            return (
                                                <Form.Item key={i} initialValue={child.issue}>
                                                    <Input
                                                        style={{ pointerEvents: 'none', cursor: 'default' }}
                                                        value={child.issue}
                                                    />
                                                </Form.Item>
                                            );
                                        })}

                                        {fields.map((field, i) => {
                                            return (
                                                <Form.Item
                                                    key={i}
                                                    {...field}
                                                    rules={ISSUE_VALIDATE.NAME}
                                                >
                                                    <Input
                                                        maxLength={80}
                                                        addonAfter={
                                                            <span
                                                                className="d-flex justify-content-center align-items-center"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => remove(field.name)}
                                                            >
                                                                <CloseOutlined />
                                                            </span>
                                                        }
                                                    />
                                                </Form.Item>
                                            );
                                        })}

                                        <Form.Item>
                                            <AukButton.Outlined
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                        Add Child Issues
                                            </AukButton.Outlined>
                                        </Form.Item>
                                    </Form.Item>
                                </>
                            );
                        }}
                    </Form.List>
                </div>
                <div className="d-flex justify-content-between p-3">
                    <AukButton.Cancel onClick={close} />
                    {!isPresetOeeCategory && (
                        <AukButton.Delete onClick={() => setConfirmation(true)} />
                    )}
                    <AukButton.Save htmlType="submit" disabled={!formChanged} />
                </div>
            </Form>
            <ConfirmationModal
                action="Delete Issue"
                toggleConfirmation={() => setConfirmation(false)}
                showConfirmation={confirmation}
                onConfirmation={handleDelete}
                message={
                    <>
                        <p className="mb-3">
              You are about to remove <code>{name}</code>
                        </p>
                        <p>
              This will remove all nested issues and affect associated labels.
                        </p>
                        <p>Would you like to continue?</p>
                    </>
                }
            />
        </>
    );
};
