import React from "react"

import AukLogoFull from "../assets/images/auk-logo-full.png"
import "./BrowserIncompatible.scss"

const BrowserIncompatible = () => {
    const browsersSupported = [
        {
            icon: <img src="https://img.icons8.com/fluent/96/000000/chrome.png" alt="Google Chrome logo" />,
            label: "Chrome",
            href: "https://www.google.com/intl/en_sg/chrome/",
        },
        {
            icon: (
                <img src="https://img.icons8.com/fluent/96/000000/ms-edge-new.png" alt="Microsoft Edge logo" />
            ),
            label: "Edge",
            href: "https://www.microsoft.com/en-us/edge",
        },
        {
            icon: <img src="https://img.icons8.com/color/96/000000/firefox.png" alt="Firefox logo" />,
            label: "Firefox",
            href: "https://www.mozilla.org/en-US/firefox/new/",
        },
        {
            icon: <img src="https://img.icons8.com/color/96/000000/safari--v1.png" alt="Safari logo" />,
            label: "Safari",
            href: "https://support.apple.com/downloads/safari",
        },
    ]
    return (
        <div className="browser-incompatible">
            <div className="auk-logo-large">
                <img src={AukLogoFull} alt="Logo"></img>
            </div>
            <div className="browser-incompatible__body">
                <h6>Incompatible Browser</h6>
                <p>
          You may be using a browser we do not support. For the best user
          experience, please use one of the following:
                </p>
                <div className="browser-incompatible__icons">
                    {browsersSupported.map((b, i) => (
                        <BrowserIcon {...b} key={i}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

const BrowserIcon = (props: BrowserItemProps) => {
    return (
        <div className="d-flex flex-column">
            <a href={props.href}>
                <div>{props.icon}</div>
                <div style={{ textAlign: "center" }}>{props.label}</div>
            </a>
        </div>
    )
}

interface BrowserItemProps {
    icon: React.ReactElement
    label: string
    href: string
}

export default BrowserIncompatible
