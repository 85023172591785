/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DashboardContext } from './DashboardContext';
import WithLoaders from '../../Widgets/components/WithLoaders';
import { NoAccess } from '../../components/None';
import { WidgetEditable, AssetPrimary, OEESummary } from '../../Widgets';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { deleteSummaryTileRequest } from '../../../store/old/Tiles/Tiles.action';
import { deleteAsset } from '../../../store/old/Assets/Assets.action';

const { confirm } = Modal;

const blockAccessor = {
    '1': (w) => w.tile.asset.block,
    '2': (w) => w.tile.block,
};

const DashboardWidget = (props) => {
    const dispatch = useDispatch();
    const { data, gridCell } = props;
    const { entity_id } = useSelector(currentEntitySelector);
    const { count: loaders } = useSelector((s) => s.ui.loader);
    const { widget_type_id, data: widgetData, tile } = data;
    const {
        editing,
        window,
        authUser,
        canViewDashboard,
        canEditDashboard,
        canFullyEditDashboard,
        handleClickWidgetEdit,
        store,
    } = useContext(DashboardContext);

    const isLoading = useMemo(() => loaders !== 0, [loaders]);

    const getDashboardWidgetBody = useCallback(() => {
        switch (widget_type_id) {
        case 1:
            return (
                <AssetPrimary
                    data={widgetData}
                    window={window}
                    showBottleneck={true}
                    canDoubleClick={!editing}
                    view={store.viewMode}
                />
            );
        case 2:
            return (
                <OEESummary
                    data={{ ...widgetData, block_id: tile.block_id }}
                    canDoubleClick={!editing}
                />
            );
        default:
            return null;
        }
    }, [data, window]);

    const canViewBlock = (() => {
        const { policy } = authUser;
        if (!policy) return true;

        const block = blockAccessor[widget_type_id.toString()](data);
        if (!block) return true;

        if (
            policy.resource_policies.find(
                ({ resource }) => resource.resource === 'blocks'
            ).rights === 'nil'
        ) {
            return false;
        }

        // for viewing child entity blocks
        if (block.entity_id !== entity_id) {
            return true;
        }

        return policy.blocks.find((b) => b.block_id === block.block_id);
    })();

    const showEdit = useMemo(
        () =>
            editing && canViewBlock && (canEditDashboard || canFullyEditDashboard),
        [editing]
    );

    const editWidget = useCallback(() => {
        handleClickWidgetEdit(data);
    }, []);

    const deleteWidget = useCallback(() => {
        const [type, id] = data.widget_id.split('_');

        // TEMP
        if (type === 'st') {
            return dispatch(deleteSummaryTileRequest({ summary_tile_id: +id }));
        }

        if (type === 't') {
            return dispatch(deleteAsset(entity_id, data.tile.asset_id));
        }
    }, []);

    const showDeleteConfirmation = useCallback(() => {
        confirm({
            title: 'Remove Widget',
            icon: <ExclamationCircleOutlined />,
            content: 'This action is not reversible, continue?',
            onOk: () => {
                deleteWidget();
            },
        });
    }, []);

    // console.log(canViewBlock);
    const content =
    !canViewDashboard || !canViewBlock ? (
        <NoAccess
            className="w-100 h-100"
            description="You do not have access to this resource"
        />
    ) : (
        getDashboardWidgetBody()
    );

    return (
        <WidgetEditable
            data={data}
            gridCell={gridCell}
            editing={editing}
            handleDelete={showDeleteConfirmation}
            handleEdit={editWidget}
            showEdit={showEdit}
            showDelete={showEdit}
        >
            <WithLoaders isLoading={isLoading}>{content}</WithLoaders>
        </WidgetEditable>
    );
};

export default DashboardWidget;
