import { TrendReducer } from './Trend/TrendUI.reducer';
import { ControlsReducer } from './Controls/Controls.reducer';
import { UtilizationReducer } from './Utilization/Utilization.reducer';
import { DashboardReducer } from './Dashboard/Dashboard.reducer';
import { RegressionUIReducer } from './Regression/RegressionUI.reducer';
import { LoaderReducer } from './Loaders/Loader.reducer';
import { OEEReducer } from './OEE/OEE.reducer';
import { WindowReducer } from './Window/Window.reducer';
import { TargetsUIReducer } from './TargetsUI/TargetsUI.reducer';
import { combineReducers } from '@reduxjs/toolkit';

export const uiReducer = combineReducers({
    trend: TrendReducer,
    controls: ControlsReducer,
    utilization: UtilizationReducer,
    dashboard: DashboardReducer,
    regression: RegressionUIReducer,
    loader: LoaderReducer,
    oee: OEEReducer,
    window: WindowReducer,
    targets: TargetsUIReducer,
});
