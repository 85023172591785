/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import AukTooltip from './AukTooltip';

import './AukDrawerRight.scss';

const AukDrawerRight = (props) => {
    const {
        handlerTooltip = '',
        withHandler,
        onClose,
        handlerOnClick,
        handlerIcon,
        autoFocus,
        visible,
        children,
        style,
        className,
    } = props;

    const [focus, setFocus] = useState(false);

    const open = useEffect(() => {
        visible && autoFocus && setFocus(true);
    }, [visible, children]);

    const close = useCallback(() => {
        onClose && onClose();
    }, [visible]);

    const toggle = useCallback(() => {
        if (visible) return close();

        handlerOnClick && handlerOnClick();
    }, [visible]);

    useEffect(() => {
        focus && setTimeout(() => setFocus(false), 1000);
    }, [focus]);

    const handler = withHandler ? (
        <div className="auk-drawer__handler" onClick={toggle}>
            <AukTooltip.Help title={visible ? '' : handlerTooltip} placement="left">
                <div key={visible ? 1 : 0} className="auk-drawer__handler__icon">
                    {handlerIcon ? (
                        handlerIcon
                    ) : visible ? (
                        <i className="fas fa-angle-right" />
                    ) : (
                        <i className="fas fa-angle-left" />
                    )}
                </div>
            </AukTooltip.Help>
        </div>
    ) : null;

    const drawerProps = { ...props };
    delete drawerProps.withHandler;
    delete drawerProps.handlerTooltip;
    delete drawerProps.handlerOnClick;
    delete drawerProps.handlerIcon;

    return (
        <Drawer
            handler={handler}
            width={320}
            {...drawerProps}
            className={`auk-drawer ${focus ? 'auk-drawer--focused' : ''} ${
                className || ''
            }`}
            visible={visible}
            getContainer={false}
            closable={false}
        >
            {visible ? <DrawerClose onClick={close} /> : null}
            {visible ? children : null}
        </Drawer>
    );
};

const DrawerClose = ({ onClick }) => {
    return (
        <div className="auk-drawer__close" onClick={onClick}>
            <CloseOutlined />
        </div>
    );
};

export default AukDrawerRight;
