/* eslint-disable react/prop-types */
import * as d3 from 'd3';
import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { DatePicker } from 'antd';

// CONSTANTS
import CONSTANTS from '../../../Constants';

// COMPONENTS
import { OEEDials } from '../../../Widgets';
import Select from '../../../components/Select';
import {
    OEEChartTooltip,
    ColumnChartTooltip,
    AggregateChartTooltip,
} from '../../../Charts/v1/tooltips/templates';
import {
    ColumnChart,
    StackColumnChart,
    LineChart,
    StackBarChartV1,
    getStackBarLabels,
    LabelsChart,
    getStackBarYRange,
} from '../../../Charts/v1';

// HELPERS / UTILS
import { generateContinuousDomain } from '../../../utils/charts';
import { getStackColumnLabels } from '../../../Charts/v1/StackColumnChart';
import { chartOeeKeys, oeeChartAccessors } from '../../../utils/oee';
import { labelDisplayText } from '../../../utils/labels';

// ACTIONS
import { uiDatetimeFormat } from '../../../utils/helpers';
import withRouter from '../../../Hooks/withRouter';
import { generateUrlQuery } from '../../../utils/url';
import { CUSTOM_SPAN } from '../../../../store/old/UI/Controls/Controls.constants';

class TrendRowSimple extends React.Component {
    constructor(props) {
        super(props);

        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeChart = this.handleChangeChart.bind(this);
        this.handleClickToAssetView = this.handleClickToAssetView.bind(this);
    }

    handleChangeStartDate(value) {
        const { handleUpdate, row } = this.props;
        handleUpdate({
            ...row,
            start_date: value,
        });
    }

    handleChangeChart(e) {
        const { handleUpdate, row } = this.props;
        const { fusion_id, metadata_id } = e;
        handleUpdate({
            ...row,
            data_type: fusion_id ? 'fusion' : 'metadata',
            chart_id: fusion_id ? fusion_id : metadata_id,
        });
    }

    handleClickToAssetView() {
        const {
            props: {
                row: { resolution, block_id },
            },
            xDomain: [start_date, end_date],
        } = this;

        const pathname = `${CONSTANTS.URLS.ASSET}/${block_id}`;
        const query = generateUrlQuery({
            resolution,
            startDate: moment(start_date),
            endDate: moment(end_date),
            span: CUSTOM_SPAN
        });

        this.props.router.navigate(`${pathname}${query}`);
    }

    get chart() {
        const { row } = this.props;

        switch (row.metric.value) {
        case 'oee':
            return this.oeeStackColumnChart;
        case 'data':
            return this.productionChart;
        case 'aggregated':
            return this.aggregateChart;
        default:
            return null;
        }
    }

    get xDomain() {
        const {
            start_date,
            span: { x, unit },
        } = this.props.row;

        const end_date = start_date.clone().add(x, unit);
        return [start_date.toDate(), end_date.toDate()];
    }

    get labelsDiagram() {
        const { data, row } = this.props;

        let labels;
        switch (row.metric.value) {
        case 'oee':
            labels = data.labels.filter((l) =>
                l.label_values.find((v) => v.issue_id)
            );
            break;
        case 'data':
            labels = data.labels.filter(
                (l) => !l.label_values.find((v) => v.issue_id)
            );
            break;
        case 'aggregated':
            return null;
        default:
            return null;
        }

        return (
            <div className="labels" style={{ height: 18 }}>
                <LabelsChart
                    xScale={d3.scaleTime()}
                    xDomain={this.xDomain}
                    xAccessor={(d) => d.from}
                    x2Accessor={(d) => d._to}
                    data={labels}
                    colorAccessorLabel={(d) => d.color}
                    colorAccessorText={(d) => '#fafafa'}
                    textAccessor={labelDisplayText}
                    useTooltip={true}
                    htmlTooltip={labelDisplayText}
                    keyAccessor={(d) => d.label_id}
                />
            </div>
        );
    }

    get oeeStackColumnChart() {
        const { data, oee2 } = this.props;

        return (
            <StackColumnChart
                xScale={d3.scaleTime()}
                yScale={d3.scaleLinear()}
                xDomain={this.xDomain}
                yDomain={[0, 100]}
                keys={chartOeeKeys}
                data={oee2 ? data.oee.oee2 : data.oee.oee}
                getDataLabels={getStackColumnLabels}
                htmlTooltip={OEEChartTooltip}
                yAccessor={oeeChartAccessors.y}
                colorAccessor={oeeChartAccessors.color}
                useBrush={false}
            />
        );
    }

    get productionChart() {
        const {
            props: {
                data: { data },
            },
            assetDatum: { chart_type, color, mode, units },
            xDomain,
        } = this;

        let chart;
        const margin = { top: 0, left: 30, right: 15, bottom: 20 };
        const aggregate = data.aggregate(mode, units);
        const colorAccessor = () => color;
        const xAccessor = (d) => d.time;
        const yAccessor = (d) => d.val;

        switch (chart_type) {
        case CONSTANTS.CHARTS.TYPES.COLUMN:
            chart = (
                <ColumnChart
                    margin={margin}
                    colorAccessor={colorAccessor}
                    xScale={d3.scaleTime()}
                    yScale={d3.scaleLinear()}
                    data={data}
                    xDomain={xDomain}
                    yDomain={generateContinuousDomain(data, yAccessor)}
                    xAccessor={xAccessor}
                    yAccessor={yAccessor}
                    htmlTooltip={ColumnChartTooltip}
                />
            );
            break;
        case CONSTANTS.CHARTS.TYPES.LINE:
            chart = (
                <LineChart
                    margin={margin}
                    colorAccessor={colorAccessor}
                    xScale={d3.scaleTime()}
                    yScale={d3.scaleLinear()}
                    data={data}
                    xDomain={xDomain}
                    yDomain={generateContinuousDomain(data, yAccessor)}
                    xAccessor={xAccessor}
                    yAccessor={yAccessor}
                    htmlTooltip={ColumnChartTooltip}
                    focusEl="circle"
                    showYGrid={true}
                />
            );
            break;
        case CONSTANTS.CHARTS.TYPES.STATE:
            chart = (
                <ColumnChart
                    margin={margin}
                    colorAccessor={colorAccessor}
                    xScale={d3.scaleTime()}
                    yScale={d3.scaleLinear()}
                    data={data}
                    xDomain={xDomain}
                    yDomain={generateContinuousDomain(data, yAccessor)}
                    xAccessor={xAccessor}
                    yAccessor={yAccessor}
                    htmlTooltip={ColumnChartTooltip}
                />
            );
            break;
        default:
            return null;
        }

        return (
            <div className="d-flex flex-column w-100 h-100">
                <div className="chart__header">{aggregate}</div>
                <div className="w-100" style={{ flexGrow: 1 }}>
                    {chart}
                </div>
            </div>
        );
    }

    get aggregateChart() {
        const { row, data, oee2 } = this.props;

        const yAccessor = (d, i) => i;
        const chartData = oee2
            ? [data.oee.aggregate_oee2]
            : [data.oee.aggregate_oee];
        const yDomain = chartData.map(yAccessor);

        return (
            <StackBarChartV1
                xScale={d3.scaleLinear()}
                yScale={d3.scaleOrdinal()}
                yDomain={yDomain}
                getYRange={getStackBarYRange(yDomain)}
                keys={chartOeeKeys}
                data={chartData}
                useDataLabels={true}
                getDataLabels={getStackBarLabels}
                yAccessor={yAccessor}
                htmlTooltip={AggregateChartTooltip}
                colorAccessor={oeeChartAccessors.color}
            />
        );
    }

    get oeeOverall() {
        const { data } = this.props;

        const hasOEEData = !isEmpty(data.oee);
        return {
            final_effective: hasOEEData ? data.oee.overall.final_effective : 0,
            loading: hasOEEData ? data.oee.overall.loading : 0,
            availability: hasOEEData ? data.oee.overall.availability : 0,
            performance: hasOEEData ? data.oee.overall.performance : 0,
            quality: hasOEEData ? data.oee.overall.quality : 0,
        };
    }

    get assetDatum() {
        const { row, assets } = this.props;

        const asset = assets[row.asset_id];
        return asset.charts.find((c) => {
            const id = row.data_type === 'metadata' ? c.metadata_id : c.fusion_id;
            return id === row.chart_id;
        });
    }

    render() {
        const { row, data, assets, arrayAsset, oee2 } = this.props;
        const asset = assets[row.asset_id];
        return (
            <div className="trend-row main-row">
                <div className="main-row-dropdowns trend-row__dropdowns">
                    <div className="chart-remove--container">
                        <div
                            className="chart-remove"
                            onClick={() => this.props.handleRemove(row.uuid)}
                        >
                            <i className="fas fa-times"></i>
                        </div>
                    </div>
                    <div className="chart-selectors__container">
                        <Select
                            className="mb-1"
                            options={arrayAsset}
                            value={asset}
                            getOptionLabel={(opt) => opt.asset_name}
                            isDisabled
                        />
                        <DatePicker
                            allowClear={false}
                            style={{ verticalAlign: 'top', height: 20, width: '100%' }}
                            format={uiDatetimeFormat}
                            showTime={{ format: 'HH:mm' }}
                            className="mb-1"
                            value={row.start_date}
                            onChange={(e) => this.handleChangeStartDate(e)}
                        />
                        <Select
                            className="mb-1"
                            options={asset.charts}
                            value={this.assetDatum}
                            onChange={(e) => this.handleChangeChart(e)}
                            getOptionLabel={(opt) => opt.sensor || opt.chart_title}
                        />
                        <div
                            className="link-to-asset"
                            onClick={this.handleClickToAssetView}
                        >
                            <span className="mr-2">
                                <i className="fas fa-chart-bar" />
                            </span>
              Go to Asset
                        </div>
                    </div>
                </div>
                <div className="trend-row__chart-wrapper">
                    {this.labelsDiagram}
                    <div className="trend-row__chart">{this.chart}</div>
                </div>
                <div className="trend-row__oee">
                    <OEEDials
                        display="row"
                        data={this.oeeOverall}
                        oee2={oee2}
                        style={{ width: '100%', flexShrink: 0, height: '80%' }}
                        donutProps={{
                            fontSize1: 14,
                            fontSize2: 11,
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(TrendRowSimple)
