/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// CONSTANTS
import CONSTANTS from '../Constants';
import { userSelector } from '../../store/old/Authentication/Authentication.selector';
import { currentEntitySelector } from '../../store/old/Entity/Entity.selector';
import AukTooltip from './AukTooltip';

import './Sidebar.scss';
import withRouter from '../Hooks/withRouter';
import { Link } from 'react-router-dom';
import translate from '../utils/translate';

const getBasePath = (str) => {
    const items = str.split('/');
    const regex = /(:\w)|(\d)/g;

    return items.filter((s) => !s.match(regex)).join('/');
};

const isActivePath = (path, currentPath) => {
    return getBasePath(path) === getBasePath(currentPath.toLowerCase());
};

const menuItems = [
    {
        path: CONSTANTS.URLS.DASHBOARD,
        title: translate('dashboard'),
        img: <i className="fas fa-tachometer-alt i-i" />,
        breadcrumb: 'Dashboard',
        resource: 'dashboard',
    },
    {
        path: `${CONSTANTS.URLS.DASHBOARD}/production_achievement`,
        title: 'Production Achievement',
        img: <i className="fa fa-bullseye i-i" />,
        breadcrumb: 'Production Achievement',
        pilot: true,
        resource: 'dashboard',
    },
    {
        path: CONSTANTS.URLS.UTILIZATION,
        title: translate('utilizationheatmap'),
        img: <i className="fas fa-th" />,
        breadcrumb: 'Utilization Heatmap',
        resource: 'heatmap',
    },
    {
        path: CONSTANTS.URLS.SKU_ASSET_STANDARD_TIME,
        title: translate('standardtimemanagement'),
        img: <i className="fas fa-stopwatch" />,
        breadcrumb: 'Standard Time Management',
        premium: true,
        resource: 'standard_time',
    },
    {
        path: CONSTANTS.URLS.TREND,
        title: translate('trendanalysis'),
        img: <i className="fas fa-chart-line i-i" />,
        breadcrumb: 'Trend Analysis',
        resource: 'trend_analysis',
    },
    {
        path: CONSTANTS.URLS.REGRESSION,
        title: translate('regressionanalysis'),
        img: <i className="fas fa-link" />,
        breadcrumb: 'Regression Analysis',
        pilot: true,
        resource: 'regression_analysis',
    },
    {
        path: CONSTANTS.URLS.ISSUETRACKER,
        title: translate('issuetracker'),
        img: <i className="fas fa-file-alt i-i" />,
        breadcrumb: 'Issue Tracker',
        resource: 'tracked_issues',
    },
    {
        path: CONSTANTS.URLS.SEARCH,
        title: translate('labelsearch'),
        img: <i className="fas fa-search i-i" />,
        breadcrumb: 'Label Search',
        resource: 'search',
    },
    {
        path: CONSTANTS.URLS.HARDWARE_MANAGEMENT,
        title: translate('hardware'),
        img: <i className="fas fa-microchip" />,
        breadcrumb: 'Hardware Management',
        resource: 'radio_mesh',
    },
    {
        path: CONSTANTS.URLS.ASSET_MANAGEMENT,
        title: translate('assetmanagement'),
        img: <i className="fas fa-industry" />,
        breadcrumb: 'Asset Management',
        resource: 'assets',
    },
    {
        path: CONSTANTS.URLS.ISSUE_MANAGEMENT,
        title: translate('issuemanagement'),
        img: <i className="fas fa-list i-i" />,
        breadcrumb: 'Issue Management',
        resource: 'issues',
    },
    {
        path: CONSTANTS.URLS.SKU_MANAGEMENT,
        title: translate('skumanagement'),
        img: <i className="fas fa-boxes i-i" />,
        breadcrumb: 'SKU Management',
        resource: 'skus',
    },
    {
        path: CONSTANTS.URLS.NOTIFICATIONS,
        title: translate('notifications'),
        img: <i className="fas fa-bell i-i" />,
        breadcrumb: 'Notification',
        resource: 'notifications',
    },
    {
        path: CONSTANTS.URLS.USER_MANAGEMENT,
        title: translate('usermanagement'),
        img: <i className="fas fa-users i-i" />,
        breadcrumb: 'User Management',
        resource: 'users',
    },
    {
        path: CONSTANTS.URLS.SCHEDULING,
        title: translate('schedules'),
        img: <i className="fas fa-clock i-i" />,
        breadcrumb: 'Schedules',
        resource: 'schedules',
    },
    {
        path: CONSTANTS.URLS.TARGET_SETTING,
        title: translate('targetsetting'),
        img: <i className="fas fa-columns i-i" />,
        breadcrumb: 'Target Setting',
        pilot: true,
        resource: 'targets',
    },
    {
        path: CONSTANTS.URLS.PREFERENCES,
        title: translate('preferences'),
        img: <i className="fas fa-palette i-i" />,
        breadcrumb: 'Entity Preferences',
        resource: '',
    },
    {
        path: CONSTANTS.URLS.PERMISSIONS,
        title: translate('permission'),
        img: <i className="fas fa-user-tag i-i" />,
        breadcrumb: 'Permission',
        premium: true,
        resource: 'permissions',
    },
];

const SidebarSimple = (props) => {
    const dispatch = useDispatch();
    const { location } = props.router;
    const [expanded, setExpanded] = useState(false);
    const user = useSelector(userSelector);
    const { entity_id: currentEntityId } = useSelector(currentEntitySelector);

    const home = user.preference && user.preference.default_view
        ? user.preference.default_view
        : CONSTANTS.URLS.DASHBOARD;

    const filteredItems = useMemo(() => {
        const { policy } = user;
        
        return menuItems.map((r) => {
            const display = policy ? user.check_user_policy_url(r.resource) : true;
            return { ...r, display };
        });
    }, [user])

    return (
        <div className={'wrapper ' + (!expanded ? 'active' : '')}>
            <div className={'side-bar ' + (expanded ? 'active' : '')}>
                <ul>
                    <li className="menu-head" onClick={() => setExpanded(!expanded)}>
                        <span
                            className="d-flex justify-content-center"
                            style={{ width: 20 }}
                        >
                            <i className="fas fa-align-justify i" />
                        </span>
                    </li>
                    <div className="menu">
                        <SidebarItem
                            showCurrent={false}
                            current={location.pathname}
                            expanded={expanded}
                            path={`${home}?entity_id=${user.entity_id}`}
                            title={translate('Home')}
                            icon={<i className="fas fa-star i-i" />}
                        />
                        {filteredItems.map((route, i) => {
                            return (
                                route.display && (
                                    <SidebarItem
                                        key={i}
                                        current={location.pathname}
                                        expanded={expanded}
                                        path={route.path}
                                        title={route.title}
                                        icon={route.img}
                                    />
                                )
                            );
                        })}
                    </div>
                </ul>
            </div>
        </div>
    );
};

const SidebarItem = (props) => {
    const {
        current,
        expanded,
        showCurrent = true,
        path,
        title,
        icon,
    } = props;
    const active = showCurrent && isActivePath(path, current) ? 'active' : '';

    return (
        <Link className={`sidebar-link ${active}`} to={path} 
            onClick={e => {
                if (active) {
                    e.preventDefault()
                }
            }}
        >
            <div className="sidebar-link__item">
                {expanded && <SidebarItemTitle title={title} />}
                <SidebarItemIcon title={title} icon={icon} />
            </div>
        </Link>
    );
};

const SidebarItemTitle = ({ title }) => {
    return (
        <div className="d-flex" style={{ flexGrow: 1 }}>
            {title}
        </div>
    );
};

const SidebarItemIcon = ({ title, icon }) => {
    return (
        <AukTooltip.Help title={title} color="#40a9ff" placement="right">
            <div
                style={{ width: 20 }}
                className="d-flex justify-content-center align-items-center"
            >
                {icon}
            </div>
        </AukTooltip.Help>
    );
};

export default withRouter(SidebarSimple);
