/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { SummaryTile, Tile } from '../../models';
import { DashboardConstants as K } from '../../../store/old/UI/Dashboard/Dashboard.constants';
import { dataApiQueryParams } from '../../utils/controls';
import AukButton from '../../components/AukButton';
import AssetConfiguration from '../Assets/AssetConfiguration';
import DashboardCanvas from './DashboardCanvas';
import DashboardWidgetCreate from './DashboardWidgetCreate';
import DeviceInitFlow from '../Devices/DashboardDeviceInitialize';
import ModalPanel from '../../components/ModalPanel';
import SummaryTileEdit from './SummaryTileEdit';
import withStreaming from '../../Wrappers/HOCs/withStreaming';
import WidgetDragLayer from './component/WidgetDragLayer';
import { ControlButtons, ViewOptions } from './component/ControlButtons';
import { PanelBody, PanelFooter, PanelHeader } from '../../components/Panel';
import { SPAWrapper } from '../../components/SPAWrapper';
import {
    startLoading,
    stopLoading,
} from '../../../store/old/UI/Loaders/Loader.action';
import {
    fetchSummaryTilesData,
    fetchTilesData,
} from '../../../store/old/Data/Data.action';
import {
    createSummaryTileRequest,
    editSummaryTileRequest,
} from '../../../store/old/Tiles/Tiles.action';
import { DashboardContext } from './DashboardContext';
import './index.scss';
import { flash } from '../../components/Flash';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

const PANELS = {
    WIDGET_CREATE: 'widgetCreate',
    DEVICE_INIT: 'deviceInitialization',
    ASSET_EDIT: 'assetEdit',
    SUMMARY_CREATE: 'summaryCreate',
    SUMMARY_EDIT: 'summaryEdit',
};

class Dashboard extends React.Component {
    canViewDashboard;
    canEditDashboard;
    canFullyEditDashboard;

    constructor(props) {
        super(props);

        this.state = {
            mode: K.MODES.VIEW,
            show: '',
            gridSelection: undefined,
            widgetSelection: undefined,
        };

        const resourcePermission = this.getResourcePermission();

        this.canViewDashboard = resourcePermission !== 'nil';
        this.canEditDashboard = resourcePermission === 'edit';
        this.canFullyEditDashboard = resourcePermission === 'full';

        this.changeMode = this.changeMode.bind(this);
        this.getData = this.getData.bind(this);
        this.handleClickGridCell = this.handleClickGridCell.bind(this);
        this.handleClickWidgetEdit = this.handleClickWidgetEdit.bind(this);
        this.hidePanel = this.hidePanel.bind(this);

        this.createSummaryTile = this.createSummaryTile.bind(this);
        this.updateSummaryTile = this.updateSummaryTile.bind(this);
    }

    getResourcePermission = () => {
        const {
            authUser: { policy, role_name },
        } = this.props;

        if (role_name === 'owner') return 'full';

        return policy
            ? policy.resource_policies.find(
                ({ resource }) => resource.resource === 'dashboard'
            ).rights
            : role_name === 'owner'
                ? 'full'
                : role_name === 'editor'
                    ? 'edit'
                    : 'view';
    };

    changeMode = (mode) => this.setState({ mode });

    getDataQuery = (customRange) => {
        return Object.assign(
            {},
            dataApiQueryParams(this.props.controls),
            customRange && { date_range: customRange }
        );
    };

    getData = (widgetIds, resRange) => {
        const tileIds = [];
        const summaryTileIds = [];
        const { entity_id } = this.props.entity;

        widgetIds.forEach((widgetId) => {
            const [type, id] = widgetId.split('_');
            type === 't' ? tileIds.push(+id) : summaryTileIds.push(+id);
        });

        this.props.startLoading();
        if (tileIds.length) {
            this.props.getTilesData(entity_id, tileIds, resRange);
        }

        if (summaryTileIds.length) {
            this.props.getSummaryTilesData(entity_id, summaryTileIds, resRange);
        }

        this.props.stopLoading();
    };

    get window() {
        const { lower, upper } = this.props.window;
        return [new Date(lower), new Date(upper)];
    }

    handleClickGridCell = (x, y) => {
        this.setState({
            show: PANELS.WIDGET_CREATE,
            gridSelection: { x, y },
        });
    };

    handleClickWidgetEdit = (w) => {
        if (w.tile instanceof Tile) {
            return this.setState({ show: PANELS.ASSET_EDIT, widgetSelection: w });
        }

        if (w.tile instanceof SummaryTile) {
            return this.setState({ show: PANELS.SUMMARY_EDIT, widgetSelection: w });
        }
    };

    showPanel = (panel = '') => {
        this.setState({ show: panel });
    };

    hidePanel = () => {
        this.showPanel();
    };

    createSummaryTile = ({ block_id, entity_id }) => {
        const { x: position_x = 0, y: position_y = 0 } = this.state.gridSelection || {};
        this.props.createSummaryTile(
            {
                type: 'block',
                position_x,
                position_y,
                block_id,
                entity_id,
            },
            () => {
                flash({ message: 'Success! Refresh to load data', status: 'success' });
                this.hidePanel();
            }
        );
    };

    updateSummaryTile = ({ block_id, entity_id }) => {
        const { tile: summary_tile } = this.state.widgetSelection || {};

        this.props.updateSummaryTile(
            {
                summary_tile_id: summary_tile.summary_tile_id,
                block_id,
                entity_id,
            },
            () => {
                flash({ message: 'Success! Refresh to load data', status: 'success' });
                this.hidePanel();
            }
        );
    };

    render() {
        const { show, widgetSelection } = this.state;
        const {
            controls,
            store,
            canvasses,
            dataStore,
            authUser,
        } = this.props;

        const {
            getData,
            window,
            getDataQuery,
            canViewDashboard,
            canEditDashboard,
            canFullyEditDashboard,
            handleClickGridCell,
            handleClickWidgetEdit,
        } = this;

        const editing = store.mode === K.MODES.EDIT;

        const context = {
            controls,
            window,
            getData,
            store,
            editing,
            dataStore,
            getDataQuery,
            authUser,
            canViewDashboard,
            canEditDashboard,
            canFullyEditDashboard,
            handleClickGridCell,
            handleClickWidgetEdit,
        };

        const canvas = canvasses['0'];

        return (
            <DashboardContext.Provider value={context}>
                <SPAWrapper className="v1-dashboard">
                    <div className="dashboard-control with-light-shadows">
                        <ControlButtons />
                        <ViewOptions />
                    </div>
                    <DashboardCanvas canvas={canvas} widgets={canvas.widgets} />
                    {store.mode === K.MODES.EDIT && !this.state.show && (
                        <WidgetDragLayer window={window} />
                    )}
                    {show === PANELS.WIDGET_CREATE && (
                        <DashboardWidgetCreate
                            showDeviceInit={() => this.showPanel(PANELS.DEVICE_INIT)}
                            showSummarySetup={() => this.showPanel(PANELS.SUMMARY_CREATE)}
                            onCancel={this.hidePanel}
                        />
                    )}
                    {show === PANELS.DEVICE_INIT && (
                        <DeviceInitFlow
                            cancel={this.hidePanel}
                            gridSelection={this.state.gridSelection}
                        />
                    )}
                    {show === PANELS.ASSET_EDIT && widgetSelection && (
                        <ModalPanel>
                            <PanelHeader>
                Edit Asset: {widgetSelection.tile.asset.asset_name}
                            </PanelHeader>
                            <PanelBody>
                                <AssetConfiguration
                                    asset={widgetSelection.tile.asset}
                                    cancel={this.hidePanel}
                                />
                            </PanelBody>
                            <PanelFooter className="p-3 w-100 d-flex">
                                <AukButton.Cancel onClick={this.hidePanel} />
                            </PanelFooter>
                        </ModalPanel>
                    )}
                    {show === PANELS.SUMMARY_CREATE && (
                        <SummaryTileEdit
                            submit={this.createSummaryTile}
                            cancel={this.hidePanel}
                        />
                    )}
                    {show === PANELS.SUMMARY_EDIT && (
                        <SummaryTileEdit
                            submit={this.updateSummaryTile}
                            cancel={this.hidePanel}
                            summaryTile={widgetSelection.tile}
                        />
                    )}
                </SPAWrapper>
            </DashboardContext.Provider>
        );
    }
}

const mapStateToProps = (rootState) => ({
    store: rootState.ui.dashboard,
    canvasses: rootState.canvas.canvasses,
    widgets: rootState.widgets.widgets,
    dataStore: rootState.data,
    authUser: rootState.auth.user,
    tiles: rootState.tiles,
    entity: currentEntitySelector(rootState)
});

const mapDispatchToProps = (dispatch) => ({
    getSummaryTilesData: (entityId, ids, query, callback) => dispatch(fetchSummaryTilesData(entityId, ids, query, callback)),
    getTilesData: (entityId, ids, query, callback) => dispatch(fetchTilesData(entityId, ids, query, callback)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    createSummaryTile: (data, callback) => {
        dispatch(createSummaryTileRequest(data, callback));
    },
    updateSummaryTile: (data, callback) => {
        dispatch(editSummaryTileRequest(data, callback));
    },
});

const ConnectedDashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default withStreaming(ConnectedDashboard, { fetchOnMount: false });

// export default connect(mapStateToProps, mapDispatchToProps)(withStreaming(Dashboard));
