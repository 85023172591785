import { put, call, takeLatest } from 'redux-saga/effects';
import { getMapFromArr } from '../../../legacy/utils/helpers';

// SERVICES
import {
    api_createLabel,
    api_deleteLabel,
    api_updateLabel,
    api_stopLabel,
    api_getAssetsLabels,
} from './Labels.services';

// ACTION
import {
    AddLabelsResource,
    ReplaceLabelResource,
    RemoveLabelResource,
    SetLabelsResource,
} from './Labels.action';

// REDUX STORE
import { store } from '../..';

// MODEL
import { Label, parseLabelArguments } from '../../../legacy/models';

// CONSTANTS
import { LabelConstants as K } from './Labels.constants';
import { errorFlash } from '../../../legacy/components/Flash';
import { currentEntitySelector } from '../Entity/Entity.selector';

// GET MANY LABELS
function* handleFetchAssetLabels(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const { payload } = action;

        const response = yield call(
            api_getAssetsLabels,
            entity_id,
            [payload.asset_id],
            payload.query
        );

        const labels = getMapFromArr(
            response[0].map((v) => new Label(...parseLabelArguments(v))),
            'label_id'
        );

        yield put(SetLabelsResource(labels));

        if (action.callback) {
            yield action.callback(labels);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchAssetLabelsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_LABELS_REQUEST,
        handleFetchAssetLabels
    );
}

function* handleCreateLabel(action) {
    try {
        const { asset_id, data } = action.payload;
        const response = yield call(api_createLabel, asset_id, data);
        const label = new Label(...parseLabelArguments(response));

        yield put(AddLabelsResource({ [label.label_id]: label }));

        if (action.callback) {
            yield action.callback(label);
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* createLabelSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_LABEL_REQUEST,
        handleCreateLabel
    );
}

function* handleUpdateLabel(action) {
    try {
        const { asset_id, data } = action.payload;
        const { label_id: original_label_id } = data;
        const response = yield call(api_updateLabel, asset_id, data);
        const label = new Label(...parseLabelArguments(response));

        yield put(ReplaceLabelResource(original_label_id, label));

        if (action.callback) {
            yield action.callback(label);
        }
    } catch (e) {
        errorFlash(e);
        yield action.callback(false);
    }
}

export function* updateLabelSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_LABEL_REQUEST,
        handleUpdateLabel
    );
}

function* handleStopLabel(action) {
    try {
        const { asset_id, data } = action.payload;
        const { label_id: original_label_id } = data;
        const response = yield call(api_stopLabel, asset_id, data);
        const label = new Label(...parseLabelArguments(response));

        yield put(ReplaceLabelResource(original_label_id, label));

        if (action.callback) {
            yield action.callback(label);
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* stopLabelSaga() {
    yield takeLatest(
        K.ACTIONS.STOP_LABEL_REQUEST,
        handleStopLabel
    );
}

function* handleDeleteLabel(action) {
    try {
        const {
            asset_id,
            data: { label_id },
        } = action.payload;
        const response = yield call(api_deleteLabel, asset_id, label_id);

        yield put(RemoveLabelResource(label_id));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (e) {
        errorFlash(e);
    }
}

export function* deleteLabelSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_LABEL_REQUEST,
        handleDeleteLabel
    );
}
