/* eslint-disable */
import React from 'react';
import * as d3 from 'd3';
import { findDOMNode } from 'react-dom';

import { BaseChartTooltip } from './tooltips';
import { isNumber, isEqual } from 'lodash';
import {
    updateHDPICanvas,
    canvasPixelRatio,
    multiFormat,
} from '../../utils/charts';

import './Charts_V1.scss';
import { generateId, getDataSubarrByTime } from '../../utils/helpers';

const SCROLLBAR_SIZE = 10;
export class BaseChartSimple extends React.Component {
    constructor(props) {
        super(props);

        const {
            xTickFormat,
            xTickSize,
            xTicks,
            yTickFormat,
            yTickSize,
            yTicks,
        } = props;

        this.id = generateId();

        this.xAxis = d3
            .axisBottom()
            .tickFormat(xTickFormat || null)
            .tickSize(isNumber(xTickSize) ? xTickSize : 4)
            .ticks(isNumber(xTicks) ? xTicks : null)
            .tickSizeOuter(0);

        this.yAxis = d3
            .axisLeft()
            .tickFormat(yTickFormat || null)
            .tickSize(isNumber(yTickSize) ? yTickSize : 4)
            .ticks(isNumber(yTicks) ? yTicks : null);

        this.colorToNode = new Map();
    }

    componentDidMount() {
        this.el = d3.select(findDOMNode(this));
        this.svg = this.el.select('svg');
        this.g = this.svg.select('g');
        this.focusContainer = this.g.select('.focus-container');
        this.benchmarkContainer = this.g.select('.benchmark-container');
        this.markerContainer = this.g.select('.marker-container');
        this.brush = this.g.select('.brush');
        this.canvas = this.el.select('canvas.main');
        this.hidden = this.el.select('canvas.hidden');

        this.canvas.node().getContext('2d', { willReadFrequently: true });
        this.hidden.node().getContext('2d', { willReadFrequently: true });

        this.focusContainer
            .append(this.props.focusEl || 'rect')
            .attr('class', 'focus');
        this.focus = this.focusContainer.select('.focus');

        const custom = document.createElement('custom');
        this.custom = d3.select(custom);
        this.custom.append('custom').attr('class', 'y-grid');

        this.redraw();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.width !== this.props.width ||
      prevProps.height !== this.props.height ||
      prevProps.colorAccessor !== this.props.colorAccessor ||
      prevProps.useMarkers !== this.props.useMarkers ||
      prevProps.markers !== this.props.markers ||
      !isEqual(prevProps.data, this.props.data) ||
      !isEqual(prevProps.xDomain, this.props.xDomain) ||
      !isEqual(prevProps.benchmark, this.props.benchmark)
        ) {
            this.redraw();
        }
    }

    redraw() {
        setTimeout(() => {
            this.recalculate();
            this.redrawChartArea();
            this.callBrush();

            const {
                colorToNode,
                redrawCustom,
                redrawCanvas,
                redrawHidden,
                props: { duration },
            } = this;

            redrawCustom && redrawCustom();
            d3.timerFlush();
            const timer = d3.timer(function (elapsed) {
                if (elapsed > duration) {
                    timer.stop();
                }
                colorToNode.clear();
                redrawCanvas && redrawCanvas();
                redrawHidden && redrawHidden();
            });
        });
    }

    callBrush() {
        const self = this;
        const {
            x,
            w,
            h,
            props: {
                useBrush,
                onBrushEnd,
                onBrushCancel,
                xAccessor,
                snapBrush,
                pointerEvents,
                data,
                brushedDataAccessor,
            },
        } = this;

        if (!pointerEvents) return;

        if (useBrush) {
            this.brushX = d3
                .brushX()
                .handleSize(24)
                .extent([
                    [0, 0],
                    [w, h],
                ])
                .on('start', () => {
                    this.handleMouseLeave();
                })
                .on('end', () => {
                    if (!d3.event.sourceEvent) return; // Only transition after input.

                    if (!d3.event.selection) {
                        // close tooltip and reset value fields
                        this.brush.transition().call(d3.event.target.move, [null, null]);
                        onBrushCancel && onBrushCancel(this.brush);
                        return; // Ignore empty selections.
                    }

                    const { selection } = d3.event;

                    let xBound;
                    if (snapBrush) {
                        xBound = snapBrush({ x, xAccessor, selection });
                    } else {
                        xBound = selection.map(x.invert);
                    }

                    this.brush.transition().call(d3.event.target.move, xBound.map(x));
                    onBrushEnd &&
            onBrushEnd(
                xBound,
                xBound.map(x),
                this.brush,
                brushedDataAccessor(data, xBound)
            );
                });

            this.brush.call(this.brushX);
        }

        this.brush
            .on('mousemove', function () {
                const newEvent = new Event('mousemove', {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                });
                newEvent.pageX = d3.event.pageX;
                newEvent.clientX = d3.event.clientX;
                newEvent.pageY = d3.event.pageY;
                newEvent.clientY = d3.event.clientY;
                newEvent.view = window;
                self.hidden.node().dispatchEvent(newEvent); // simulate mousedown event on overlay element
            })
            .on('mouseleave', function () {
                self.handleMouseLeave();
            });

        this.hidden
            .on('mousemove', function () {
                self.handleMouseMove(this);
            })
            .on('mouseleave', function () {
                self.handleMouseLeave();
            });
    }

    recalculate() {
    // w, h, x, y
        const {
            w,
            h,
            props: {
                width,
                height,
                margin: { left, right, top, bottom },
                xScale,
                xDomain,
                getXRange,
                yScale,
                yDomain,
                getYRange,
            },
        } = this;

        const xRange = getXRange ? getXRange(w) : [0, w];
        const yRange = getYRange ? getYRange(h) : [h, 0];

        this.x = xScale.domain(xDomain).range(xRange);
        this.y = yScale.domain(yDomain).range(yRange);
    }

    redrawChartArea() {
    // axes and canvas dimensions
        const {
            g,
            x,
            y,
            w,
            props: { duration, hideXAxis, hideYAxis, transition, showYGrid },
        } = this;

        const t = transition || null;

        if (!hideXAxis) {
            this.xAxis.scale(x);
            g.select('.x-axis').transition(t).duration(duration).call(this.xAxis);
        }

        if (!hideYAxis) {
            this.yAxis.scale(y);
            g.select('.y-axis').transition(t).duration(duration).call(this.yAxis);
        }

        if (this.props.useBenchmark) {
            this.redrawBenchmark();
        }

        this.redrawMarkers();

        updateHDPICanvas(this.canvas.node(), this.w, this.h);
        updateHDPICanvas(this.hidden.node(), this.w, this.h);
    }

    redrawMarkers() {
        if (!this.props.useMarkers) {
            return this.markerContainer.selectAll('.marker').remove();
        }

        const { y, h, w } = this;

        const markers = this.props.markers
            .map((d) => +d)
            .filter((d) => isFinite(d) && y(d) <= h && y(d) >= 0);

        const t = this.props.transition || null;

        const markerSelection = this.markerContainer
            .selectAll('.marker')
            .data(markers, (d, i) => i);

        markerSelection
            .enter()
            .append('line')
            .attr('class', 'marker')
            .attr('stroke', '#E03616')
            .style('opacity', '0.8')
            .attr('x1', 0)
            .attr('y1', y)
            .attr('x2', w)
            .attr('y2', y);

        markerSelection
            .transition(t)
            .attr('x1', 0)
            .attr('y1', y)
            .attr('x2', w)
            .attr('y2', y);

        markerSelection.exit().remove();
    }

    redrawBenchmark() {
        const { benchmark } = this.props;

        const t = this.props.transition || null;
        const generateLine = d3
            .line()
            .x((d) => this.x(benchmark.xAccessor(d)))
            .y((d) => this.y(benchmark.yAccessor(d)));

        const lineSelection = this.benchmarkContainer
            .selectAll('.line')
            .data([benchmark.data], (d, i) => i);

        lineSelection
            .enter()
            .append('path')
            .attr('class', 'line')
            .attr('d', generateLine)
            .attr('stroke', '#E03616')
            .attr('stroke-width', 2)
            .attr('fill', 'none');

        lineSelection.transition(t).attr('d', generateLine);

        lineSelection.exit().remove();
    }

    redrawYGridLinesCustom() {
        const {
            custom,
            y,
            w,
            props: { showYGrid, transition },
        } = this;
        const t = transition || null;
        if (showYGrid) {
            const selection = custom.selectAll('.tick').data(y.ticks(4));
            selection.enter().append('custom').attr('class', 'tick');

            selection.transition(t);

            selection.exit().remove();
        }
    }

    redrawYGridLinesCanvas() {
        const {
            custom,
            y,
            w,
            canvas,
            props: { showYGrid },
        } = this;

        const context = canvas.node().getContext('2d');
        if (showYGrid) {
            const ticks = custom.selectAll('.tick');
            ticks.each(function (d, i) {
                var node = d3.select(this);
                context.beginPath();
                context.lineWidth = 0.5;
                context.strokeStyle = 'rgba(0,0,0,0.28)';
                context.moveTo(0, y(d) + 0.5);
                context.lineTo(w, y(d) + 0.5);
                context.stroke();
                context.closePath();
            });
        }
    }

    getNodeDatum(DOMnode) {
        const { clientX, clientY } = window.event;
        const [mouseX, mouseY] = d3.mouse(DOMnode);
        const hiddenContext = this.hidden.node().getContext('2d');
        const pixelRatio = canvasPixelRatio(hiddenContext);
        const [r, g, b] = hiddenContext.getImageData(
            mouseX * pixelRatio,
            mouseY * pixelRatio,
            1,
            1
        ).data;

        const color = `rgb(${r},${g},${b})`;
        const d = this.colorToNode.get(color);
        return d;
    }

    handleMouseMove(DOMnode) {
        const {
            x,
            h,
            props: { getBandwidth, xAccessor, htmlTooltip, useTooltip, refocus },
        } = this;

        if (useTooltip) {
            const d = this.getNodeDatum(DOMnode);

            if (!d) {
                this.handleMouseLeave();
                return;
            }

            this.showFocus(d);
            this.showTooltip(d);
        }
    }

    handleMouseLeave() {
        if (this.props.useTooltip) {
            this.focus.attr('opacity', 0);
            this.hideTooltip();
        }
    }

    showTooltip(d) {
        const { clientX, clientY } = window.event;
        const { htmlTooltip } = this.props;

        const { top, bottom, height, width } = this.tooltip
            .node()
            .getBoundingClientRect();
        const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
        const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

        let dy = clientY + 10;
        let dx = clientX + 10;

        if (dy + height > windowHeight) dy = dy - height;
        if (dx + width > windowWidth) dx = windowWidth - width - 10;

        this.tooltip
            .style('top', `${dy}px`)
            .style('left', `${dx}px`)
            .style('visibility', 'visible')
            .style('opacity', 0.8)
            .html(htmlTooltip && htmlTooltip(d));
    }

    hideTooltip() {
        this.tooltip.style('opacity', 0).style('visibility', 'hidden');
    }

    showFocus(d) {
        const {
            x,
            h,
            props: { getBandwidth, xAccessor },
        } = this;

        this.focus
            .attr('width', getBandwidth(d, x))
            .attr('height', h)
            .attr('opacity', 0.2)
            .attr('transform', `translate(${x(xAccessor(d))}, 0)`);
    }

    hideFocus() {
        this.focus.attr('opacity', 0);
    }

    get w() {
        const {
            margin: { left, right },
            width,
        } = this.props;
        return Math.max(width - left - right - SCROLLBAR_SIZE, 0);
    }

    get h() {
        const {
            margin: { top, bottom },
            height,
        } = this.props;
        return Math.max(height - top - bottom, 0);
    }

    get clipId() {
        return 'clip-path-' + this.id;
    }

    render() {
        const {
            clipId,
            w,
            h,
            props: {
                margin,
                title,
                subtitle,
                height,
                width,
                hideXAxis,
                hideYAxis,
                showYGrid,
            },
        } = this;

        return (
            <div className={`chart-v1 ${this.props.className || ''}`}>
                {title ? <div className="chart-v1__title">{title}</div> : null}
                {subtitle ? <div className="chart-v1__subtitle">{subtitle}</div> : ''}
                <div style={{ position: 'relative' }}>
                    <BaseChartTooltip ref={(node) => (this.tooltip = d3.select(node))} />
                    <canvas
                        className="hidden"
                        style={{
                            position: 'absolute',
                            marginLeft: margin.left,
                            marginTop: margin.top,
                            opacity: 0,
                        }}
                    />
                    <canvas
                        className="main"
                        style={{
                            position: 'absolute',
                            marginLeft: margin.left,
                            marginTop: margin.top,
                            // opacity: 0.5,
                        }}
                    />
                    <svg
                        className="chart-v1__svg"
                        width={width}
                        height={height}
                        style={{ position: 'absolute' }}
                    >
                        <g transform={`translate(${margin.left},${margin.top})`}>
                            <defs>
                                <defs>
                                    <clipPath id={clipId}>
                                        <rect width={w} height={h} />
                                    </clipPath>
                                </defs>
                            </defs>
                            {hideXAxis ? null : (
                                <g className="axis x-axis" transform={`translate(0,${h})`} />
                            )}
                            {hideYAxis ? null : <g className="axis y-axis" />}
                            {showYGrid ? <g className="grid y-grid" /> : null}
                            <g className="focus-container" clipPath={`url(#${clipId})`} />
                            <g className="benchmark-container" clipPath={`url(#${clipId})`} />
                            <g className="marker-container" clipPath={`url(#${clipId})`} />
                            <g className="brush">
                                <rect className="overlay" height={h} width={w} />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}

BaseChartSimple.defaultProps = {
    width: 600,
    height: 200,
    margin: { top: 10, left: 30, right: 15, bottom: 20 },
    duration: 200,
    pointerEvents: true,
    xTickFormat: multiFormat,
    brushedDataAccessor: (data, bounds) =>
        getDataSubarrByTime(data, bounds[0], bounds[1]),
};
