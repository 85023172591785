import { keys, uniq } from 'lodash';

export const permittedIssuesByBlock = (issues, block_id, blocks_issues_exclusions) => {
    const excludedIssues = new Set(
        keys(blocks_issues_exclusions[block_id]).map((id) => +id)
    );
    return keys(issues)
        .filter((issue_id) => !excludedIssues.has(+issue_id))
        .map((issue_id) => +issue_id);
};

export const permittedIssuesByBlocks = (issues, block_ids, blocks_issues_exclusions) => {
    return uniq(
        block_ids
            .map((id) => permittedIssuesByBlock(issues, id, blocks_issues_exclusions))
            .reduce((acc, curr) => acc.concat(...curr), [])
    );
};
