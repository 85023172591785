/* eslint-disable */
import React from 'react'

const BlockOutputNotSupported = () => {
    return <div style={{ flexGrow: 1, paddingLeft: "48px" }}>
        <div className="w-100 oee-chart">
      Output is unavailable for block with multiple machines
        </div>
    </div>
}

export default BlockOutputNotSupported
