/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Table, Input, Button, Space } from 'antd';

// COMPONENT
import ConfirmationModal from '../../components/ConfirmationModal';
import Groups from './Groups';
import ModalGroups from './ModalGroups';
import UsersPending from './UsersPending';
import ModalUserInvite from './ModalUserInvite';
import Highlighter from 'react-highlight-words';
import AukTooltip from '../../components/AukTooltip';
import { SearchOutlined } from '@ant-design/icons';
import { Permission } from '../../components/Permission';

// ACTION
import {
    deleteUserRequest,
    changeUserRoleRequest,
} from '../../../store/old/User/User.action';

import {
    deleteGroupRequest,
    updateGroupRequest,
    createGroupRequest,
} from '../../../store/old/Group/Group.action';

import {
    getAllInvitationsRequest,
    createInvitationRequest,
    updateInvitationRequest,
    revokeInvitationRequest,
} from '../../../store/old/Invitation/Invitation.action';

// SELECTOR
import { arrayUsers } from '../../../store/old/User/User.selector';
import { arrayGroups } from '../../../store/old/Group/Group.selector';
import { arrayInvitations } from '../../../store/old/Invitation/Invitation.selector';

// MODEL
import { User } from '../../models';

import './UsersManagement.scss';
import 'react-table/react-table.css';
import translate from '../../utils/translate';

class UserManagementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            indexTab: 0,
            isOpenInviteUser: false,
            isOpenCreateGroup: false,
            isOpenManage: false,
            isOpenRemoveUserConfirmation: false,
            edit: '',
            removeUser: {},
        };

        this.handleUserInvites = this.handleUserInvites.bind(this);
        this.toggleRemoveUserConfirmation =
      this.toggleRemoveUserConfirmation.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleAcceptRequest = this.handleAcceptRequest.bind(this);
        this.handleRejectRequest = this.handleRejectRequest.bind(this);
        // this.handleResendInvite = this.handleResendInvite.bind(this);
        this.handleRevokeInvite = this.handleRevokeInvite.bind(this);
        this.removeUserFromEntity = this.removeUserFromEntity.bind(this);

        this.handleCreateGroup = this.handleCreateGroup.bind(this);
        this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
        this.handleUpdateGroup = this.handleUpdateGroup.bind(this);
        this.handleClickOnEdit = this.handleClickOnEdit.bind(this);
    }

    componentDidMount() {
        const { getAllInvitations } = this.props;

        getAllInvitations();
    }

    // interactions
    handleTab(index) {
        this.setState({
            indexTab: index,
        });
    }

    toggleModalInviteUser = () => {
        this.setState((prev) => {
            return {
                isOpenInviteUser: !prev.isOpenInviteUser,
            };
        });
    };

    toggleModalCreateGroup = () => {
        this.setState((prev) => {
            return {
                isOpenCreateGroup: !prev.isOpenCreateGroup,
            };
        });
    };

    toggleModalManageGroup = () => {
        this.setState((prev) => {
            return {
                isOpenManage: !prev.isOpenManage,
            };
        });
    };

    // current users
    handleChangeRole(e, user) {
        const newUser = new User(
            user.user_id,
            user.email,
            user.name_first,
            user.name_last,
            user.mobile,
            user.country_code,
            e.target.value,
            user.appointment,
            user.entity_id,
            user.confirmed,
            user.user_img,
            user.user_img_id,
            null
        );

        this.props.changeUserRole(newUser);
    }

    // groups
    handleClickOnEdit(index) {
        this.setState((prev) => {
            return {
                edit: index,
                isOpenManage: !prev.isOpenManage,
            };
        });
    }

    // groups -- modal
    handleCreateGroup(members, group_name) {
        const { activeEntity, createGroup } = this.props;

        const users = members.map((u) => u.user_id);

        createGroup({ entity_id: +activeEntity, group_name, users }, () => {
            this.toggleModalCreateGroup();
        });
    }

    handleUpdateGroup(groupMembers, group_name, group_id) {
        const users = groupMembers.map((m) => m.user_id);

        this.props.updateGroup({ group_name, users, group_id }, () => {
            this.toggleModalManageGroup();
        });
    }

    handleDeleteGroup(group_id) {
        const { deleteGroup } = this.props;

        deleteGroup(group_id, () => {
            this.toggleModalManageGroup();
        });
    }

    // user invites -- modal
    handleUserInvites(emails) {
        const { createInvitation } = this.props;

        createInvitation(emails, () => {
            this.toggleModalInviteUser();
        });
    }

    // pending users
    handleAcceptRequest(row, index) {
        const { updateInvitation } = this.props;

        updateInvitation(row.invite_id, true);
    }

    handleRejectRequest(row, index) {
        const { updateInvitation } = this.props;

        updateInvitation(row.invite_id, false);
    }

    handleRevokeInvite(row, index) {
        const { revokeInvitation } = this.props;

        revokeInvitation(row);
    }

    toggleRemoveUserConfirmation(user) {
        this.setState((prev) => {
            return {
                isOpenRemoveUserConfirmation: !prev.isOpenRemoveUserConfirmation,
                removeUser: user || {},
            };
        });
    }

    removeUserFromEntity(row) {
        this.props.deleteUser(row, () => {
            this.toggleRemoveUserConfirmation();
        });
    }

    generateRemoveUserContent() {
        const { name_first, name_last } = this.state.removeUser;
        const content = (
            <div>
                <p>This action is irreversible.</p>
                <p>
                    {name_first} {name_last}'s account will be deleted, and he/she will no
          longer have access to your organisation.
                </p>
                <p>Are you sure?</p>
            </div>
        );
        return content;
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, display: 'flex', alignItems: 'center' }}
                    >
            Search
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
            Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    get userTab() {
        const { users, invitations, user, activeEntity } = this.props;
        const { isClientUser, isWhitelistedDemoUser } = user;

        const isEditPermission = user.check_resource_policy(
            'users',
            false,
            true,
            false
        );

        const usersBelongToCurrentEntity = users.filter(
            (v) => v.entity_id === activeEntity
        );

        const columns = [
            {
                title: 'First Name',
                dataIndex: 'name_first',
                key: 'name_first',
                ...this.getColumnSearchProps('name_first'),
            },
            {
                title: 'Last Name',
                dataIndex: 'name_last',
                key: 'name_last',
                ...this.getColumnSearchProps('name_last'),
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                ...this.getColumnSearchProps('email'),
            },
            {
                title: 'Appointment',
                dataIndex: 'appointment',
                key: 'appointment',
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                render: (text, record, index) => {
                    return (
                        <select
                            className="users-select"
                            onChange={(event) => this.handleChangeRole(event, record)}
                            value={record.role_name}
                            disabled={!isEditPermission}
                        >
                            <option value="owner">owner</option>
                            <option value="editor">editor</option>
                            <option value="viewer">viewer</option>
                        </select>
                    );
                },
            },
            {
                title: '',
                dataIndex: 'action',
                render: (text, record, index) => (
                    <Permission forResource resource="users" canDo="full">
                        <div className="d-flex justify-content-center">
                            <span
                                className="p-1"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => this.toggleRemoveUserConfirmation(record)}
                            >
                                <i className="far fa-trash-alt" />
                            </span>
                        </div>
                    </Permission>
                ),
                width: 50,
            },
        ];

        return (
            <div className="tab-content">
                <Permission forResource resource="users" canDo="edit">
                    <div className="d-flex w-100 justify-content-end">
                        <AukTooltip.Help title={translate(['invite', 'new'])}>
                            <Button
                                onClick={this.toggleModalInviteUser}
                                className="auk-button auk-button--round"
                            >
                                <i className="fas fa-plus" />
                            </Button>
                        </AukTooltip.Help>
                    </div>
                </Permission>

                <UsersPending
                    pendingList={isClientUser || isWhitelistedDemoUser ? invitations : []}
                    accept={this.handleAcceptRequest}
                    reject={this.handleRejectRequest}
                    // resend={this.handleResendInvite}
                    revoke={this.handleRevokeInvite}
                />
                <hr />
                <Table
                    columns={columns}
                    dataSource={
                        isClientUser || isWhitelistedDemoUser
                            ? usersBelongToCurrentEntity
                            : []
                    }
                    rowKey="user_id"
                />
            </div>
        );
    }

    get groupTab() {
        const { groups } = this.props;

        return (
            <div className="tab-content">
                <Permission forResource resource="users" canDo="edit">
                    <div className="d-flex w-100 justify-content-end">
                        <AukTooltip.Help title={translate(['create', 'new'])}
                        >
                            <Button
                                onClick={this.toggleModalCreateGroup}
                                className="auk-button auk-button--round"
                            >
                                <i className="fas fa-plus" />
                            </Button>
                        </AukTooltip.Help>
                    </div>
                </Permission>

                <Groups groupList={groups} handleClickOnEdit={this.handleClickOnEdit} />
            </div>
        );
    }

    _sortPendingByStatus = (d) => {
        return d.concat().sort((a, b) => {
            const displayOrder = { 'request sent': 1, 'invite sent': 2, expired: 3 };
            return displayOrder[a.status] - displayOrder[b.status];
        });
    };

    render() {
        const { isClientUser, isWhitelistedDemoUser } = this.props.user;
        const { handleCreateGroup, handleDeleteGroup, handleUpdateGroup } = this;
        const { users, groups } = this.props;
        const {
            edit,
            isOpenManage,
            isOpenCreateGroup,
            isOpenInviteUser,
            indexTab,
            isOpenRemoveUserConfirmation,
            removeUser,
        } = this.state;

        return (
            <div className="usersmanagement">
                <div className="d-flex justify-content-center">
                    <div className="col">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <div
                                    onClick={() => this.handleTab(0)}
                                    className={`nav-link ${indexTab === 0 ? 'active' : ''}`}
                                >
                                    {translate('users')}
                                </div>
                            </li>
                            <li className="nav-item">
                                <div
                                    onClick={() => this.handleTab(1)}
                                    className={`nav-link ${indexTab === 1 ? 'active' : ''}`}
                                >
                                    {translate('groups')}
                                </div>
                            </li>
                        </ul>
                        {indexTab === 0 ? this.userTab : this.groupTab}
                        <ModalUserInvite
                            show={isOpenInviteUser}
                            toggle={this.toggleModalInviteUser}
                            inviteUsers={this.handleUserInvites}
                            handleUserInvite={this.handleUserInvite}
                        />

                        <ModalGroups
                            show={isOpenCreateGroup}
                            toggle={this.toggleModalCreateGroup}
                            showEdit={isOpenManage}
                            toggleEdit={this.toggleModalManageGroup}
                            userList={isClientUser || isWhitelistedDemoUser ? users : []}
                            grName={
                                isOpenManage && groups[edit] ? groups[edit].group_name : ''
                            }
                            group_id={
                                isOpenManage && groups[edit] ? groups[edit].group_id : ''
                            }
                            groupMembers={
                                isOpenManage && groups[edit] ? groups[edit].user_groups : []
                            }
                            handleCreate={handleCreateGroup}
                            handleDelete={handleDeleteGroup}
                            handleUpdate={handleUpdateGroup}
                        />

                        <ConfirmationModal
                            showConfirmation={isOpenRemoveUserConfirmation}
                            onConfirmation={() => this.removeUserFromEntity(removeUser)}
                            toggleConfirmation={this.toggleRemoveUserConfirmation}
                            action="Remove User"
                            message={this.generateRemoveUserContent()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (root) => {
    return {
        activeEntity: root.entity.active,
        user: root.auth.user,
        users: arrayUsers(root),
        groups: arrayGroups(root),
        invitations: arrayInvitations(root),
    };
};

const mapDisplayToProps = (dispatch) => {
    return {
        deleteUser: (user, callback) =>
            dispatch(deleteUserRequest({ single: user }, callback)),
        changeUserRole: (user, callback) =>
            dispatch(changeUserRoleRequest({ single: user }, callback)),
        updateGroup: (group, callback) =>
            dispatch(updateGroupRequest(group, callback)),
        createGroup: (group, callback) =>
            dispatch(createGroupRequest(group, callback)),
        deleteGroup: (group_id, callback) =>
            dispatch(deleteGroupRequest(group_id, callback)),
        getAllInvitations: (callback) =>
            dispatch(getAllInvitationsRequest(callback)),
        updateInvitation: (invite_id, accept, callback) =>
            dispatch(updateInvitationRequest({ invite_id, accept }, callback)),
        createInvitation: (emails, callback) =>
            dispatch(createInvitationRequest({ emails }, callback)),
        revokeInvitation: (invitation, callback) =>
            dispatch(revokeInvitationRequest({ single: invitation }, callback)),
    };
};

export const UserManagement = connect(
    mapStateToProps,
    mapDisplayToProps
)(UserManagementComponent);
