/* eslint-disable react/prop-types */
import React, { useContext, useState, useMemo } from 'react';
import moment from 'moment';
import { isAfter } from 'date-fns';
import { DatePicker, Input, Menu, Select, TreeSelect } from 'antd';
import { LeftOutlined, RightOutlined, FilterOutlined } from '@ant-design/icons';

// COMPONENTS
import AukButton from '../../components/AukButton';
import CSVDownloadPrefixer from '../../components/PromptCSVDownloadPrefixer';
import AukTooltip from '../../components/AukTooltip';
import { errorFlash } from '../../components/Flash';
import { Permission, RolePermission } from '../../components/Permission';

// CONTEXT
import { TargetSettingContext } from '.';

// HELPERS / CONSTANTS
import { TargetsUIConstants as K } from '../../../store/old/UI/TargetsUI/TargetsUI.constants';
import { getBlocksTreeData } from '../../utils/blocks';
import { saveCSV } from '../../utils/csv';
import {
    defaultFilterOption,
    fileDatetimeFormat,
    csvDatetimeFormat,
} from '../../utils/helpers';

import './index.scss';
import CsvFileUpload from '../../components/CSVFileUpload';
import translate from '../../utils/translate';

const { Option } = Select;

const CSV = {
    UPLOAD: 'upload',
    TEMPLATE: 'template',
    REFERENCES: 'references',
};

const CSV_TEMPLATE_HEADERS =
  'asset_id (required), sku_id (optional), target (required), from (required; e.g. yyyy-mm-dd hh:mm), to (required; e.g. yyyy-mm-dd hh:mm), notes (optional)';

// const getTreeData = (node) => {
//   if (node.children && node.children.length) {
//     return {
//       label: node.label,
//       value: node.block_id,
//       children: node.children.map((c) => getTreeData(c)),
//       disabled: node.isRestricted,
//     };
//   }

//   return {
//     label: node.label,
//     value: node.block_id,
//     disabled: node.isRestricted,
//   };
// };

const Header = ({
    startDate,
    changeStartDate,
    period,
    changePeriod,
    setShowUploadCSV,
    rootBlock,
    skuList,
    setFilteredBlock,
    setFilteredSKU,
    downloadReferencesCsv,
    downloadTemplateCsv,
    clickDownloadTargetsCsv,
}) => {
    const { size, unit } = period;

    const csvOptions = [
        { key: CSV.UPLOAD, label: 'Upload targets (.csv)' },
        { key: CSV.TEMPLATE, label: 'Download template (.csv)' },
        { key: CSV.REFERENCES, label: 'Download asset/SKU references (.csv)' },
    ];

    const handleClickMenu = ({ key }) => {
        switch (key) {
        case CSV.UPLOAD:
            setShowUploadCSV(true);
            return;
        case CSV.TEMPLATE:
            downloadTemplateCsv();
            return;
        case CSV.REFERENCES:
            downloadReferencesCsv();
            return;
        default:
            return;
        }
    };

    return (
        <div className="target-setting__header d-flex justify-content-between mb-3">
            <div className="target-setting__header__left d-flex">
                <Input.Group compact className="d-flex w-100">
                    <AukButton.Outlined
                        className="pointer-events-none"
                        style={{ width: 20 }}
                    >
                        <FilterOutlined />
                    </AukButton.Outlined>
                    <TreeSelect
                        className="w-50"
                        placeholder="Display process/asset"
                        treeData={[getBlocksTreeData(rootBlock)]}
                        treeDefaultExpandAll
                        showSearch
                        filterOption={defaultFilterOption}
                        onChange={setFilteredBlock}
                        allowClear
                    />
                    <Select
                        style={{ flexGrow: 1 }}
                        placeholder="Display SKU"
                        showSearch
                        filterOption={defaultFilterOption}
                        onChange={setFilteredSKU}
                        allowClear
                        options={skuList.map((s) => ({
                            value: s.sku_id,
                            label: s.codeName,
                        }))}
                    />
                </Input.Group>
            </div>
            <div className="target-setting__header__center d-flex">
                <div className="target-setting__header_range-input">
                    <DatePicker
                        className="w-100"
                        value={startDate}
                        onChange={changeStartDate}
                        allowClear={false}
                    />
                </div>
                <div className="target-setting__header_range-input ml-2">
                    <Select
                        className="w-100"
                        placeholder="Select range"
                        value={period.value}
                        onChange={(val) => changePeriod(K.PERIOD_OPTIONS_MAP[val])}
                    >
                        {K.PERIOD_OPTIONS.map((o) => (
                            <Option key={o.value} value={o.value}>
                                {o.size} {translate(o.unit)}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="target-setting__header_range-arrows d-flex">
                    <AukTooltip.Help title={`Past ${size} ${unit}`}>
                        <div
                            className="target-setting__header_range-arrow d-flex align-items-center"
                            onClick={() =>
                                changeStartDate(startDate.clone().subtract(size, unit))
                            }
                        >
                            <LeftOutlined />
                        </div>
                    </AukTooltip.Help>
                    <AukTooltip.Help title={`Next ${size} ${unit}`}>
                        <div
                            className="target-setting__header_range-arrow d-flex align-items-center"
                            onClick={() => changeStartDate(startDate.clone().add(size, unit))}
                        >
                            <RightOutlined />
                        </div>
                    </AukTooltip.Help>
                </div>
            </div>
            <div className="target-setting__header__right">
                <Permission forResource resource="targets" canDo="edit">
                    <RolePermission accessLevel="editor">
                        <AukTooltip.Help title="Export to CSV" placement="top">
                            <AukButton.Outlined
                                className="auk-button--round p-0 m-0"
                                onClick={clickDownloadTargetsCsv}
                            >
                                <i className="fas fa-download" />
                            </AukButton.Outlined>
                        </AukTooltip.Help>
                        <AukTooltip.Help title="Bulk Create" placement="top">
                            <AukButton.Dropdown
                                className="auk-button--round p-0 m-0 ml-2"
                                icon={<i className="fas fa-upload" />}
                                overlay={
                                    <Menu onClick={handleClickMenu}>
                                        {csvOptions.map((o) => (
                                            <Menu.Item key={o.key}>{o.label}</Menu.Item>
                                        ))}
                                    </Menu>
                                }
                            />
                        </AukTooltip.Help>
                    </RolePermission>
                </Permission>
            </div>
        </div>
    );
};

export const ActionHeader = () => {
    const {
        store: { assetList, skuList, rootBlock, targetList },
        period,
        changePeriod,
        changeStartDate,
        startDate,
        endDate,
        handleCreateManyPlans,
        setFilteredBlock,
        setFilteredSKU,
    } = useContext(TargetSettingContext);

    const [showUploadCSV, setShowUploadCSV] = useState(false);
    const [showDownloadCSV, setShowDownloadCSV] = useState(false);

    const dateRange = useMemo(() => {
        return [startDate, endDate]
            .map((d) => moment(d).format(fileDatetimeFormat))
            .join('_');
    }, [startDate, endDate]);

    const submitCSV = (arr) => {
        if (Array.isArray(arr) && arr.length === 0) {
            return errorFlash({
                message: 'CSV Upload Error',
                details: 'File can not be empty',
            });
        }

        const rows = arr.map(([asset_id, sku_id, value, from, to, notes]) => ({ asset_id, sku_id, value, from, to, notes }));
        const regex = new RegExp(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/);
        let dateValid = true;
        let dateRangevalid = true;
        const dataLength = rows.length;
        let index = 0;

        while (index < dataLength) {
            const valid = regex.test(rows[index].from) && regex.test(rows[index].to);

            if (!valid) {
                dateValid = false;

                break;
            }

            if (valid) {
                const from = new Date(rows[index].from);
                const to = new Date(rows[index].to);

                if (!isAfter(to, from)) {
                    dateRangevalid = false;

                    break;
                }
            }

            index++;
        }

        if (!dateValid) {
            return errorFlash({
                message: 'CSV Upload Error',
                details: 'From and to fields should follow this format yyyy-mm-dd hh:mm (E.g. 2021-01-31 13:05)',
            });
        }

        if (!dateRangevalid) {
            return errorFlash({
                message: 'CSV Upload Error',
                details: 'To should be greater than From',
            });
        }

        const newData = rows.map((v) => {
            return {
                asset_id: +v.asset_id,
                sku_id: v.sku_id ? +v.sku_id : null,
                value: +v.value,
                target: +v.value,
                from: new Date(v.from).toISOString(),
                to: new Date(v.to).toISOString(),
                notes: v.notes || '',
            };
        });

        handleCreateManyPlans(newData, () => {
            setShowUploadCSV(false);
        });
    };

    const targetsCsvString = () => {
        const columnHeaders = `Asset,Sku,From,To,Target,Notes\n`;

        return targetList
            .filter((t) => t.asset)
            .map((t) => {
                const { value, asset, sku, notes } = t;
                const skuLabel = sku ? sku.codeName : '';
                const from = moment(t.from).format(csvDatetimeFormat);
                const to = moment(t.to).format(csvDatetimeFormat);

                return `${asset.asset_name},${skuLabel},${from},${to},${value},"${notes}"\n`;
            })
            .reduce((acc, curr) => (acc += curr), columnHeaders);
    };

    const downloadTargetsCsv = (prefix) => {
        const fileName = `${prefix}_${dateRange}.csv`;

        saveCSV(targetsCsvString(), fileName);
    };

    const referencesCsvString = () => {
        const assetHeaders = 'Asset, Asset ID\n';
        const assetBlock =
      assetHeaders +
      assetList.map((a) => `${a.asset_name}, ${a.asset_id}`).join('\n');

        const skuHeaders = 'SKU, SKU ID\n';
        const skuBlock =
      skuHeaders + skuList.map((s) => `${s.codeName}, ${s.sku_id}`).join('\n');

        return assetBlock + '\n\n' + skuBlock;
    };

    const downloadReferencesCsv = () =>
        saveCSV(referencesCsvString(), 'ts_references.csv');

    const downloadTemplateCsv = () =>
        saveCSV(CSV_TEMPLATE_HEADERS, 'ts_template.csv');

    return (
        <>
            <Header
                startDate={startDate}
                changeStartDate={changeStartDate}
                period={period}
                changePeriod={changePeriod}
                setShowDownloadCSV={setShowDownloadCSV}
                setShowUploadCSV={setShowUploadCSV}
                rootBlock={rootBlock}
                skuList={skuList}
                setFilteredBlock={setFilteredBlock}
                setFilteredSKU={setFilteredSKU}
                downloadReferencesCsv={downloadReferencesCsv}
                downloadTemplateCsv={downloadTemplateCsv}
                clickDownloadTargetsCsv={() => setShowDownloadCSV(true)}
            />
            <CsvFileUpload
                visible={showUploadCSV}
                onCancel={() => setShowUploadCSV(false)}
                onSubmit={submitCSV}
                rowTransform={(s) => s.replace(/(".*?")/g, '').split(',')}
            />
            <CSVDownloadPrefixer
                show={showDownloadCSV}
                toggle={() => setShowDownloadCSV(!showDownloadCSV)}
                defaultPrefix={'targets'}
                save={downloadTargetsCsv}
                date={dateRange}
            />
        </>
    );
};
