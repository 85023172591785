/* eslint-disable react/prop-types */
import React from 'react';
import Table from '../../components/Table';
import { Permission } from '../../components/Permission';
import translate from '../../utils/translate';

const UsersPending = (props) => {
    const columns = [
        {
            Header: translate('email'),
            Cell: (row) => {
                const { type } = props.pendingList[row.index];
                return type === 'request'
                    ? props.pendingList[row.index].requester.email
                    : props.pendingList[row.index].recipient;
            },
        },
        {
            Header: translate('status'),
            Cell: (row) => {
                const { type, status } = props.pendingList[row.index];
                let message =
          type === 'request' ? 'New user request' : 'User invite sent';
                status === 'expired' && (message = 'Invitation expired');
                return <div className="users-cursive">{message}</div>;
            },
        },
        {
            Cell: (row) => {
                const { type, status } = props.pendingList[row.index];
                let actions = null;
                type === 'request' &&
          (actions = (
              <Permission forResource resource="users" canDo="edit">
                  <div>
                      <button
                          className="btn b-accept acc role-btn"
                          onClick={() =>
                              props.accept(props.pendingList[row.index], row.index)
                          }
                      >
                          {translate('accept')}
                      </button>
                      <button
                          className="btn b-delete users-delete-margin role-btn"
                          onClick={() =>
                              props.reject(props.pendingList[row.index], row.index)
                          }
                      >
                          {translate('reject')}
                      </button>
                  </div>
              </Permission>
          ));
                type === 'invite' &&
          (actions = (
              <Permission forResource resource="users" canDo="full">
                  <div>
                      {/* <button
              className="btn b-accept acc role-btn"
              onClick={() => 
                props.resend(props.pendingList[row.index], row.index)
              }>
              RESEND
            </button> */}
                      <button
                          className="btn b-delete users-delete-margin role-btn"
                          onClick={() =>
                              props.revoke(props.pendingList[row.index], row.index)
                          }
                      >
                          {translate('revoke')}
                      </button>
                  </div>
              </Permission>
          ));
                return actions;
            },
        },
    ];

    return props.pendingList.length !== 0 ? (
        <Table
            data={props.pendingList}
            columns={columns}
            showPagination={false}
            pageSize={props.pendingList.length}
        />
    ) : null;
};

export default UsersPending;
