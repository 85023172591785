/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TreeSelect } from 'antd';

// COMPONENTS
import AukTooltip from '../AukTooltip';
import Modal from '../Modal';
import { withEntity } from '../../Wrappers/HOCs/withEntity';

// REDUX
import { LogOut } from '../../../store/old/Authentication/Authentication.action';
import { changeEntityFlow } from '../../../store/old/Entity/Entity.action';

// HELPERS
import CONSTANTS from '../../Constants';
import { regexMatch } from '../../utils/helpers';
import UserAvatar from '../Avatar';

import './Header.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import withRouter from '../../Hooks/withRouter';
import SystemInbox from './SystemInbox';
import CompanyLogo from '../CompanyLogo';
import translate from '../../utils/translate';

const getTreeData = (node) => {
    if (node.children && node.children.length) {
        return {
            title: node.entity_name,
            value: node.entity_id,
            children: node.children.map((c) => getTreeData(c)),
            disabled: node.accessDenied,
        };
    }

    return {
        title: node.entity_name,
        value: node.entity_id,
        disabled: node.accessDenied,
    };
};

class HeaderAuthenticated extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeProfile: false,
            modal: {
                show: false,
                body: '',
                onProceed: () => {},
            },
        };

        this.resetModal = this.resetModal.bind(this);
    }

    handleProfile = () => {
        this.setState((prev) => {
            return { activeProfile: !prev.activeProfile };
        });
    };

    profile = () => {
        this.resetState();
        this.props.router.navigate(CONSTANTS.URLS.PROFILE);
    };

    logout = () => {
        this.props.logout(() => {
            this.props.router.navigate('/login');
            window.location.reload();
        });
    };

    resetState = () => {
        this.setState({ activeProfile: false });
    };

    handleChange = (id) => {
        if (this.props.entity.entity_id === id) return;
        const destination = this.props.entities[id];

        if (this.shouldAlertEntityChange) {
            return this.setState({
                modal: {
                    show: true,
                    body: (
                        <div>
                            <p>
                This will redirect you to{' '}
                                <strong>{destination.entity_name}</strong>&apos;s dashboard.
                            </p>
                        </div>
                    ),
                    onProceed: () => {
                        this.props.router.navigate(`${CONSTANTS.URLS.DASHBOARD}?entity_id=${id}`);
                        this.resetModal();
                    },
                },
            });
        }

        const query = window.location.search.replace(`entity_id=${this.props.entity.entity_id}`, `entity_id=${id}`)
        this.props.router.navigate(`${window.location.pathname}${query}`);
    };

    resetModal = () => {
        this.setState({
            modal: {
                show: false,
                body: '',
                onProceed: () => {},
            },
        });
    };

    get shouldAlertEntityChange() {
        const blacklist = [CONSTANTS.URLS.ASSET, CONSTANTS.URLS.BLOCK];
        const {
            location: { href: url },
        } = window;

        return blacklist.reduce((acc, curr) => {
            if (acc === true) return true;
            return !!regexMatch(url, curr);
        }, false);
    }

    get orgTreeData() {
        return [getTreeData(this.props.organisation)];
    }

    render() {
        const { user, entity } = this.props;

        const EntitySelect = (
            <TreeSelect
                value={entity.entity_id}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.orgTreeData}
                treeDefaultExpandAll
                onChange={(val) => this.handleChange(val)}
            />
        );

        const guide = (
            <Link className="header-auth__icon" to={CONSTANTS.URLS.USER_GUIDE}>
                <AukTooltip.Info title="User Guide" placement="right">
                    <QuestionCircleOutlined />
                </AukTooltip.Info>
            </Link>
        );

        const inbox = (
            <div className="header-auth__icon">
                <AukTooltip.Info title="Inbox" placement="left">
                    <span>
                        <SystemInbox />
                    </span>
                </AukTooltip.Info>
            </div>
        );

        const auth = (
            <>
                <div className="company-name">
                    <CompanyLogo/>
                    {EntitySelect}
                </div>
                <div className="collapse navbar-collapse">
                    <div className="navbar-nav"></div>
                    <div className="d-flex">
                        {inbox}
                        {guide}
                        <ul className="navbar-nav">
                            <li
                                tabIndex="0"
                                className={`nav-item dropdown user-menu ${
                                    this.state.activeProfile ? 'show' : ''
                                }`}
                            >
                                <a
                                    onClick={this.handleProfile}
                                    className="nav-link dropdown-toggle d-flex align-items-center justify-content-between"
                                    id="navbarDropdownMenuLink"
                                    aria-haspopup="true"
                                    aria-expanded={this.state.activeProfile}
                                >
                                    <UserAvatar user={user} />
                                    <div className="logo">
                                        <div className="l">{user && user.nameFull}</div>
                                        <div className="e">{user && user.email}</div>
                                    </div>
                                </a>
                                <div
                                    className={`dropdown-menu ${
                                        this.state.activeProfile ? 'show' : ''
                                    }`}
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <button
                                        className="dropdown-item"
                                        href="#"
                                        onClick={this.profile}
                                    >
                                        {translate('profile')}
                                    </button>
                                    <div className="dropdown-divider" />
                                    <button
                                        className="dropdown-item"
                                        href="#"
                                        onClick={this.logout}
                                    >
                                        {translate('logout')}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );

        const hamburger = (
            <div className="hidden-lg">
                {guide}
                <ul className="navbar-nav">
                    <li
                        tabIndex="0"
                        className={`nav-item dropdown user-menu ${
                            this.state.activeProfile ? 'show' : ''
                        }`}
                    >
                        <button
                            className="nav-link navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            style={{ marginRight: '5px' }}
                            onClick={this.handleProfile}
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div
                            className={`dropdown-menu ${
                                this.state.activeProfile ? 'show' : ''
                            }`}
                        >
                            <button className="dropdown-item">
                                <Link to="user">Profile</Link>
                            </button>
                            <div className="dropdown-divider" />
                            <button className="dropdown-item" href="#" onClick={this.logout}>
                Logout
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        );

        return (
            <div className="header-auth">
                {auth}
                {hamburger}
                <Modal
                    title="Change Entity"
                    body={this.state.modal.body}
                    show={this.state.modal.show}
                    toggle={() => this.resetModal()}
                    onProceed={() => this.state.modal.onProceed()}
                />
            </div>
        );
    }
}

const mapStateToProps = (appState) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (callback) => dispatch(LogOut(callback)),
        changeEntity: (entity_id, callback) =>
            dispatch(changeEntityFlow(entity_id, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withEntity(withRouter(HeaderAuthenticated)));
