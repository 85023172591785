/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/prop-types */
import React from 'react';
import * as d3 from 'd3';
import { findDOMNode } from 'react-dom';
import { AutoSizer } from 'react-virtualized';

import { BaseChartTooltip } from './tooltips';
import { isNumber, isEqual } from 'lodash';
import {
    updateHDPICanvas,
    canvasPixelRatio,
    roundRect,
    clearCanvas,
    genColor,
} from '../../utils/charts';


import './Charts_V1.scss';
import { eventStore } from '../../auxStore';

const SCROLLBAR_SIZE = 10;

class LabelsChartSimple extends React.Component {
    constructor(props) {
        super(props);

        this.colorToNode = new Map();

        this.redrawCustom = this.redrawCustom.bind(this);
        this.redrawCanvas = this.redrawCanvas.bind(this);
        this.redrawHidden = this.redrawHidden.bind(this);
    }

    componentDidMount() {
        this.el = d3.select(findDOMNode(this));
        this.svg = this.el.select('svg');
        this.g = this.svg.select('g');
        this.overlay = this.g.select('.overlay');
        this.canvas = this.el.select('canvas.main');
        this.hidden = this.el.select('canvas.hidden');

        this.canvas.node().getContext('2d', { willReadFrequently: true });
        this.hidden.node().getContext('2d', { willReadFrequently: true });

        const custom = document.createElement('custom');
        this.custom = d3.select(custom);

        this.redraw();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.width != this.props.width ||
      prevProps.height != this.props.height ||
      prevProps.data !== this.props.data ||
      !isEqual(prevProps.xDomain, this.props.xDomain)
        ) {
            this.redraw();
        }
    }

    redraw() {
        setTimeout(() => {
            this.recalculate();
            this.redrawChartArea();

            const {
                colorToNode,
                redrawCustom,
                redrawCanvas,
                redrawHidden,
                props: { duration },
            } = this;

            redrawCustom && redrawCustom();
            d3.timerFlush();
            const timer = d3.timer(function (elapsed) {
                if (elapsed > duration) {
                    timer.stop();
                }
                colorToNode.clear();
                redrawCanvas && redrawCanvas();
                redrawHidden && redrawHidden();
            });
        });
    }

    redrawCustom() {
        const {
            props: {
                transition,
                duration,
                data,
                xAccessor,
                x2Accessor,
                colorAccessorLabel,
                keyAccessor,
                textAccessor,
                colorAccessorText,
                strokeAccessorLabel,
                widthAccessor,
            },
            x,
            custom,
        } = this;

        const t = transition || null;
        const selection = custom
            .selectAll('.label')
            .data(data, (d) => keyAccessor(d));

        selection
            .enter()
            .append('custom')
            .attr('class', 'label')
            .attr('x', (d) => x(xAccessor(d)))
            .attr('width', (d) => x(x2Accessor(d)) - x(xAccessor(d)))
            .attr('text', textAccessor)
            .attr('fill', colorAccessorLabel)
            .attr('stroke', strokeAccessorLabel || colorAccessorLabel);

        selection
            .transition(t)
            .duration(duration)
            .attr('x', (d) => x(xAccessor(d)))
            .attr('width', (d) => x(x2Accessor(d)) - x(xAccessor(d)))
            .attr('text', textAccessor)
            .attr('fill', colorAccessorLabel)
            .attr('stroke', strokeAccessorLabel || colorAccessorLabel);

        selection.exit().remove();
    }

    redrawCanvas() {
        const {
            props: { borderRadius, colorAccessorText, strokeAccessorLabel },
            w,
            h,
            canvas,
            custom,
        } = this;

        const context = canvas.node().getContext('2d');
        clearCanvas(context, w, h);

        const labels = custom.selectAll('.label');

        labels.each(function (d, i) {
            const node = d3.select(this);
            context.save();
            context.beginPath();
            roundRect(
                context,
                +node.attr('x'),
                0,
                +node.attr('width'),
                h,
                borderRadius
            );
            context.fillStyle = node.attr('fill');
            context.strokeStyle = node.attr('stroke');
            context.globalAlpha = 0.9;
            context.lineCap = 'round';
            context.fill();
            strokeAccessorLabel && context.stroke();
            context.closePath();
            context.clip();

            context.font = `300 12px sans-serif`;
            context.fillStyle = colorAccessorText(d); // colorAccessorText
            context.textAlign = 'right';
            context.fillText(
                node.attr('text'),
                Math.min(+node.attr('x') + +node.attr('width') - 6, w - 6),
                h - 4
            );

            context.restore();
        });
    }

    redrawHidden() {
        const { hidden, custom, w, h, colorToNode } = this;

        const hiddenContext = hidden.node().getContext('2d');
        clearCanvas(hiddenContext, w, h);

        const labels = custom.selectAll('.label');
        labels.each(function (d, i) {
            var node = d3.select(this);

            hiddenContext.beginPath();
            const color = genColor(i);
            colorToNode.set(color, d);

            roundRect(
                hiddenContext,
                +node.attr('x') - 1,
                0,
                +node.attr('width') + 2,
                h,
                0
            );
            hiddenContext.fillStyle = color;
            hiddenContext.fill();

            hiddenContext.closePath();
        });
    }

    recalculate() {
        const {
            w,
            h,
            props: {
                width,
                height,
                margin: { left, right, top, bottom },
                xScale,
                xDomain,
                getXRange,
            },
        } = this;

        const xRange = getXRange ? getXRange(w) : [0, w];

        this.x = xScale.domain(xDomain).range(xRange);
    }

    redrawChartArea() {
        const self = this;
        updateHDPICanvas(this.canvas.node(), this.w, this.h);
        updateHDPICanvas(this.hidden.node(), this.w, this.h);

        this.overlay.attr('width', this.w).attr('height', this.h);
        this.overlay
            .on('mousemove', () => {
                const newEvent = new Event('mousemove', {
                    bubbles: true,
                    cancelable: true,
                    composed: true,
                });
                newEvent.pageX = d3.event.pageX;
                newEvent.clientX = d3.event.clientX;
                newEvent.pageY = d3.event.pageY;
                newEvent.clientY = d3.event.clientY;
                newEvent.view = window;
                self.hidden.node().dispatchEvent(newEvent); // simulate mousedown event on overlay element
            })
            .on('mouseleave', function () {
                self.handleMouseLeave();
            })
            .on('click', () => {
                if (eventStore.onDoubleClick()) {
                    const newEvent = new Event('doubleclick', {
                        bubbles: true,
                        cancelable: true,
                        composed: true,
                    });
                    newEvent.pageX = d3.event.pageX;
                    newEvent.clientX = d3.event.clientX;
                    newEvent.pageY = d3.event.pageY;
                    newEvent.clientY = d3.event.clientY;
                    newEvent.view = window;
                    self.hidden.node().dispatchEvent(newEvent); // simulate mousedown event on overlay element
                }
            });

        this.hidden
            .on('mousemove', function () {
                self.handleMouseMove(this);
            })
            .on('doubleclick', function () {
                self.handleDoubleClick(this);
            })
            .on('mouseleave', function () {
                self.handleMouseLeave();
            });
    }

    getNodeDatum(DOMnode) {
        const { clientX, clientY } = window.event;
        const [mouseX, mouseY] = d3.mouse(DOMnode);
        const hiddenContext = this.hidden.node().getContext('2d');

        const pixelRatio = canvasPixelRatio(hiddenContext);
        const [r, g, b] = hiddenContext.getImageData(
            mouseX * pixelRatio,
            mouseY * pixelRatio,
            1,
            1
        ).data;

        const color = `rgb(${r},${g},${b})`;
        const d = this.colorToNode.get(color);
        return d;
    }

    handleDoubleClick(DOMnode) {
        const d = this.getNodeDatum(DOMnode);

        if (!d) return;

        this.props.onDoubleClick && this.props.onDoubleClick(d, this.x);
    }

    handleMouseMove(DOMnode) {
        const {
            x,
            h,
            params,
            props: { xAccessor, htmlTooltip, useTooltip },
        } = this;

        if (useTooltip) {
            const d = this.getNodeDatum(DOMnode);

            if (!d) {
                this.handleMouseLeave();
                return;
            }

            this.overlay.style('cursor', 'move');
            this.showTooltip(d);
        }
    }

    handleMouseLeave() {
        this.overlay.style('cursor', 'default');
        if (this.props.useTooltip) {
            this.hideTooltip();
        }
    }

    showTooltip(d) {
        const { clientX, clientY } = window.event;
        const { htmlTooltip } = this.props;

        const { top, bottom, height } = this.tooltip.node().getBoundingClientRect();
        const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
        let dy = clientY + 10;
        let dx = clientX + 10;

        if (dy + height > windowHeight) {
            dy = dy - height;
        }

        this.tooltip
            .style('top', `${dy}px`)
            .style('left', `${dx}px`)
            .style('visibility', 'visible')
            .style('opacity', 0.8)
            .html(htmlTooltip && htmlTooltip(d));
    }

    hideTooltip() {
        this.tooltip.style('opacity', 0).style('visibility', 'hidden');
    }

    get w() {
        const {
            margin: { left, right },
            width,
        } = this.props;
        return Math.max(width - left - right - SCROLLBAR_SIZE, 0);
    }

    get h() {
        const {
            margin: { top, bottom },
            height,
        } = this.props;
        return Math.max(height - top - bottom, 0);
    }

    render() {
        const {
            clipId,
            w,
            h,
            props: {
                margin,
                title,
                subtitle,
                height,
                width,
                hideXAxis,
                hideYAxis,
                showYGrid,
            },
        } = this;

        return (
            <div className={`chart-v1 ${this.props.className || ''}`}>
                {title ? <div className="chart-v1__title">{title}</div> : null}
                {subtitle ? <div className="chart-v1__subtitle">{subtitle}</div> : ''}
                <div style={{ position: 'relative' }}>
                    <BaseChartTooltip ref={(node) => (this.tooltip = d3.select(node))} />
                    <canvas
                        className="hidden"
                        style={{
                            position: 'absolute',
                            marginLeft: margin.left,
                            marginTop: margin.top,
                            opacity: 0,
                        }}
                    />
                    <canvas
                        className="main"
                        style={{
                            position: 'absolute',
                            marginLeft: margin.left,
                            marginTop: margin.top,
                            opacity: 1,
                        }}
                    />
                    <svg
                        className="chart-v1__svg"
                        width={width}
                        height={height}
                        style={{ position: 'absolute' }}
                    >
                        <g transform={`translate(${margin.left},${margin.top})`}>
                            <rect className="overlay" opacity={0} height={h} width={w} />
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}

LabelsChartSimple.defaultProps = {
    width: 600,
    height: 200,
    margin: { top: 0, left: 30, right: 15, bottom: 0 },
    duration: 200,
    borderRadius: 6,
    colorAccessorLabel: (d) => 'steelblue',
    colorAccessorText: (d) => '#fafafa',
    keyAccessor: (d) => d,
};

export class LabelsChart extends React.Component {
    render() {
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <LabelsChartSimple {...this.props} width={width} height={height} />
                )}
            </AutoSizer>
        );
    }
}
