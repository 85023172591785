/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

// ACTION
import {
    Authenticate,
    FetchUser,
} from '../../../store/old/Authentication/Authentication.action';
import { fetchOrganizationRequest } from '../../../store/old/Entity/Entity.action';


// CONSTANT
import CONSTANTS from '../../Constants';

// COMPONENTS
import InputGroupPrepend from '../../components/InputGroupPrepend';
import { Logo } from '../../components/Logo';

import mainLogoSrc from '../../../assets/images/auk-logo-full.png';


// SELECTOR
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';


import './Account.scss';
import { errorFlash } from '../../components/Flash';
import { session } from '../../auxStore';
import withRouter from '../../Hooks/withRouter';
import translate from '../../utils/translate';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
        };
    }

    // componentDidMount() {
    //     if (session.isAuthenticated) {
    //         this.props.router.navigate('/dashboard');
    //     }
    // }

    fieldValidation = () => {
        const validationResult = {
            email: '',
            password: '',
        };

        const currentData = {
            email: this.state.email,
            password: this.state.password,
        };

        const isEmailValid = currentData.email.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        const isPasswordValid = currentData.password.length >= 6;

        if (!isEmailValid) {
            validationResult.email = 'Please enter a valid email address';
        } else {
            validationResult.email = '';
        }

        if (!isPasswordValid) {
            validationResult.password =
        'Password should be at least 8 characters long';
        } else {
            validationResult.password = '';
        }

        return validationResult;
    };

    handleChangeInput = (key, e) => {
        const value = e.target.value;

        return this.setState({ [key]: value });
    };

    login = () => {
        const { authenticate, fetchOrg, fetchUser } = this.props;

        const validationErrors = this.fieldValidation();

        if (
            validationErrors.password.length > 0 ||
      validationErrors.email.length > 0
        ) {
            this.flashLoginError(validationErrors);
            return;
        }

        authenticate(
            {
                username: this.state.email,
                password: this.state.password,
            },
            (authenticatedUser) => {
                // this.initializeGA(authenticatedUser);
                fetchUser((user) => {
                    authenticatedUser.entity_id !== -1 &&
            fetchOrg(authenticatedUser.entity_id);
                    this.handleRedirectOnLogin(user);
                });
            }
        );
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.login();
    };

    flashLoginError = (data) => {
        errorFlash({
            message: 'Sign in error',
            details: (
                <ol>
                    {Object.keys(data)
                        .filter((key) => data[key])
                        .map((err, i) => (
                            <li key={i}>{data[err]}</li>
                        ))}
                </ol>
            ),
        });
    };

    handleRedirectOnLogin = (user) => {
        const wasRedirected =
      this.props.router.location.state &&
      !(
          this.props.router.location.state.from.pathname === '/' ||
        this.props.router.location.state.from.pathname === '/login'
      );

        if (wasRedirected) {
            return this.props.router.navigate(this.props.router.location.state.from);
        }

        if (
            isEmpty(user.preference) ||
      !user.preference ||
      (user.preference && !user.preference.default_view)
        ) {
            return this.props.router.navigate(`${CONSTANTS.URLS.DASHBOARD}`);
        }

        if (user.preference.default_view) {
            return this.props.router.navigate(user.preference.default_view);
        }

        if (user.entity_id === -1) {
            return this.props.router.navigate(CONSTANTS.URLS.JOIN_COMPANY);
        }
    };

    // initializeGA = (user) => {
    //     initGA({
    //         userId: `${user.user_id}`,
    //         entityId: user.entity_id ? `${user.entity_id}` : null,
    //     });
    //     PageView();
    // };

    render() {
        const validEmail = (
            <input
                type="text"
                className="form-control place"
                value={this.state.email}
                onChange={(event) => this.handleChangeInput('email', event)}
            />
        );

        const validPassword = (
            <input
                type="password"
                className="form-control place"
                value={this.state.password}
                onChange={(event) => this.handleChangeInput('password', event)}
            />
        );

        return (
            <div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col col-md-4 col-md-offset-4">
                                <Logo className="main-logo" src={mainLogoSrc} />
                                <div className="panel">
                                    <div className="panel-header">
                                        <p className="panel-title">
                                            { translate('login') }
                                        </p>
                                    </div>
                                    <div className="panel-body">
                                        <form onSubmit={this.handleSubmit}>
                                            <fieldset>
                                                <InputGroupPrepend
                                                    className="mb-1"
                                                    icon={<i className="far fa-user" />}
                                                    input={validEmail}
                                                />
                                                <InputGroupPrepend
                                                    className="mb-1"
                                                    icon={<i className="fas fa-key" />}
                                                    input={validPassword}
                                                />
                                                <div className="forgot-check">
                                                    <Link
                                                        to={CONSTANTS.URLS.RESET_PASSWORD}
                                                        className="forgot"
                                                    >
                                                        {translate('forgotpassword')}
                                                    </Link>
                                                </div>
                                                <input type="submit" style={{ display: 'none' }} />
                                            </fieldset>
                                        </form>
                                    </div>
                                    <div className="panel-footer">
                                        <Link to={CONSTANTS.URLS.REGISTER}>
                                            <button className="btn b-cancel">
                                                {translate('register')}
                                            </button>
                                        </Link>
                                        <button
                                            type="submit"
                                            className="btn b-save"
                                            onClick={this.login}
                                        >
                                            {translate('login')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        controls: controlsState(rootState),
        active: rootState.entity.active,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authenticate: (data, callback) =>
            dispatch(Authenticate(data, callback)),
        fetchOrg: (entity_id) =>
            dispatch(fetchOrganizationRequest(entity_id)),
        fetchUser: (callback) => dispatch(FetchUser(callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Login));
