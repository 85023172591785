/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Collapse, Form, Input, Select } from 'antd';
import { Link } from 'react-router-dom';
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';
import ColorPicker from '../../components/ColorPicker';
import {
    EllipsisOutlined,
    DeleteFilled,
    InfoCircleOutlined,
} from '@ant-design/icons';
import './ChannelRow.scss';
import { isValidChartTitle } from '../../utils/device';
import BatchRecognitionInputs, {
    BatchRecognitionFormLabel,
    validateBatchRecognition,
} from './components/BatchRecognitionInputs';
import ControlLimitsInputs, {
    ControlLimitsFormLabel,
    validateControlLimits,
} from './components/ControlLimitsInputs';

const getFieldKey = (name, channel) => [`${channel}`, name];

const CHANNEL_VALIDATE = {
    CHART_TITLE: (channel) => [
        {
            whitespace: true,
            message: 'Channel name is required.',
        },
        () => ({
            validator: (_, value) => {
                if (!isValidChartTitle(value))
                    return Promise.reject(
                        new Error('Channel name cannot contain #%&*?|<>{}\\.')
                    );
                return Promise.resolve();
            },
        }),
        (form) => ({
            validator: (_, value) => {
                const modeKey = getFieldKey('mode', channel);
                const mode = form.getFieldValue(modeKey);

                if (mode && !value) {
                    return Promise.reject(new Error('Channel name is required'));
                }

                if (value && !mode) {
                    return Promise.resolve(
                        form.setFields([{ name: modeKey, errors: ['Mode is required'] }])
                    );
                }

                return Promise.resolve(form.setFields([{ name: modeKey, errors: [] }]));
            },
        }),
    ],
    MODE: (channel) => [
        (form) => ({
            validator: (_, value) => {
                const chartTitleKey = getFieldKey('chart_title', channel);
                const chartTitle = form.getFieldValue(chartTitleKey);

                if (chartTitle && !value) {
                    return Promise.reject(new Error('Mode is required'));
                }

                if (value && !chartTitle) {
                    return Promise.resolve(
                        form.setFields([
                            { name: chartTitleKey, errors: ['Channel name is required'] },
                        ])
                    );
                }
                return Promise.resolve(
                    form.setFields([{ name: chartTitleKey, errors: [] }])
                );
            },
        }),
    ],
    BAT_REC: (channel) => [
        (form) => ({
            validator: (_, value) => {
                const modeKey = getFieldKey('mode', channel);
                const mode = form.getFieldValue(modeKey);

                return validateBatchRecognition(mode, value);
            },
        }),
    ],
    CONTROL_LIMITS: () => [
        () => ({
            validator: (_, value) => validateControlLimits(value),
        }),
    ],
};

export const ChannelHeader = (props) => (
    <div className="d-flex" style={{ padding: '8px 16px' }}>
        <Form.Item className="col-1 mb-0" hidden={props.hideMapping}>
            <span className="d-flex justify-content-center">Input</span>
        </Form.Item>
        <Form.Item className="col-1 mb-0" hidden={props.hideMapping}>
            <span className="d-flex justify-content-center">Data</span>
        </Form.Item>
        <Form.Item className="col-1 mb-0">
            <span className="d-flex justify-content-center">Ch</span>
        </Form.Item>
        <Form.Item className="w-50 text-align-center mb-0">
      Name <span style={{ fontSize: 12 }}>(max 20 char)</span>*
        </Form.Item>
        <Form.Item className="w-50 text-align-center mb-0">Mode</Form.Item>
        <Form.Item className="col-1 mb-0"></Form.Item>
        <Form.Item className="col-1 mb-0"></Form.Item>
    </div>
);

const ChannelRow = (props) => {
    const { data = {}, channel, serialMap, isSerial, handleDelete } = props;

    const [expanded, setExpanded] = useState('');

    const onDelete = () => {
        if (isSerial) return;
        handleDelete();
        setExpanded('');
    };

    const [mode, setMode] = useState(data.mode);

    return (
        <Form.Item noStyle>
            <div className="channel-config">
                <Collapse activeKey={[expanded]}>
                    <Collapse.Panel
                        showArrow={false}
                        key="1"
                        header={
                            <div
                                className="d-flex"
                                style={{ flexGrow: 1 }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Form.Item
                                    className="col-1"
                                    hidden={!isSerial || isEmpty(serialMap)}
                                >
                                    <span className="d-flex justify-content-center">
                                        {`${serialMap[channel] ? serialMap[channel][0] : ''}`}
                                    </span>
                                </Form.Item>
                                <Form.Item
                                    className="col-1"
                                    hidden={!isSerial || isEmpty(serialMap)}
                                >
                                    <span className="d-flex justify-content-center">
                                        {`${serialMap[channel] ? serialMap[channel][1] : ''}`}
                                    </span>
                                </Form.Item>
                                <Form.Item
                                    hidden
                                    noStyle
                                    initialValue={data.metadata_id}
                                    name={getFieldKey('metadata_id', channel)}
                                >
                                    <span>{data.metadata_id}</span>
                                </Form.Item>
                                <Form.Item
                                    className="col-1"
                                    initialValue={channel}
                                    name={getFieldKey('channel', channel)}
                                >
                                    <span className="d-flex justify-content-center">
                                        {channel}
                                    </span>
                                </Form.Item>
                                <Form.Item
                                    hidden
                                    noStyle
                                    initialValue={data.speed}
                                    name={getFieldKey('speed', channel)}
                                >
                                    <span>{data.speed}</span>
                                </Form.Item>
                                <Form.Item
                                    className="w-50 px-2"
                                    name={getFieldKey('chart_title', channel)}
                                    initialValue={data.chart_title || ''}
                                    rules={CHANNEL_VALIDATE.CHART_TITLE(channel)}
                                >
                                    <Input
                                        className="w-100"
                                        placeholder="Chart title"
                                        maxLength={20}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="w-50 px-2"
                                    name={getFieldKey('mode', channel)}
                                    initialValue={data.mode}
                                    rules={CHANNEL_VALIDATE.MODE(channel)}
                                >
                                    <Select
                                        className="w-100 h-100"
                                        options={K.MODES}
                                        placeholder="Mode"
                                        onChange={setMode}
                                    />
                                </Form.Item>
                                <Form.Item className="col-1 text-align-center">
                                    <EllipsisOutlined
                                        onClick={() => mode && setExpanded(expanded ? '' : '1')}
                                        style={{ transform: 'rotate(180' }}
                                    />
                                </Form.Item>
                                <Form.Item className="col-1 text-align-center">
                                    <DeleteFilled
                                        className={`${isSerial ? 'disabled' : ''}`}
                                        onClick={onDelete}
                                    />
                                </Form.Item>
                            </div>
                        }
                    >
                        <div className="d-flex flex-column px-3">
                            <Form.Item
                                name={getFieldKey('color', channel)}
                                label="Color"
                                initialValue={data.color}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                            >
                                <ColorPicker className="channel-config__color" />
                            </Form.Item>
                            <Form.Item
                                name={getFieldKey('units', channel)}
                                label="Unit Measure"
                                initialValue={data.units}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                            >
                                <Input
                                    className="w-100"
                                    placeholder="e.g. Celcius"
                                    maxLength={50}
                                />
                            </Form.Item>
                            <Form.Item
                                name={getFieldKey('unit_conversion', channel)}
                                label={
                                    <span>
                    Unit Transform
                                        <Link className="ml-2" to={'/userguide?id=72'}>
                                            <InfoCircleOutlined title="Click to view available operators" />
                                        </Link>
                                    </span>
                                }
                                initialValue={data.unit_conversion}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                            >
                                <Input
                                    className="w-100"
                                    placeholder="E.g. (x/10)*150"
                                    maxLength={80}
                                />
                            </Form.Item>
                            <Form.Item
                                hidden={mode !== '3a'}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                label={<BatchRecognitionFormLabel />}
                                name={getFieldKey('batRec', channel)}
                                initialValue={data.batRec}
                                rules={CHANNEL_VALIDATE.BAT_REC(channel)}
                            >
                                <BatchRecognitionInputs />
                            </Form.Item>
                            <Form.Item
                                hidden={mode !== '3a'}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                label={<ControlLimitsFormLabel />}
                                name={getFieldKey('control_limits', channel)}
                                initialValue={data.control_limits}
                                rules={CHANNEL_VALIDATE.CONTROL_LIMITS()}
                            >
                                <ControlLimitsInputs />
                            </Form.Item>
                            <Form.Item
                                name={getFieldKey('invert', channel)}
                                label="Invert"
                                initialValue={data.invert}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                hidden={mode !== '2a'}
                            >
                                <Select className="w-100 h-100" options={K.INVERT} />
                            </Form.Item>
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        </Form.Item>
    );
};

export default ChannelRow;
