/* eslint-disable */
import React, { createRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import { Collapse, Popover, Menu } from 'antd';
import { isEmpty } from 'lodash';
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import AukButton from '../../components/AukButton';
import withNavigation from "../../Wrappers/HOCs/withNavigation"
import { withSkus } from "../../Wrappers/HOCs/withSkus"
import BlockTitle from '../Assets/shared/BlockTitle';
import AukTooltip from '../../components/AukTooltip';
import LabellerDrawer from '../Labels';
import OEEStackColumnChart from '../../Charts/OEEStackColumnChart';
import BlockOutputChart from "./components/BlockOutputChart"
import BlockOutputNotSupported from "./components/BlockOutputNotSupported"
import OutputText from "./components/OutputText"
import WithBlockUsers from './WithBlockUsers';
import { OEEDials } from '../../Widgets';
import {
    AggregateChartTooltip,
    LabelsChartTooltip,
} from '../../Charts/v1/tooltips/templates';
import { flash } from '../../components/Flash';
import { OEELegend } from '../../Charts';
import { SPAWrapper } from '../../components/SPAWrapper';
import {
    getStackBarLabels,
    getStackBarYRange,
    LabelsChart,
    StackBarChartV1,
} from '../../Charts/v1/';
import CONSTANTS from '../../Constants';
import { blocksCSV } from './Blocks.csv_exporter';
import { chartOeeKeys, oeeChartAccessors } from '../../utils/oee';
import { labelDisplayText } from '../../utils/labels';
import { saveCSV } from '../../utils/csv';
import { LABEL_DRAWER } from '../Labels/components';
import { getDataSubarrByTime } from '../../utils/helpers';

import './Blocks.scss';
import BlockCSVExportModal from './BlockCSVExportModal';
import { RolePermission } from '../../components/Permission';
import translate from '../../utils/translate';

const OUTPUT = 'Output';
const OEE_TIME_SERIES = 'Time Series';
const OEE_AGGREGATED = 'Aggregated';
const VIEWS = [OEE_TIME_SERIES, OUTPUT, OEE_AGGREGATED];

const labelsProps = {
    margin: { top: 0, left: 35, right: 20, bottom: 0 },
    xAccessor: (d) => new Date(d.from),
    x2Accessor: (d) => new Date(d._to),
    colorAccessorLabel: (d) => d.color,
    colorAccessorText: () => '#fafafa',
    textAccessor: labelDisplayText,
    useTooltip: true,
    htmlTooltip: LabelsChartTooltip,
    keyAccessor: (d) => d.label_id,
};

const EXPORT_KEY = 'export';
const CLASSIC_EXPORT_KEY = 'classic_export';

class BlocksPresentation extends React.Component {
    chartsRef;
    constructor(props) {
        super(props);

        this.chartsRef = createRef();
        this.chartsRef.current = [];

        this.state = {
            // view: OEE_AGGREGATED,
            view: OEE_TIME_SERIES,
            brushSelection: undefined,
            brushedData: [],
            nowTagging: '',
            labelSelection: undefined,
            showLabelDrawer: false,
            exportModalVisible: false,
            blockIndex: null,
            asset: null,
        };

        this.download = this.download.bind(this);
        this.brushCancel = this.brushCancel.bind(this);
        this.brushEnd = this.brushEnd.bind(this);
        this.moveBrushes = this.moveBrushes.bind(this);
        this.doubleClickLabel = this.doubleClickLabel.bind(this);
        this.getBrushRange = this.getBrushRange.bind(this);
        this.showExportModal = this.showExportModal.bind(this);
        this.closeExportModal = this.closeExportModal.bind(this);
        this.handleClickMenu = this.handleClickMenu.bind(this);
    }

    showExportModal() {
        this.setState({ exportModalVisible: true });
    }

    closeExportModal() {
        this.setState({ exportModalVisible: false });
    }

    brushEnd(bounds, scaled, brushElement, data) {
        this.setState(
            {
                showLabelDrawer: true,
                nowTagging: LABEL_DRAWER.TYPE.ISSUE_LABELLER,
                brushedData: data,
                brushSelection: bounds,
                labelSelection: undefined,
            },
            () => {
                this.moveBrushes(
                    this.brushElements.filter(function () {
                        return this !== brushElement.node();
                    }),
                    null
                );
            }
        );
    }

    brushCancel() {
        const brushes = d3.selectAll('.brush');
        this.moveBrushes(brushes, null);
        this.setState({
            labelSelection: undefined,
            brushedData: [],
            brushSelection: undefined,
            showLabelDrawer: false,
        });
    }

    moveBrushes(selection, range) {
        selection.transition().call(d3.brushX().move, range);
    }

    doubleClickLabel(block, xScaleAccessor) {
        const chartBrush = d3.select(`#b${block.block_id}`).select('.brush');

        return (d, x) => {
            if (!x) x = xScaleAccessor;

            const brushSelection = this.getBrushRange(d, {
                toAccessor: (l) => l._to,
            });

            const brushedData = getDataSubarrByTime(
                block.oee.oee,
                brushSelection[0],
                brushSelection[1]
            );

            this.setState({
                nowTagging: LABEL_DRAWER.TYPE.ISSUE_LABELLER,
                brushSelection,
                labelSelection: d,
                showLabelDrawer: true,
                brushedData,
            });

            this.moveBrushes(chartBrush, brushSelection.map(x));
        };
    }

    handleSkuLabel(block, index) {
        const chartBrush = d3.select(`#block-${block.block_id}.block-row`).select('.brush');
        return (d, x) => {
            const brushSelection = this.getBrushRange(d, {
                toAccessor: (l) => l._to,
            });

            const brushedData = getDataSubarrByTime(
                block.oee.oee,
                brushSelection[0],
                brushSelection[1]
            );

            this.setState({
                nowTagging: LABEL_DRAWER.TYPE.PRODUCTION_LABELLER,
                brushSelection,
                labelSelection: d,
                showLabelDrawer: true,
                brushedData,
                asset: block.asset,
                blockIndex: index,
            });

            this.moveBrushes(chartBrush, brushSelection.map(x));
        };
    }

    getBrushRange(d, options = {}) {
        const from = options.fromAccessor ? options.fromAccessor(d) : d.from;
        const to = options.toAccessor ? options.toAccessor(d) : d.to;

        const [windowStartDate, windowEndDate] = this.window.map((e) => moment(e));
        const [brushStartDate, brushEndDate] = [moment(from), moment(to)];

        return [
            moment.max(windowStartDate, brushStartDate).toDate(),
            moment.min(windowEndDate, brushEndDate).toDate(),
        ];
    }

    get brushElements() {
        return d3.selectAll('.brush');
    }

    get window() {
        const { upper, lower } = this.props.window;
        return [new Date(lower), new Date(upper)];
    }

    chart(block, { index, isMainChart = false }) {
        const { oee2 } = this.props;
        const { view } = this.state;
        const { window, brushEnd, brushCancel } = this;

        if (view === OUTPUT) {
            if (block.asset_id) {
                return <BlockOutputChart
                    ref={(el) => {
                        if (this.chartsRef.current) {
                            this.chartsRef.current[index] = el;
                        }
                    }}
                    entityId={block.entity_id}
                    assetId={block.asset_id}
                    labelsProps={labelsProps}
                    onBrushEnd={brushEnd}
                    onLabelPress={this.handleSkuLabel(block, index)}
                    output={block.oee.oee.map(({ yield: val, ...values }) => ({ val, ...values }))}
                />
            }

            if (!isMainChart) {
                return <BlockOutputNotSupported />;
            }
        }

        if (view === OEE_TIME_SERIES || (view === OUTPUT && !block.asset_id && isMainChart)) {
            return (
                <div style={{ flexGrow: 1 }} id={`b${block.block_id}`}>
                    <div className="w-100 issue-labels-chart" style={{ height: 18, flexShrink: 0 }}>
                        <LabelsChart
                            {...labelsProps}
                            xScale={d3.scaleTime()}
                            xDomain={window}
                            data={block.labels}
                            onDoubleClick={this.doubleClickLabel(block)}
                        />
                    </div>
                    <div className="w-100 oee-chart">
                        <OEEStackColumnChart
                            data={oee2 ? block.oee.oee2 : block.oee.oee}
                            xDomain={window}
                            brush={{
                                useBrush: true,
                                onBrushEnd: (bounds, scaled, brushElement, data) =>
                                    brushEnd(bounds, scaled, brushElement, data),
                                onBrushCancel: brushCancel,
                            }}
                        />
                    </div>
                </div>
            );
        }

        if (view === OEE_AGGREGATED) {
            const yAccessor = (d, i) => i;
            const chartData = oee2
                ? [block.oee.aggregate_oee2]
                : [block.oee.aggregate_oee];
            const yDomain = chartData.map(yAccessor);

            return (
                <div style={{ flexGrow: 1 }}>
                    <StackBarChartV1
                        xScale={d3.scaleLinear()}
                        yScale={d3.scaleOrdinal()}
                        yDomain={yDomain}
                        getYRange={getStackBarYRange(yDomain)}
                        keys={chartOeeKeys}
                        data={chartData}
                        useDataLabels={true}
                        getDataLabels={getStackBarLabels}
                        yAccessor={yAccessor}
                        htmlTooltip={AggregateChartTooltip}
                        colorAccessor={oeeChartAccessors.color}
                    />
                </div>
            );
        }

        return null;
    }

    oeeOverall(block) {
        const hasOEEData = !isEmpty(block.oee);
        return {
            final_effective: hasOEEData ? block.oee.overall.final_effective : 0,
            loading: hasOEEData ? block.oee.overall.loading : 0,
            availability: hasOEEData ? block.oee.overall.availability : 0,
            performance: hasOEEData ? block.oee.overall.performance : 0,
            quality: hasOEEData ? block.oee.overall.quality : 0,
        };
    }

    getLinkUrl(block) {
        return block.asset
            ? `${CONSTANTS.URLS.ASSET}/${block.block_id}`
            : `${CONSTANTS.URLS.BLOCK}/${block.block_id}`;
    }

    download() {
        const loaders = this.props.count;
        if (loaders)
            return flash({ message: 'Loading... Please wait.', status: 'warning' });

        const { block } = this.props;

        if (isEmpty(block.oee))
            return flash({ message: 'No data', status: 'warning' });

        saveCSV(blocksCSV(block, this.props.masks), `${block.label.split(' ').join('_')}.csv`);
    }

    handleSkuModifySuccess() {
        if (!this.chartsRef || !this.chartsRef.current) {
            return;
        }

        const chartRef = this.chartsRef.current[this.state.blockIndex];
        chartRef.getAssetLabels();

        this.props.onRefreshData();
        this.setState({ showLabelDrawer: false });
    }

    handleClickMenu({ key }) {
        switch (key) {
        case EXPORT_KEY:
            this.showExportModal();
            return;
        case CLASSIC_EXPORT_KEY:
            this.download();
            return;
        default:
            return;
        }
    }

    render() {
        const { brushCancel } = this;
        const { authUser, block, oee2, navigate } = this.props;
        const {
            showLabelDrawer,
            brushSelection,
            brushedData,
            nowTagging,
            labelSelection,
            exportModalVisible,
            asset,
        } = this.state;

        return (
            <SPAWrapper className="blocks-component">
                <BlockCSVExportModal
                    visible={exportModalVisible}
                    onCancel={this.closeExportModal}
                    block={block}
                />
                <LabellerDrawer
                    window={this.window}
                    visible={showLabelDrawer}
                    range={brushSelection}
                    type={nowTagging}
                    selection={labelSelection}
                    brushedData={brushedData}
                    onClose={brushCancel}
                    onZoom={brushCancel}
                    withHandler={false}
                    asset={asset}
                    onSuccess={() => this.handleSkuModifySuccess()}
                    readOnly
                />
                <div className="blocks-component__header">
                    <BlockTitle id={block.block_id}>
                        <div className="d-flex">
                            {/* {this.headerButtons} */}
                            <div className="d-flex">
                                <AukTooltip.Help title={translate('paretoanalysis')}>
                                    <AukButton.Outlined
                                        className="auk-button--round mr-2"
                                        style={{ transform: 'rotate(270deg) scaleY(-1)' }}
                                        onClick={() =>
                                            navigate(`${CONSTANTS.URLS.PARETO}/${block.block_id}`)
                                        }
                                    >
                                        <i className="fas fa-signal i-i" />
                                    </AukButton.Outlined>
                                </AukTooltip.Help>
                                <RolePermission accessLevel="editor">
                                    <AukTooltip.Help title={translate('export')}>
                                        <AukButton.Dropdown
                                            className="auk-button--round mr-2"
                                            icon={<i className="fas fa-download" />}
                                            placement="bottomLeft"
                                            trigger={['click']}
                                            overlay={
                                                <Menu onClick={this.handleClickMenu}>
                                                    <Menu.Item key={EXPORT_KEY}>Export</Menu.Item>
                                                    <Menu.Item key={CLASSIC_EXPORT_KEY}>
                            Classic Export
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                        />
                                    </AukTooltip.Help>
                                </RolePermission>
                            </div>
                            <div className="blocks-component__header__tabs">
                                {VIEWS.map((v) => {
                                    const isActive = this.state.view === v;
                                    return (
                                        <AukButton.ToggleBlue
                                            key={v}
                                            active={isActive}
                                            onClick={() => this.setState({ view: v })}
                                            style={{ fontSize: 12 }}
                                        >
                                            {v}
                                        </AukButton.ToggleBlue>
                                    );
                                })}
                            </div>
                        </div>
                    </BlockTitle>
                </div>
                <div className="blocks--component__body">
                    <div className="block--oee-main">
                        <BlockRow
                            key={block.block_id}
                            oee2={oee2}
                            block={block}
                            access={authUser.check_user_policy_for_block(block.block_id)}
                            chart={this.chart(block, { isMainChart: true })}
                            oeeOverall={this.oeeOverall(block)}
                            window={window}
                            legend
                        />
                    </div>
                    <hr />
                    <div className="block--oee-children w-100">
                        <BlocksList
                            authUser={authUser}
                            view={this.state.view}
                            blocks={block.children}
                            getLinkUrl={this.getLinkUrl.bind(this)}
                            getChart={this.chart.bind(this)}
                            getOeeOverall={this.oeeOverall.bind(this)}
                            window={this.window}
                            oee2={oee2}
                            refs={this.chartsRef}
                        />
                    </div>
                </div>
            </SPAWrapper>
        );
    }
}

const BlocksList = ({
    authUser,
    oee2,
    blocks,
    getLinkUrl,
    getChart,
    getOeeOverall,
    window,
    view,
}) => {
    return (
        <>
            {blocks.map((b, i) => {
                const access = authUser.check_user_policy_for_block(b.block_id);
                return (
                    <BlockRow
                        key={b.block_id}
                        link={getLinkUrl(b)}
                        oee2={oee2}
                        block={b}
                        access={access}
                        chart={getChart(b, { index: i })}
                        oeeOverall={getOeeOverall(b)}
                        window={window}
                        isShowOutput={view === OUTPUT}
                    />
                );
            })}
        </>
    );
};

const BlockRow = ({
    access,
    chart,
    block,
    oeeOverall,
    oee2,
    link,
    legend,
    isShowOutput = false,
}) => {
    const [output, setOutput] = useState(0);
    useEffect(() => {
        if (!isShowOutput && block.asset_id) {
            setOutput(block.oee.oee.reduce((sum, { yield: val }) => {
                if (typeof val === "undefined") {
                    return sum;
                }

                return sum + val;
            }, 0));
        }
    }, [JSON.stringify(block.oee)]);

    return (
        <Collapse ghost defaultActiveKey={block.block_id}>
            <Collapse.Panel
                key={block.block_id}
                header={
                    <WithBlockUsers block={block}>
                        <div className="d-flex flex-fill align-items-center">
                            <span className="d-flex align-items-center justify-content-start">
                                {link ? <Link to={link}>{block.label}</Link> : block.label}
                                <OutputText
                                    isNotSupported={!block.asset_id}
                                    value={output}
                                    isShowOutput={isShowOutput}
                                />
                                {legend ? (
                                    <Popover
                                        placement="rightBottom"
                                        content={
                                            <span className="legend-popover-container d-flex" style={{ width: '60vw' }}>
                                                <OEELegend />
                                            </span>
                                        }
                                    >
                                        <InfoCircleOutlined className="ml-2" />
                                    </Popover>
                                ) : null}
                            </span>
                        </div>
                    </WithBlockUsers>
                }
            >
                {access ? (
                    <div id={`block-${block.block_id}`} className="block-row">
                        <div className="chart-wrapper">{chart}</div>
                        <OEEDials
                            display="row"
                            data={oeeOverall}
                            oee2={oee2}
                            donutProps={{
                                fontSize1: 12,
                                fontSize2: 10,
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className="d-flex justify-content-center align-items-center my-2"
                        style={{ border: '1px solid #ddd', height: 100 }}
                    >
            Access Restricted
                    </div>
                )}
            </Collapse.Panel>
        </Collapse>
    );
};

const mapStateToProps = (state) => ({
    count: state.ui.loader.count,
    masks: state.ui.oee.masks,
});

export default connect(mapStateToProps)(withNavigation(withSkus(BlocksPresentation)));
