/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

// CONSTANT
import CONSTANT from '../../Constants';

// ACTION
// import {
//     createUserPreferencesRequest,
//     updateUserPreferencesRequest,
// } from '../../../store/old/Preference/Preference.action';

// COMPONENTS
import AukButton from '../AukButton';

// STYLE
import './LanguageSelection.scss';

const LanguageArray = ({ callback, selectedLanguage }) => {
    const parent = [];
    let children = [];
    const languages = CONSTANT.LANGUAGES;
    const langsLength = languages.length;

    for (let i = 0; i < langsLength; i++) {
        if (i % 4 === 0) {
            children = [];
            children.push(
                <div
                    className={`col-3 language-item ${
                        selectedLanguage === languages[i].lang ? 'active' : ''
                    }`}
                    key={i}
                    onClick={() => callback(languages[i].lang)}
                >
                    <p className="lang-title">{languages[i].name}</p>
                    <p className="lang-title">{languages[i].nativeName}</p>
                </div>
            );
            parent.push(
                <div className="row language-row" key={i}>
                    {children}
                </div>
            );
        } else {
            children.push(
                <div
                    className={`col-3 language-item ${
                        selectedLanguage === languages[i].lang ? 'active' : ''
                    }`}
                    key={i}
                    onClick={() => callback(languages[i].lang)}
                >
                    <p className="lang-title">{languages[i].name}</p>
                    <p className="lang-title">{languages[i].nativeName}</p>
                </div>
            );
        }
    }

    return parent;
};

class LanguageSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModaLanguage: false,
        };
    }

    handleChangeState = () => {
        this.setState((prev) => {
            return {
                showModaLanguage: !prev.showModaLanguage,
            };
        });
    };

    handleChangeLanguage = (lang) => {
        const {
            userPreferences: { dark, default_view, zoom_level, time },
            // changeLanguage,
            auth,
        } = this.props;

        const body = {
            language: lang,
            dark,
            default_view,
            zoom_level,
            time,
        };

        if (auth) {
            this.saveUserPreferences(body, () => {
                // changeLanguage(lang);
            });
        } else {
            // changeLanguage(lang);
        }
    };

    get isUpdate() {
        return this.props.userPreferences.preference_id;
    }

    get saveUserPreferences() {
        return this.isUpdate
            ? this.props.updateUserPreferences
            : this.props.createUserPreferences;
    }

    render() {
        const { language, auth } = this.props;
        const { showModaLanguage } = this.state;

        return (
            <div className="language-selection-container">
                {auth ? (
                    <div className="control-item" onClick={this.handleChangeState}>
                        <i className="fas fa-globe" style={{ fontSize: 18 }} />
                    </div>
                ) : (
                    <AukButton.Outlined
                        ghost
                        className="mr-2"
                        onClick={this.handleChangeState}
                    >
                        {translate('language')}
                    </AukButton.Outlined>
                )}
                <Modal
                    className="auk-modal"
                    title="Select Language"
                    visible={showModaLanguage}
                    onCancel={() => this.setState({ showModaLanguage: false })}
                    footer={
                        <div className="d-flex justify-content-end">
                            <AukButton.Close
                                onClick={() => this.setState({ showModaLanguage: false })}
                            />
                        </div>
                    }
                >
                    <LanguageArray
                        selectedLanguage={language}
                        callback={(lang) => this.handleChangeLanguage(lang)}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        // language: rootState.localization.language,
        userPreferences: rootState.preference.user,
    };
};

const mapDisPatchToProps = (dispatch) => {
    return {
        // changeLanguage: (language) => dispatch(changeLanguage(language)),
        // updateUserPreferences: (userPreferences, callback) =>
        //     dispatch(updateUserPreferencesRequest(userPreferences, callback)),
        // createUserPreferences: (userPreferences, callback) =>
        //     dispatch(createUserPreferencesRequest(userPreferences, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDisPatchToProps
)(LanguageSelection);
