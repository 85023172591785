/* eslint-disable react/prop-types */
import * as d3 from 'd3';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AssetHeader from './AssetHeader';
import AssetBody from './AssetBody';
import { Permission } from '../../../components/Permission';
import { SPAWrapper } from '../../../components/SPAWrapper';
import { NoData } from '../../../components/None';
import LabellerDrawer from '../../Labels';
import CONSTANTS from '../../../Constants';
import { dataApiQueryParams } from '../../../utils/controls';
import { getDataSubarrByTime } from '../../../utils/helpers';
import { getLabelsChartXScale } from '../../../utils/labels';
import { LABEL_DRAWER } from '../../Labels/components';
import withAsset from '../../../Wrappers/HOCs/withAsset';
import withStreaming from '../../../Wrappers/HOCs/withStreaming';
import { FetchAssetLabels } from '../../../../store/old/Labels/Labels.action';
import { fetchAssetTargets } from '../../../../store/old/Target/Target.action';
import {
    fetchAssetChartsData,
    fetchAssetOeeData,
} from '../../../../store/old/Data/Data.action';
import { eventEmitter } from '../../../auxStore';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';
import './index.scss';
import { fetchBlockIssues } from '../../../../store/old/Blocks/Blocks.action';
import { withIssues } from '../../../Wrappers/HOCs/withIssues';
import { withSkus } from '../../../Wrappers/HOCs/withSkus';

export const AssetDomainContext = React.createContext({});

class AssetViewSimple extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            brushSelection: undefined,
            brushedData: [],
            nowTagging: '',
            labelSelection: undefined,
            targetSelection: undefined,
            showLabelDrawer: false,
            chartSelection: undefined,
            showOutput: false,
        };

        const {
            entity: { entity_id },
            asset: { block_id },
        } = props;

        this.refreshHandler = eventEmitter.on(CONSTANTS.EVENTS.REFRESH, () => {
            const query = this.apiQueryParams();
            this.getData(query);
        });

        this.onBrushEnd = this.onBrushEnd.bind(this);
        this.onBrushCancel = this.onBrushCancel.bind(this);
        this.onClickOutput = this.onClickOutput.bind(this);

        props.getBlockIssues(entity_id, block_id);
    }

    componentWillUnmount() {
        this.refreshHandler();
    }

    apiQueryParams() {
        const { controls } = this.props;

        return { ...dataApiQueryParams(controls) };
    }

    getData(params) {
        const { getChartData, getOeeData, getLabels, getTargets, asset, entity } = this.props;
        const { entity_id } = entity;
        const { asset_id } = asset;
        const { date_range } = params;

        getChartData(entity_id, asset_id, params);
        getOeeData(entity_id, asset_id, params);
        getLabels(asset_id, { date_range });
        getTargets(asset_id, { date_range });
    }

    getBrushRange = (d, options = {}) => {
        const from = options.fromAccessor ? options.fromAccessor(d) : d.from;
        const to = options.toAccessor ? options.toAccessor(d) : d.to;

        const [windowStartDate, windowEndDate] = this.window.map((e) => moment(e));
        const [brushStartDate, brushEndDate] = [moment(from), moment(to)];

        return [
            moment.max(windowStartDate, brushStartDate).toDate(),
            moment.min(windowEndDate, brushEndDate).toDate(),
        ];
    };

    doubleClickLabel = (
        nowTagging,
        chartSelection,
        data,
        xScaleAccessor
    ) => {
        return (d, x) => {
            if (!x) x = xScaleAccessor;

            const brushSelection = this.getBrushRange(d, {
                toAccessor: (l) => l._to,
            });

            const brushedData = getDataSubarrByTime(
                data,
                brushSelection[0],
                brushSelection[1]
            );

            this.setState({
                nowTagging,
                brushSelection,
                labelSelection: d,
                showLabelDrawer: true,
                brushedData,
                chartSelection,
            });

            this.moveBrushes(brushSelection.map(x));
        };
    };

    onBrushEnd = (
        nowTagging,
        chartSelection,
        brushSelection, // bounds
        scaled,
        brushedData
    ) => {
        const [brushFrom, brushTo] = brushSelection;
        if (moment(brushFrom).isSame(moment(brushTo))) return this.onBrushCancel();

        this.moveBrushes(scaled);
        this.setState({
            nowTagging,
            chartSelection,
            brushSelection,
            showLabelDrawer: true,
            brushedData,
        });
    };

    onBrushCancel = () => {
        this.moveBrushes(null);
        this.setState({
            showLabelDrawer: false,
            chartSelection: undefined,
            brushSelection: undefined,
            labelSelection: undefined,
            brushedData: undefined,
            nowTagging: '',
        });
    };

    onClickOutput = () => {
        this.setState({ showOutput: !this.state.showOutput });
    };

    moveBrushes = (range) => {
        this.brushes.transition().call(d3.brushX().move, range);
    };

    get brushes() {
        return d3.select('.asset-default__body__charts').selectAll('.brush');
    }

    get window() {
        const { lower, upper } = this.props.window;
        return [new Date(lower), new Date(upper)];
    }

    render() {
        const { controls, labels, data, asset } = this.props;

        const {
            brushSelection,
            showLabelDrawer,
            nowTagging,
            labelSelection,
            brushedData,
            chartSelection,
            showOutput,
        } = this.state;

        const {
            window,
            doubleClickLabel,
            onBrushEnd,
            onBrushCancel,
            onClickOutput,
        } = this;

        const context = {
            controls,
            asset,
            labels,
            data,
            window,
            doubleClickLabel,
            onBrushEnd,
            onBrushCancel,
            onClickOutput,
            brushSelection,
            showOutput,
        };

        const assetHasCharts = !!asset.charts.length;

        return (
            <AssetDomainContext.Provider value={context}>
                <Permission resource="assets" forBlock blockId={asset.block_id}>
                    <SPAWrapper className="asset-view">
                        {assetHasCharts && (
                            <LabellerDrawer
                                key={asset.asset_id}
                                handlerOnClick={() =>
                                    this.setState({
                                        showLabelDrawer: true,
                                        nowTagging: LABEL_DRAWER.TYPE.FORWARD_LABELLER,
                                    })
                                }
                                window={window}
                                visible={showLabelDrawer}
                                range={brushSelection}
                                type={nowTagging}
                                selection={labelSelection}
                                chartSelection={chartSelection}
                                asset={asset}
                                brushedData={brushedData}
                                onClose={onBrushCancel}
                                onZoom={onBrushCancel}
                                onClickIssueLabelItem={doubleClickLabel(
                                    LABEL_DRAWER.TYPE.ISSUE_LABELLER,
                                    asset.block,
                                    asset.oee.oee,
                                    getLabelsChartXScale(window, '.issue-labels-chart .main')
                                )}
                                onClickProductionLabelItem={doubleClickLabel(
                                    LABEL_DRAWER.TYPE.PRODUCTION_LABELLER,
                                    asset.primary,
                                    asset.primary.data,
                                    getLabelsChartXScale(window, '.issue-labels-chart .main')
                                )}
                                onSuccess={() => {
                                    eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH, true);
                                    this.onBrushCancel();
                                }}
                            />
                        )}
                        <div className="asset-default">
                            <AssetHeader />
                            {assetHasCharts ? (
                                <AssetBody />
                            ) : (
                                <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                                    <NoData />
                                </div>
                            )}
                        </div>
                    </SPAWrapper>
                </Permission>
            </AssetDomainContext.Provider>
        );
    }
}

const mapStateToProps = (appState) => ({
    labels: appState.label.labels,
    data: appState.data,
    entity: currentEntitySelector(appState),
});

const mapDispatchToProps = (dispatch) => ({
    getChartData: (entityId, assetId, query, callback) => dispatch(fetchAssetChartsData(entityId, assetId, query, callback)),
    getOeeData: (entityId, assetId, query, callback) => dispatch(fetchAssetOeeData(entityId, assetId, query, callback)),
    getLabels: (assetId, queryParams, callback) => dispatch(FetchAssetLabels(assetId, queryParams, callback)),
    getTargets: (assetId, query, callback) => dispatch(fetchAssetTargets(assetId, query, callback)),
    getBlockIssues: (entityId, blockId, callback) => dispatch(fetchBlockIssues(entityId, blockId, callback)),
});

const AssetView = connect(mapStateToProps, mapDispatchToProps)(AssetViewSimple);

export default withIssues(withSkus(withAsset(withStreaming(AssetView))));
