import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LabelConstants as K } from '../../../../store/old/Labels/Labels.constants';
import './LabelCounters.scss';

let timerId = null;
export const OEETimer = (props: any) => {
    const { tagging } = props;

    if (!tagging) {
        clearInterval(timerId);
        return (
            <div className="label-counter">
                <div className="start-stop-circle" />
                <p className="label">
                    <span className="text-uppercase">Issue</span>: Elapsed
                </p>
                <p className="value" style={{ opacity: 0.8 }}>
          --:--:--
                </p>
            </div>
        );
    }

    const [oeeTotalTime, setOeeTotalTime] = useState(moment().startOf('day'));

    useEffect(() => {
        startTimer();

        return () => clearInterval(timerId);
    }, []);

    const startTimer = () => {
        timerId = setInterval(() => {
            const currentTime = moment();
            const oeeFrom = moment(tagging.from);
            const diff = currentTime.diff(oeeFrom, 'second');
            const newOeeTotalTime = moment(oeeTotalTime).add(diff, 'second');
            setOeeTotalTime(newOeeTotalTime);
        }, 1000);
    };

    return (
        <div className="label-counter">
            <div className="start-stop-circle start" />
            <p className="label">OEE</p>
            <p className="value">{moment(oeeTotalTime).format('HH:mm:ss')}</p>
        </div>
    );
};

export const SKUTotal = (props: any) => {
    const { tagging } = props;

    const defaultComponent = (
        <div className="label-counter">
            <div className="start-stop-circle" />
            <p className="label">
                <span className="text-uppercase">SKU</span>: Output
            </p>
            <p className="value" style={{ opacity: 0.8 }}>
        --
            </p>
        </div>
    );

    if (!tagging) return defaultComponent;

    const isTaggingSKU = tagging.label_values.find(
        ({ type }) => type === K.TYPES.SKU
    );

    if (!isTaggingSKU) return defaultComponent;
    return (
        <div className="label-counter">
            <div className="start-stop-circle start" />
            <p className="label">
                <span className="text-uppercase">SKU</span>: Output
            </p>
            <p className="value">
                {isTaggingSKU.total ? isTaggingSKU.total.toFixed(0) : 0}
            </p>
        </div>
    );
};

const LabelCounters = (props: any) => {
    return (
        <div className="label-counters">
            <OEETimer tagging={props.asset.liveIssueLabel} />
            <SKUTotal tagging={props.asset.liveProductLabel} />
        </div>
    );
};

export default LabelCounters;
