/* eslint-disable react/prop-types */

import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import moment from 'moment';
import { Checkbox, Input, Popover } from 'antd';
import { has, round } from 'lodash';
import { useSelector } from 'react-redux';
import {
    ExclamationCircleOutlined,
    SaveFilled,
} from '@ant-design/icons';

// REDUX
import { issuesState } from '../../../store/old/Issues/Issues.selector';
import { blocksState } from '../../../store/old/Blocks/Blocks.selector';

// HELPERS
import CONSTANTS from '../../Constants';
import { flash } from '../../components/Flash';
import {
    uiDatetimeFormatWithSeconds,
} from '../../utils/helpers';
import { generatePermittedIssuesTreeData } from '../IssueManagement/IssuesTreeData';
import {
    checkFutureLabel,
    compileLabel,
    findOverlappingLabels,
    generateStopLabel,
    getSpan,
} from '../../utils/labels';
import { oeeFactors, oeeWaterfall } from '../../utils/oee';

// COMPONENTS
import AukButton from '../../components/AukButton';
import SearchableTree from '../../components/SearchableTree';
import {
    Labeller,
    LabellerBody,
    LabellerBodyItem,
    LabellerHeader,
    LabellerHeaderItem,
    LabelRangeZoom,
    StopLabelIcon,
} from './components';
import { DangerZone, DangerZoneItem } from '../../components/DangerZone';
import { NoAccess } from '../../components/None';

import { LabelNotesInput, LabelsContext } from '.';
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';
import withLabelling from '../../Wrappers/HOCs/withLabelling';

const isQualityIssue = (issue) => {
    if (!issue) return false;
    return issue.oee === 'rj' || issue.oee === 'rw';
};


export const IssueLabeller = withLabelling((props) => {
    const { asset, openDataDelete, readOnly, onSuccess } = useContext(LabelsContext);

    const { createLabel, updateLabel, deleteLabel, stopLabel, showLabelNotes } =
    props;

    const { issues } = useSelector(issuesState);
    const { oee2 } = useSelector(controlsState);

    const {
        range,
        selection,
        brushedData = [],
        onClickIssueLabelItem,
        chartSelection,
    } = props;

    const [formData, setFormdata] = useState({
        issue_id: selection ? selection.issue.issue_id : null,
    });
    const [override, setOverride] = useState(
        selection ? selection.override : false
    );
    const [notes, setNotes] = useState(selection ? selection.notes : '');

    const [brushStart, brushEnd] = range;

    useEffect(() => {
        if (selection) {
            setOverride(selection.override);
            setNotes(selection.notes);
        }
    }, [selection]);

    const key = useMemo(() => (selection ? selection.label_id : ''), [selection]);
    const selectedIssue = useMemo(() => issues[formData.issue_id], [formData]);

    const brushedOEE = useMemo(() => {
        const index = oee2 === true ? 1 : 0;
        return oeeFactors(oeeWaterfall(brushedData))[index];
    }, [brushedData, oee2]);

    const spanDuration = useMemo(() => getSpan(range), [range]);

    const brushedOutput = useMemo(() => {
        return brushedData.reduce((acc, curr) => (acc += curr.yield), 0);
    }, [brushedData]);

    let handleSave = () => {};
    let handleDelete = () => {};

    if (asset) {
        const { asset_id } = asset;
        handleSave = useCallback(() => {
            if (!formData.issue_id || !issues[formData.issue_id])
                return flash({ message: 'Please select an issue', status: 'warning' });

            const hasDefects = has(formData, 'defects');

            if (hasDefects && +formData.defects < 0) {
                return flash({
                    message: 'Defects cannot be negative',
                    status: 'warning',
                });
            }

            if (hasDefects && +formData.defects > brushedOutput) {
                return flash({
                    message: 'Defect quantity cannot be greater than output',
                    status: 'warning',
                });
            }

            const overlappedLabels = findOverlappingLabels(
                asset.issueLabels,
                brushStart,
                brushEnd,
                selection
            );

            if (overlappedLabels.length) {
                return flash({
                    message: 'Cannot overwrite existing label(s)',
                    status: 'warning',
                });
            }

            const values = {
                issue: formData.issue_id,
                notes: showLabelNotes ? notes : undefined,
            };

            const requestPayload = compileLabel(
                {
                    values,
                    from: brushStart,
                    to: brushEnd,
                    label_id: selection ? selection.label_id : null,
                },
                {
                    override,
                    defects: hasDefects
                        ? formData.defects === null
                            ? formData.defects
                            : +formData.defects
                        : undefined,
                }
            );

            if (checkFutureLabel(requestPayload))
                return flash({
                    message: 'Cannot create label in future time.',
                    status: 'warning',
                });

            const saveHandler = selection ? updateLabel : createLabel;
            saveHandler(asset_id, requestPayload, onSuccess);
        }, [formData, override, range, notes, showLabelNotes]);

        handleDelete = () => {
            if (!selection) return;
            if (!selection.isComplete) {
                return flash({
                    message: 'Please stop label before deleting.',
                    status: 'warning',
                });
            }
            deleteLabel(asset_id, selection, onSuccess);
        };
    }

    return (
        <Labeller className="issue-labeller" id="issueLabeller" key={key}>
            <LabellerHeader className="flex-column">
                <LabellerHeaderItem label="Span:">{spanDuration}</LabellerHeaderItem>
                <LabellerHeaderItem label="Range">
                    <LabelRangeZoom
                        range={range}
                        disableZoom={selection ? !selection.isComplete : false}
                    />
                </LabellerHeaderItem>
            </LabellerHeader>
            <LabellerBody>
                <LabellerBodyItem
                    header={`${
                        chartSelection
                            ? `${chartSelection.label} OEE Selection`
                            : 'OEE Selection'
                    }`}
                    addonHeader={[
                        <DangerZoneItem
                            key={key}
                            title="Delete device data"
                            description="Delete highlighted data."
                            handleClick={openDataDelete}
                            action="Delete"
                            confirmText="Are you sure ? This action is irreversible."
                        />,
                    ]}
                >
                    <div>
                        {oee2 ? 'OEE-2' : 'OEE-1'}: {round(brushedOEE.value, 2)} %
                    </div>
                    {asset && asset.primary && asset.primary.mode !== '2a' ? (
                        <div>
              Output: {round(brushedOutput, 2).toLocaleString()}{' '}
                            {asset.primary && asset.primary.units}
                        </div>
                    ) : null}
                </LabellerBodyItem>
                {selection ? (
                    <LabellerBodyItem header={'Selection'}>
                        <IssueLabelListItem
                            onClick={onClickIssueLabelItem}
                            data={selection}
                            readOnly={readOnly}
                            onStop={(d) =>
                                stopLabel(asset.asset_id, generateStopLabel(d), (l) =>
                                    onSuccess(l)
                                )
                            }
                        />
                    </LabellerBodyItem>
                ) : null}
                {!readOnly ? (
                    <>
                        <LabellerBodyItem header={selection ? 'Edit' : 'Create'}>
                            <PermittedIssuesTreeSelect
                                withDefects
                                data={selection}
                                onChange={setFormdata}
                            />
                        </LabellerBodyItem>
                        {(selection && selection.isComplete) || !selection ? (
                            <LabellerBodyItem header="Options">
                                <Checkbox
                                    checked={override}
                                    onChange={(e) => setOverride(e.target.checked)}
                                >
                                    <div className="d-flex align-items-center">
                    OEE Override{' '}
                                        {isQualityIssue(selectedIssue) ? (
                                            <Popover
                                                placement="right"
                                                content={
                                                    <span style={{ width: 200, color: '#dc3545' }}>
                            Does not apply to QUA issues
                                                    </span>
                                                }
                                            >
                                                <ExclamationCircleOutlined
                                                    className="ml-2"
                                                    style={{ color: '#dc3545' }}
                                                />
                                            </Popover>
                                        ) : null}
                                    </div>
                                </Checkbox>
                            </LabellerBodyItem>
                        ) : null}
                        {showLabelNotes ? (
                            <LabellerBodyItem header="Notes">
                                <LabelNotesInput
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </LabellerBodyItem>
                        ) : null}
                        <AukButton.Blue
                            className="my-3"
                            icon={<SaveFilled />}
                            onClick={handleSave}
                        >
              Save
                        </AukButton.Blue>
                        {selection && (
                            <LabellerBodyItem header="Last Modified">
                                <div className="labeller__last-modified">
                                    {selection.lastModified}
                                </div>
                            </LabellerBodyItem>
                        )}
                        {selection ? (
                            <DangerZone header="Delete">
                                <DangerZoneItem
                                    title="Label"
                                    description="Delete selected label."
                                    handleClick={handleDelete}
                                    action="Delete"
                                    confirmText="Are you sure? This may affect your OEE."
                                />
                            </DangerZone>
                        ) : null}
                    </>
                ) : (
                    <>
                        {showLabelNotes ? (
                            <LabellerBodyItem header="Notes">
                                <LabelNotesInput
                                    value={notes}
                                    disabled={true}
                                    showCount={false}
                                    // onChange={(e) => setNotes(e.target.value)}
                                />
                            </LabellerBodyItem>
                        ) : null}
                        <NoAccess
                            description={'Action not available. Please tag in asset view.'}
                        />
                    </>
                )}
            </LabellerBody>
        </Labeller>
    );
});

export const PermittedIssuesTreeSelect = (props) => {
    const { asset } = useContext(LabelsContext);
    const { withDefects, data, onChange } = props;

    const issuesStore = useSelector(issuesState);
    const blocksStore = useSelector(blocksState);

    const treeData = useMemo(
        () =>
            generatePermittedIssuesTreeData(
                issuesStore,
                [asset.block_id],
                blocksStore.blocks_issues_exclusions
            ),
        [issuesStore, blocksStore]
    );

    const [defects, setDefects] = useState(
        data && data.defects ? data.defects : null
    );
    const [selection, setSelection] = useState(data ? [data.issue] : []);

    const isQualitySelection = useMemo(
        () => (selection.length ? isQualityIssue(selection[0]) : false),
        [selection]
    );

    const getFormData = (value) => {
        const defectCount = withDefects
            ? value
                ? isQualityIssue(value)
                    ? { defects }
                    : {}
                : {}
            : {};

        return {
            issue_id: value ? value.issue_id : null,
            ...defectCount,
        };
    };

    useEffect(() => {
        onChange && onChange(getFormData(selection.length ? selection[0] : null));
    }, [selection, defects]);

    const showDefectsInput =
    withDefects &&
    asset.primary &&
    (asset.primary.mode === CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT ||
      asset.primary.mode === CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE ||
      asset.primary.mode === CONSTANTS.CHANNELS.MODES.ANALOG_MAX ||
      asset.primary.mode === CONSTANTS.CHANNELS.MODES.ANALOG_MIN) &&
    isQualitySelection;

    const selectedKeys = selection.map(({ issue_id }) => issue_id);
    const defaultSearch = data ? data.issue.name : '';

    return (
        <>
            <div className="w-100" style={{ height: 380 }}>
                <SearchableTree
                    search={{ placeholder: 'Type to filter' }}
                    tree={{
                        treeData,
                        onSelect: (d) =>
                            setSelection(d[0] ? [issuesStore.issues[d[0]]] : []),
                        selectedKeys: selectedKeys,
                    }}
                    data={issuesStore.issues}
                    parentKeyAccessor={(i) => i.parent_issue_id}
                    nodeKeyAccessor={(treeNode) => +treeNode.key}
                    defaultExpanded={[1, 2, 3, 4]}
                    defaultSearch={defaultSearch}
                />
            </div>
            {showDefectsInput ? (
                <Input
                    addonBefore="Defects"
                    type="number"
                    placeholder="No. of reject / rework items"
                    value={defects}
                    onChange={(e) => setDefects(e.target.value)}
                />
            ) : null}
        </>
    );
};

export const IssueLabelListItem = (props) => {
    const { data, onStop, onClick = (l) => {}, readOnly } = props;

    return (
        <div
            className="label-vlist-item d-flex align-items-center px-2 justify-content-between"
            onClick={() => onClick(data)}
        >
            <div className="label-vlist-item__descriptor d-flex flex-column h-100">
                <div className="d-flex">
                    <span
                        className="mr-2"
                        style={{ fontSize: 14, color: data.issue.color }}
                    >
                        <i className="fas fa-square" />
                    </span>
                    <span>{data.issue.name}</span>
                </div>
                <div style={{ fontSize: 10 }}>
                    {moment(data.from).format(uiDatetimeFormatWithSeconds)} -{' '}
                    {data.isComplete
                        ? moment(data.to).format(uiDatetimeFormatWithSeconds)
                        : 'On-going'}
                </div>
            </div>
            {!data.isComplete && !readOnly && (
                <StopLabelIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        onStop(data);
                    }}
                />
            )}
        </div>
    );
};
