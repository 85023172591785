/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import 'whatwg-fetch';
import Markdown from 'react-markdown';
import { connect } from 'react-redux';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { orderBy } from 'lodash';

import './UserGuide.scss';
import jumbotron from '../../../assets/images/UserGuideJumbotron.jpg';

// HELPER
import { getMapFromArr } from '../../utils/helpers';

// CONSTANT
import Constants from '../../Constants';
import { getQueryParams } from '../../utils/service';
import withRouter from '../../Hooks/withRouter';
import { SPAWrapper } from '../../components/SPAWrapper';

class UserGuide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rawArray: [],
            content: null,
            collapse: true,
            active: null,
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const query = getQueryParams(this.props.router.location.search) || {};
        const { id } = query;

        fetch(Constants.CMS_API + 'user-guides', {
            method: 'GET',
        }).then(async (v) => {
            const jsonResponse = await v.json();
            const mapData = getMapFromArr(jsonResponse, 'id');

            return this.setState({
                rawArray: jsonResponse,
                content: mapData,
                active: id || null,
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { id } = getQueryParams(this.props.router.location.search) || {};

        if (id && id != this.state.active) {
            this.setState({ active: id });
        }
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    queryString(element) {
        return element.id ? `?id=${element.id}` : '';
    }

    get sidebar() {
        const { rawArray } = this.state;
        const { language } = this.props;

        const titleLanguageField =
      language === 'en' ? `menu_title` : `menu_title_${language}`;

        const subheadingHtml = (element) => {
            const isActive = element.id == this.state.active;

            return (
                <li
                    key={element.id}
                    className={`sidenavlist ${isActive ? 'is-active' : ''}`}
                    onClick={() => {
                        this.setState({ active: element.id });
                        console.log(this.queryString(element))
                        this.props.router.navigate(
                            `${window.location.pathname}${this.queryString(element)}`
                        );
                    }}
                >
                    {element[titleLanguageField]}
                </li>
            );
        };

        const headingHtml = (element, children, idx) => {
            return (
                <ul key={idx}>
                    <li className="sidenavheadings">{element[titleLanguageField]}</li>
                    <ul>{children}</ul>
                </ul>
            );
        };

        return rawArray
            .filter((v) => !v.parent)
            .map((heading, i) => {
                const subheadings = orderBy(
                    heading.chilrens,
                    ['order'],
                    ['asc']
                ).reduce((acc, curr, j) => {
                    return [...acc, subheadingHtml(curr)];
                }, []);
                return headingHtml(heading, subheadings, i);
            });
    }

    get pageContent() {
        const { language } = this.props;
        const { content, active } = this.state;

        if (!content || !active) return null;

        const contentLanguageField =
      language === 'en' ? `content` : `content_${language}`;

        return content[active][contentLanguageField] || content[active]['content'];
    }

    get mdContent() {
        const { pageContent } = this;

        if (!pageContent) {
            return (
                <div className="markdown-body" style={{ marginTop: 100 }}>
                    <img src={jumbotron} />
                </div>
            );
        }

        return <Markdown className="markdown-body">{pageContent}</Markdown>;
    }

    render() {
        return (
            <SPAWrapper className="mainpage">
                <Button className="btn-menu--mobile" onClick={this.toggle}>
          =
                </Button>
                <Collapse isOpen={this.state.collapse}>
                    <CardBody className="sidenavmobile">{this.sidebar}</CardBody>
                </Collapse>
                <div className="maincontentmobile">{this.mdContent}</div>
                <div className="sidenav">{this.sidebar}</div>
                <div className="maincontent">{this.mdContent}</div>
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        // language: rootState.localization.language,
        language: 'en'
    };
};

export default connect(mapStateToProps, null)(withRouter(UserGuide));
