/* eslint-disable react/prop-types */

import React, {
    useMemo,
    useState,
} from 'react';
import { TagFilled } from '@ant-design/icons';

// INTERFACES
import {
    Block,
    Fusion,
    Label,
    Metadatum,
    Target,
} from '../../models';

// COMPONENTS
import AukDrawerRight from '../../components/AukDrawerRight';
import DataDeleteModal from '../Assets/Asset/components/DataDeleteModal';
import TextArea from '../../components/AukInput/TextArea';
import { LABEL_DRAWER } from './components';
import { IssueLabeller } from './IssueLabeller';
import { ProductionLabeller } from './ProductionLabeller';
import { ForwardLabeller } from './ForwardLabeller';
import { TargetProductionLabeller } from './TargetProductionLabeller';

import './index.scss';

export const LabelsContext = React.createContext({});

const LabellerDrawer = (props) => {
    const {
        selection,
        chartSelection,
        window,
        range,
        brushedData,
        asset,
        onClose,
        type,
        visible,
        onClickIssueLabelItem,
        onClickProductionLabelItem,
        handlerOnClick,
        readOnly = false,
        withHandler = true,
        onZoom = () => {},
        onSuccess = () => {},
        onRemoveSuccess,
    } = props;

    const [showDataDelete, setShowDataDelete] = useState(false);
    const openDataDelete = () => setShowDataDelete(true);
    const closeDataDelete = () => setShowDataDelete(false);

    const content = useMemo(() => {
        if (!visible) return null;

        if (type === LABEL_DRAWER.TYPE.FORWARD_LABELLER)
            return (
                <ForwardLabeller
                    onClickProductionLabelItem={onClickProductionLabelItem}
                    onClickIssueLabelItem={onClickIssueLabelItem}
                />
            );

        if (type === LABEL_DRAWER.TYPE.ISSUE_LABELLER)
            return (
                <IssueLabeller
                    selection={
                        selection && selection instanceof Label && selection.issue
                            ? selection
                            : undefined
                    }
                    range={range || [new Date(), new Date()]}
                    brushedData={brushedData}
                    onClickIssueLabelItem={onClickIssueLabelItem}
                    chartSelection={
                        chartSelection instanceof Block ? chartSelection : undefined
                    }
                />
            );

        if (type === LABEL_DRAWER.TYPE.PRODUCTION_LABELLER)
            return (
                <ProductionLabeller
                    selection={
                        selection && selection instanceof Label && !selection.issue
                            ? selection
                            : undefined
                    }
                    range={range || [new Date(), new Date()]}
                    brushedData={brushedData}
                    onClickProductionLabelItem={onClickProductionLabelItem}
                    chartSelection={
                        chartSelection instanceof Metadatum ||
            chartSelection instanceof Fusion
                            ? chartSelection
                            : undefined
                    }
                />
            );

        if (type === LABEL_DRAWER.TYPE.TARGET_PRODUCTION_LABELLER)
            return (
                <TargetProductionLabeller
                    selection={selection instanceof Target ? selection : undefined}
                />
            );
    }, [visible, selection, range, brushedData, asset, type]);

    return (
        <LabelsContext.Provider
            value={{
                asset,
                window,
                openDataDelete,
                readOnly,
                onZoom,
                onSuccess,
                onRemoveSuccess,
            }}
        >
            <AukDrawerRight
                className="auk-drawer-labeller"
                autoFocus
                withHandler={withHandler}
                visible={visible}
                handlerIcon={<TagFilled style={{ color: '#fafafa' }} />}
                handlerTooltip="Click for forward tagging"
                handlerOnClick={handlerOnClick}
                maskStyle={{ display: 'none', pointerEvents: 'none' }}
                style={{ position: 'absolute', pointerEvents: 'none' }}
                onClose={onClose}
            >
                {content}
                {!readOnly && range && (
                    <DataDeleteModal
                        visible={showDataDelete}
                        asset={asset}
                        range={range}
                        close={closeDataDelete}
                    />
                )}
            </AukDrawerRight>
        </LabelsContext.Provider>
    );
};

export default LabellerDrawer;

export * from './IssueLabeller';
export * from './ProductionLabeller';
export * from './TargetProductionLabeller';
export * from './ForwardLabeller';

export const LabelNotesInput = (props) => {
    return (
        <TextArea
            className="label-notes-input"
            showCount
            maxLength={255}
            {...props}
        />
    );
};
