/* eslint-disable react/display-name */
import React from "react";
import { useNavigate } from "react-router-dom";

const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
}

withNavigation.displayName = "withNavigation";

export default withNavigation;
