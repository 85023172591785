import { put, call, all, race, take, takeLatest, takeEvery } from 'redux-saga/effects';
import { flatten, uniq, mapValues, values, keys } from 'lodash';

// SERVICES
import {
    api_getAssetOEEData,
    api_getAssetChartsData,
    api_getAssetOperatorData,
    api_getSummaryTileData,
    api_getTileDataAndLabels,
    api_getBlockOEEData,
} from './Data.services';

// ACTION
import {
    addBlocksOeeDataResource,
    addTimesSeriesDataResource,
    addSummaryTilesOeeDataResource,
} from './Data.action';

import { setBlockBottleneck } from '../Blocks/Blocks.action';

// HELPERS
import { DataConstants as K } from './Data.constants';
import { flattenTree, getMapFromArr } from '../../../legacy/utils/helpers';
import { OEEFactory } from '../../../legacy/utils/oee';
import { segmentQueryRange } from '../../../legacy/utils/date';
import { TimeSeriesFactory } from '../../../legacy/utils/data';

// REDUX STORE
import { store } from '../..';
import { Label, parseLabelArguments } from '../../../legacy/models';
import {
    AddLabelsResource,
    SetLabelsResource,
    SetLabelsState,
} from '../Labels/Labels.action';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { toggleSummaryTileLoading } from '../UI/Dashboard/Dashboard.action';
import { errorFlash } from '../../../legacy/components/Flash';
import { assetsState } from '../Assets/Assets.selector';
import CONSTANTS from '../../../legacy/Constants';

const getDataResource = (data, idAccessor, valueAccessor) => {
    return data.reduce(
        (acc, curr) => ({ ...acc, [idAccessor(curr)]: valueAccessor(curr) }),
        {}
    );
};

// GET ASSET CHART DATA
function* handleGetAssetChartsData(action) {
    try {
        const { asset_id, query, entity_id } = action.payload;

        const [channelsData, fusionsData] = yield call(
            api_getAssetChartsData,
            entity_id,
            asset_id,
            query
        );

        const metaDataResource = getDataResource(
            channelsData,
            (d) => d.metadata_id,
            (d) => TimeSeriesFactory(d.data)
        );

        const fusionsDataResource = getDataResource(
            fusionsData,
            (d) => d.fusion_id,
            (d) => TimeSeriesFactory(d.data)
        );

        yield put(
            addTimesSeriesDataResource(metaDataResource, fusionsDataResource)
        );

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAssetChartsDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_CHARTS_DATA_REQUEST,
        handleGetAssetChartsData
    );
}

// GET ASSET OEE DATA
function* handleGetAssetOeeData(action) {
    try {
        const appState = store.getState();

        const { asset_id, query, entity_id } = action.payload;

        const res = yield call(api_getAssetOEEData, entity_id, asset_id, query);

        const { block_id } = assetsState(appState).assets[asset_id];
        yield put(addBlocksOeeDataResource({ [block_id]: OEEFactory(res) }));

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAssetOeeDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_OEE_DATA_REQUEST,
        handleGetAssetOeeData
    );
}

function* handleGetAssetOperatorData(action) {
    try {
        const appState = store.getState();
        const {
            assets: { assets },
        } = appState;

        const { asset_id, query, entity_id } = action.payload;

        const res = yield call(
            api_getAssetOperatorData,
            entity_id,
            asset_id,
            query
        );

        const labels = getMapFromArr(
            res.labels.map(
                (l) => new Label(...parseLabelArguments({ ...l, asset_id }))
            ),
            'label_id'
        );

        const { primary } = assets[asset_id];

        const data = {
            [primary.metadata_id || primary.fusion_id]: TimeSeriesFactory(res.data),
        };

        yield all([
            put(SetLabelsResource(labels)),
            put(
                addTimesSeriesDataResource(
                    primary.metadata_id && data,
                    primary.fusion_id && data
                )
            ),
        ]);

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAssetOperatorDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_OPERATOR_DATA_REQUEST,
        handleGetAssetOperatorData
    );
}

const generateBlockLabelsResource = (root) => {
    return flattenTree(root, (n) => n.children)
        .map((b) => {
            const labels = b.effective_labels
                ? b.effective_labels.reduce((acc, curr) => {
                    const l = new Label(
                        ...parseLabelArguments({
                            ...curr,
                            block_id: b.block_id,
                        })
                    );
                    acc[l.label_id] = l;
                    return acc;
                }, {})
                : [];

            return { [b.block_id]: labels };
        })
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});
};

function* handleGetBlockOeeData(action) {
    try {
        const appState = store.getState();
        const { tzStartDifference } = currentEntitySelector(appState);

        const { block_id, query, entity_id } = action.payload;
        const { date_range, res_x, res_period, sku_oee } = query;

        if (!sku_oee) {
            const res = yield call(api_getBlockOEEData, entity_id, block_id, query);

            const blocksOeeResource = flattenTree(res, (n) => n.children).reduce(
                (acc, curr) => ({ ...acc, [curr.block_id]: OEEFactory(curr) }),
                {}
            );

            const blocksLabels = generateBlockLabelsResource(res);
            const labels = values(blocksLabels).reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
            );
            const by_block = mapValues(blocksLabels, (arr) =>
                values(arr).map((l) => l.label_id)
            );

            yield all([
                put(addBlocksOeeDataResource(blocksOeeResource)),
                put(SetLabelsState(labels, by_block)),
            ]);
        } else {
            const queries = segmentQueryRange(
                date_range,
                { res_x, res_period },
                tzStartDifference
            ).map((r) => ({
                ...query,
                date_range: r,
            }));

            const step = 2;
            const raw = [];
            let index = 0;

            while (index < queries.length) {
                const next = index + step;

                const { batch } = yield race({
                    batch: all(
                        queries
                            .slice(index, next)
                            .map((r) => call(api_getBlockOEEData, entity_id, block_id, r))
                    ),
                    cancel: take(CONSTANTS.EVENTS.CANCEL_TASK),
                });

                if (batch) {
                    raw.push(...batch);

                    const rawCombined = flatten(
                        raw.map((item) => flattenTree(item, (n) => n.children))
                    ).reduce((acc, curr) => {
                        if (!acc[curr.block_id])
                            return { ...acc, [curr.block_id]: { ...curr } };

                        acc[curr.block_id].oee = acc[curr.block_id].oee.concat(...curr.oee);
                        return acc;
                    }, {});

                    const blocksLabels = raw
                        .map(generateBlockLabelsResource)
                        .reduce((acc, curr) => {
                            keys(curr).map(
                                (block_id) =>
                                    (acc[block_id] = acc[block_id]
                                        ? { ...acc[block_id], ...curr[block_id] }
                                        : { ...curr[block_id] })
                            );
                            return acc;
                        }, {});

                    const labels = values(blocksLabels).reduce((acc, curr) => {
                        return { ...acc, ...curr };
                    }, {});

                    const by_block = mapValues(blocksLabels, (arr) => {
                        return values(arr).map((l) => l.label_id);
                    });

                    const combinedBlocksOeeResource = mapValues(rawCombined, OEEFactory);

                    yield all([
                        put(addBlocksOeeDataResource(combinedBlocksOeeResource)),
                        put(SetLabelsState(labels, by_block)),
                    ]);

                    index = next;
                } else {
                    break;
                }
            }
        }

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getBlockOeeDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_BLOCK_OEE_DATA_REQUEST,
        handleGetBlockOeeData
    );
}

function* handleGetSummaryTileData(action) {
    try {
        const { ids, query, entity_id } = action.payload;

        yield put(toggleSummaryTileLoading());

        const res = yield call(
            api_getSummaryTileData,
            entity_id,
            Object.assign({}, { ids }, query)
        );

        const bn_blocks = uniq(
            flatten(res.map((v) => v.bn_blocks || []))
        );

        yield put(setBlockBottleneck(bn_blocks));

        const stOEEResource = getDataResource(
            res,
            (d) => d.summary_tile_id,
            (d) => d.overall
        );

        yield all([
            put(addSummaryTilesOeeDataResource(stOEEResource)),
            put(toggleSummaryTileLoading()),
        ]);

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getSummarytileDataSaga() {
    yield takeEvery(K.ACTIONS.FETCH_SUMMARY_TILE_DATA_REQUEST, handleGetSummaryTileData);
}

function* handleGetTileDataAndLabels(action) {
    try {
        const { ids, query, entity_id } = action.payload;

        const res = yield call(
            api_getTileDataAndLabels,
            entity_id,
            Object.assign({}, { ids }, query)
        );

        const labelsResource = getMapFromArr(
            res.labels
                .filter((l) => l.label_values && l.label_values.length)
                .map((l) => new Label(...parseLabelArguments({ ...l }))),
            'label_id'
        );

        const metaDataResource = res.metadata.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.metadata_id]: TimeSeriesFactory(curr.data),
            }),
            {}
        );

        const fusionsDataResource = res.fusions.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.fusion_id]: TimeSeriesFactory(curr.data),
            }),
            {}
        );

        yield all([
            put(addTimesSeriesDataResource(metaDataResource, fusionsDataResource)),
            put(AddLabelsResource(labelsResource)),
        ]);

        action.callback && action.callback();
        if (action.callback) {
            action.callback(res);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* getTileDataAndLabelsSaga() {
    yield takeEvery(
        K.ACTIONS.FETCH_TILE_DATA_AND_LABELS_REQUEST,
        handleGetTileDataAndLabels
    );
}
