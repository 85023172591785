import { LabelConstants as K } from './Labels.constants';
import { Label } from '../../../legacy/models';

export const FetchAssetLabels = (asset_id, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_LABELS_REQUEST,
        payload: { asset_id, query },
        callback,
    };
};

export const SetLabelsState = (labels, by_block) => {
    return {
        type: K.ACTIONS.SET_LABELS_STATE,
        payload: { labels, by_block },
    };
};

export const SetLabelsResource = (labels) => {
    return {
        type: K.ACTIONS.SET_LABELS_RESOURCE,
        payload: { labels },
    };
};

export const AddLabelsResource = (labels) => {
    return {
        type: K.ACTIONS.ADD_LABELS_RESOURCE,
        payload: { labels },
    };
};

export const ReplaceLabelResource = (original_label_id, updated) => {
    return {
        type: K.ACTIONS.REPLACE_LABEL_RESOURCE,
        payload: { original_label_id, updated },
    };
};

export const CreateLabel = (asset_id, data, callback) => {
    return {
        type: K.ACTIONS.CREATE_LABEL_REQUEST,
        payload: { asset_id, data },
        callback,
    };
};

export const StopLabel = (asset_id, data, callback) => {
    return {
        type: K.ACTIONS.STOP_LABEL_REQUEST,
        payload: { asset_id, data },
        callback,
    };
};

export const UpdateLabel = (asset_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_LABEL_REQUEST,
        payload: {
            asset_id,
            data,
        },
        callback,
    };
};

export const DeleteLabel = (asset_id, data, callback) => {
    return {
        type: K.ACTIONS.DELETE_LABEL_REQUEST,
        payload: {
            asset_id,
            data,
        },
        callback,
    };
};

export const RemoveLabelResource = (label_id) => {
    return {
        type: K.ACTIONS.REMOVE_LABEL_RESOURCE,
        payload: label_id,
    };
};

export const ResetLabelsState = () => {
    return {
        type: K.ACTIONS.RESET_LABELS_STATE,
    };
};
