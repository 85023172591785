/* eslint-disable react/prop-types */

import React from 'react';
import moment from 'moment';
import { round } from 'lodash';
import { useSelector } from 'react-redux';

// REDUX
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';

// COMPONENTS
import {
    Labeller,
    LabellerBody,
    LabellerBodyItem,
    LabellerHeader,
    LabellerHeaderItem,
    LabelRangeZoom,
} from './components';


export const TargetProductionLabeller = (props) => {
    const { resolution } = useSelector(controlsState);

    const { selection, asset } = props;
    if (!selection) return null;

    const { sku, from, to, notes, value } = selection;
    const { res_x, res_period } = resolution;

    const range = [moment(from), moment(to)];
    const uom = sku ? sku.uom : 'pcs';
    const resolutionSeconds = moment.duration(res_x, res_period).as('seconds');
    const targetDurationSeconds = selection.duration();
    const targetPerResolution = round(
        (value / targetDurationSeconds) * resolutionSeconds,
        2
    );

    return (
        <Labeller>
            <LabellerHeader className="flex-column">
                <LabellerHeaderItem label="Production">
                    <strong>{sku ? sku.codeName : 'Planned Production'}</strong>
                </LabellerHeaderItem>
                <LabellerHeaderItem label="Duration">
                    {selection.duration('hours')} hours
                </LabellerHeaderItem>
                <LabellerHeaderItem label="Range">
                    <LabelRangeZoom asset={asset} range={range} />
                </LabellerHeaderItem>
            </LabellerHeader>
            <LabellerBody>
                <LabellerBodyItem header="Target">
                    <code className="mb-3">{`${value} ${uom}`}</code>
                </LabellerBodyItem>

                <LabellerBodyItem
                    header={`Target Per Resolution (${res_x} ${res_period})`}
                >
                    <code className="mb-3">{`${targetPerResolution} ${uom}`}</code>
                </LabellerBodyItem>
                <LabellerBodyItem header="Notes">
                    <code className="mb-3">{notes || '-'}</code>
                </LabellerBodyItem>
            </LabellerBody>
        </Labeller>
    );
};
