/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Modal, Select } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { findKey, isEmpty, mapValues, uniq } from 'lodash';

// REDUX
import {
    arrayAssets,
    assetsSelector,
} from '../../../store/old/Assets/Assets.selector';
import { DeleteFusion } from '../../../store/old/Fusions/Fusions.action';

// TYPES / INTERFACES
import { getDefaultSpeedAttribute } from '../../models';

// UTILS
import {
    defaultFilterOption,
    getMapFromArr,
    regexEscape,
    regexMatch,
} from '../../utils/helpers';
import { isValidChartTitle } from '../../utils/device';
import { mathOperatorsRegex } from '../../utils/validate';

// COMPONENTS
import AukButton from '../../components/AukButton';
import FusionExpressionEditor from './FusionExpressionEditor';
import { DangerZoneItem } from '../../components/DangerZone';

import './FusionForm.scss';

import {
    ExclamationCircleOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { Permission } from '../../components/Permission';
import BatchRecognitionInputs, {
    BatchRecognitionFormLabel,
    validateBatchRecognition,
} from '../Devices/components/BatchRecognitionInputs';
import ControlLimitsInputs, {
    ControlLimitsFormLabel,
    validateControlLimits,
} from '../Devices/components/ControlLimitsInputs';

const { confirm } = Modal;

const DELETED_MENTION = '@{DELETED>DELETED}';

const FUSION_MODES = [
    { value: '1a', label: 'Digital Count' },
    { value: '2a', label: 'Digital State' },
    { value: '3a', label: 'Analog Voltage' },
];

const FUSION_CHART_TYPES = [
    { value: 'column', label: 'Column' },
    { value: 'line', label: 'Line' },
];

const FUSION_VALIDATE = {
    CHART_TITLE: [
        {
            required: true,
            message: 'Fusion name is required.',
        },
        {
            whitespace: true,
            message: 'Fusion name is required.',
        },
        (form) => ({
            validator: (_, value) => {
                if (!isValidChartTitle(value))
                    return Promise.reject(
                        new Error('Fusion name cannot contain #%&*?|<>{}\\.')
                    );
                return Promise.resolve();
            },
        }),
    ],
    CHART_TYPE: [
        {
            required: true,
            message: 'Chart type is required.',
        },
    ],
    MODE: [
        {
            required: true,
            message: 'Mode is required.',
        },
    ],
    BAT_REC: [
        (form) => ({
            validator: (_, value) =>
                validateBatchRecognition(form.getFieldValue('mode'), value),
        }),
    ],
    CONTROL_LIMITS: [
        (form) => ({
            validator: (_, value) => validateControlLimits(value),
        }),
    ],
    EXP: [
        {
            required: true,
            message: 'Fusion expression is required.',
        },
        {
            whitespace: true,
            message: 'Fusion expression is required.',
        },
        (form) => ({
            validator: (_, value) => {
                const hasDeletedInputs = regexMatch(value, DELETED_MENTION);

                if (hasDeletedInputs)
                    return Promise.reject(
                        new Error('Expression cannot contain deleted inputs.')
                    );

                const { exp_mapping, exp_transform } = getExpressionObject(value);

                if (exp_transform.length > 300)
                    return Promise.reject(
                        new Error('Expression too long (must be less than 300 characters).')
                    );

                return Promise.resolve();
            },
        }),
    ],
};

const getMetadataIdFromMention = (m) =>
    m.replace(/[@{}]/g, '').split('>')[0];

const getExpressionObject = (s) => {
    const str = s.replace(/\n/g, ' ');
    const deconstructed = str.split('');

    let openIndex = null;
    let mention = '';
    let mentions = [];

    for (let i = 0; i < deconstructed.length; i++) {
        let current = deconstructed[i];
        if (current === '@') {
            openIndex = i;
            mention += current;
        } else if (openIndex !== null && current !== '}') {
            mention += current;
        } else if (current === '}') {
            mention += current;
            openIndex = null;
            mentions.push(mention);
            mention = '';
        }
    }

    mentions = uniq(mentions);

    const exp_mapping = mentions
        .map(getMetadataIdFromMention)
        .reduce(
            (acc, curr, i) => ({ ...acc, [String.fromCharCode(97 + i)]: +curr }),
            {}
        );

    const exp_transform = mentions
        .reduce((acc, curr) => {
            const metadata_id = +getMetadataIdFromMention(curr);
            const key = findKey(exp_mapping, (k) => k === metadata_id) || '';
            const replacementRegex = new RegExp(regexEscape(curr), 'g');
            return acc.replace(replacementRegex, key);
        }, str)
        .replace(/\s*/g, '');

    return { exp_mapping, exp_transform };
};

const FusionForm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { fusion = {}, submit, asset, onDelete } = props;

    const assetsResource = useSelector(assetsSelector);
    const assetOptions = useSelector(arrayAssets).map((a) => ({
        value: a.asset_id,
        label: a.asset_name,
    }));

    const [form] = Form.useForm();
    const [mode, setMode] = useState(fusion.mode || '1a');
    const [selectedAssets, setSelectedAssets] = useState(
        fusion.assets
            ? uniq(fusion.assets.map((a) => a.asset_id))
            : [asset.asset_id]
    );

    const mentions = useMemo(() => {
        return selectedAssets
            .map((asset_id) => assetsResource[asset_id])
            .reduce((acc, curr) => acc.concat(...curr.metadata), [])
            .map((m) => ({
                id: m.metadata_id,
                display: `${m.ofAsset.asset_name}:${m.chart_title}`,
            }));
    }, [selectedAssets]);

    const getFormData = (data) => {
        const {
            expression,
            chart_title,
            chart_type,
            mode,
            type,
            units,
            batRec,
            fusion_id,
            speed,
            control_limits,
        } = data;

        const { exp_mapping, exp_transform } = getExpressionObject(expression);
        const analog = FUSION_MODES[2].value;

        const formData = {
            chart_title,
            chart_type,
            mode,
            type,
            units,
            exp_mapping,
            exp_transform,
            autoUpdate: mode !== analog,
            fusion_id,
            speed,
        };

        mode === analog && (formData.batRec = batRec);
        mode === analog && (formData.control_limits = control_limits);

        return formData;
    };

    const expressionString = useMemo(() => {
        if (isEmpty(fusion)) return '';

        const { exp_mapping, exp_transform } = fusion;
        const mentionsMap = getMapFromArr(mentions, 'id');
        const variables = mapValues(exp_mapping, (v) => {
            const mention = mentionsMap[+v];
            return mention ? `@{${v}>${mention.display}}` : DELETED_MENTION;
        });

        const specialChars = exp_transform.split('').reduce((acc, curr, i) => {
            if (regexMatch(curr, mathOperatorsRegex)) {
                return acc.concat(curr);
            }
            return acc;
        }, []);

        const terms = exp_transform
            .replace(mathOperatorsRegex, '`')
            .split('`')
            .map((t) => t.replace(/\s*/g, ''));

        let str = '';
        for (let i = 0; i < terms.length; i++) {
            const term = terms[i];
            const nextTerm = exp_mapping[term] ? variables[term] : term;
            const nextOperator = specialChars[i] || '';
            str = str + nextTerm + nextOperator;
        }

        return str;
    }, [fusion]);

    const hasDeletedInputs = useMemo(
        () => !!regexMatch(expressionString, DELETED_MENTION),
        [expressionString]
    );

    const [acknowledge, setAcknowledge] = useState(false);
    const showAcknowledge = hasDeletedInputs && !acknowledge;

    return (
        <>
            {showAcknowledge && (
                <div
                    className="w-100 h-100 d-flex justify-content-center align-items-center"
                    style={{
                        position: 'absolute',
                        zIndex: 2,
                    }}
                >
                    <code
                        style={{
                            border: '1px solid #bbb',
                            background: '#ddd',
                            borderRadius: 2,
                            cursor: 'pointer',
                            width: '80%',
                        }}
                        className="p-3"
                        onClick={() => setAcknowledge(true)}
                    >
            Input(s) used in the computation of this fusion chart have been
            removed. Click to modify fusion expression, or delete the chart.
                    </code>
                </div>
            )}
            <Form
                style={{ opacity: showAcknowledge ? 0.2 : 1 }}
                preserve
                ref={ref}
                name="fusionForm"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                className="fusion-form"
                onFinish={() => submit(getFormData(form.getFieldsValue(true)))}
                onValuesChange={(changedValues, allValues) => {
                    if (changedValues.mode) {
                        setMode(changedValues.mode);
                        form.setFields([
                            {
                                name: 'speed',
                                value: getDefaultSpeedAttribute(changedValues.mode),
                            },
                        ]);
                    }
                }}
            >
                <div className="fusion-form__fields">
                    <Form.Item name="fusion_id" initialValue={fusion.fusion_id} hidden>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name="chart_title"
                        initialValue={fusion.chart_title || ''}
                        label="Fusion Name"
                        rules={FUSION_VALIDATE.CHART_TITLE}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                    <Form.Item
                        name="chart_type"
                        initialValue={fusion.chart_type || 'column'}
                        label="Chart Type"
                        rules={FUSION_VALIDATE.CHART_TYPE}
                    >
                        <Select options={FUSION_CHART_TYPES} />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        initialValue={fusion.type || 'expression'}
                        label="Fusion Type"
                        hidden
                    >
                        <Select options={[{ label: 'Expression', value: 'expression' }]} />
                    </Form.Item>
                    <Form.Item
                        name="mode"
                        initialValue={fusion.mode || '1a'}
                        label="Mode"
                        rules={FUSION_VALIDATE.MODE}
                    >
                        <Select options={FUSION_MODES} />
                    </Form.Item>
                    <Form.Item
                        hidden
                        noStyle
                        initialValue={fusion.speed || true}
                        name="speed"
                    >
                        <span>{fusion.speed}</span>
                    </Form.Item>
                    <Form.Item
                        name="units"
                        initialValue={fusion.units || ''}
                        label="Units"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        hidden={mode !== '3a'}
                        label={<BatchRecognitionFormLabel />}
                        name="batRec"
                        initialValue={fusion.batRec}
                        rules={FUSION_VALIDATE.BAT_REC}
                    >
                        <BatchRecognitionInputs />
                    </Form.Item>
                    <Form.Item
                        hidden={mode !== '3a'}
                        label={<ControlLimitsFormLabel />}
                        name="control_limits"
                        initialValue={fusion.control_limits}
                        rules={FUSION_VALIDATE.CONTROL_LIMITS}
                    >
                        <ControlLimitsInputs />
                    </Form.Item>
                    <Form.Item label=" " colon={false} className="mb-1">
                        <div className="d-flex text-uppercase">
              Expression Constructor{' '}
                            <Link
                                className="d-flex align-items-center ml-2"
                                to={'/userguide?id=72'}
                            >
                                <InfoCircleOutlined title="Click to view available operators" />
                            </Link>
                        </div>
                    </Form.Item>
                    <Form.Item label="Assets">
                        <Select
                            value={selectedAssets}
                            options={assetOptions}
                            onChange={setSelectedAssets}
                            mode="multiple"
                            filterOption={defaultFilterOption}
                            placeholder="Select asset sources for expression constructor"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Expression"
                        name="expression"
                        initialValue={expressionString}
                        rules={FUSION_VALIDATE.EXP}
                    >
                        <FusionExpressionEditor data={mentions} />
                    </Form.Item>
                    {!isEmpty(fusion) && (
                        <Permission forResource resource="fusions" canDo="full">
                            <Form.Item label=" " className="mb-3" colon={false}>
                                <DangerZoneItem
                                    title="Delete chart"
                                    description="May affect OEE computation."
                                    button={
                                        <AukButton.Delete
                                            size="small"
                                            onClick={() => {
                                                confirm({
                                                    title: 'Remove fusion chart',
                                                    icon: <ExclamationCircleOutlined />,
                                                    content: 'This action is not reversible, continue?',
                                                    onOk: () => {
                                                        dispatch(
                                                            DeleteFusion(fusion, () => onDelete && onDelete())
                                                        );
                                                    },
                                                });
                                            }}
                                        />
                                    }
                                />
                            </Form.Item>
                        </Permission>
                    )}
                </div>
                <div className="fusion-form__submit">
                    <Permission forResource resource="fusions" canDo="edit">
                        <AukButton.Save htmlType="submit" />
                    </Permission>
                </div>
            </Form>
        </>
    );
});

export default FusionForm;
