// Thanks to https://github.com/icd2k3/react-router-breadcrumbs-hoc

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumbs.scss';
import withHomeviewIcon from '../Wrappers/HOCs/withHomeviewIcon';
import { Breadcrumb, Popover, Tag } from 'antd';
import { capitalize, isFinite } from 'lodash';
import { ROUTES } from '../../routes/routes';
import { regexMatch } from '../utils/helpers';

const getReadableSegment = (segment) => segment.split(/[_-]/).map(s => capitalize(s)).join(' ');
const getUrlSegments = (pathname) =>  pathname.split('/');
const getPath = (segments, index) => {
    const result = []

    for (let i = 0; i <= index; i++) {
        result.push(segments[i]);
    }

    const nextSegment = segments[index + 1]
    if (nextSegment && isFinite(+nextSegment)) {
        result.push(nextSegment)
    }

    return '/' + result.join('/')
}

const betaPages = [ROUTES.PRODUCTION_ACHIEVEMENT, ROUTES.REGRESSION, ROUTES.TARGET_SETTING]
const isBeta = (pathname) => {
    for (const page of betaPages) {
        if (regexMatch(pathname, page)) { return true }
        return false;
    }
}

const premiumPages = [ROUTES.PERMISSIONS_MANAGEMENT, ROUTES.STANDARD_TIMES];
const isPremium = (pathname) => {
    for (const page of premiumPages) {
        if (regexMatch(pathname, page)) { return true }
        return false;
    }
}

export const LocationBreadcrumbs = withHomeviewIcon((props) => {
    const location = useLocation();
    const pathname = location.pathname.substring(1);
    const segments = getUrlSegments(pathname);
    const beta = isBeta(pathname);
    const premium = isPremium(pathname);

    return (
        <div className="item breadcrumbs">
            <Breadcrumb>
                {
                    segments.map((segment, i) => {
                        if (+segment) return null;
                        const href = getPath(segments, i);
                        return (
                            <Breadcrumb.Item key={i}>
                                <Link to={getPath(segments, i)} onClick={(e) => {
                                    if (href === location.pathname) {
                                        e.preventDefault()
                                    }
                                }}>{getReadableSegment(segment)}</Link>
                            </Breadcrumb.Item>
                        )})
                }
            </Breadcrumb>
            {beta ? <BetaTag /> : null}
            {premium ? <PremiumTag /> : null}
        </div>
    );
});

export const BetaTag = () => (
    <Popover
        placement="rightBottom"
        content={
            <>
                <p>
                    This feature is part of the early access program, and may be removed
                    or revised significantly in the future.
                </p>
                <p>Any feedback to improve is welcomed.</p>
            </>
        }
    >
        <Tag className="m-0 text-uppercase" color="#ddd">
            Beta
        </Tag>
    </Popover>
);

export const PremiumTag = () => (
    <Popover
        placement="rightBottom"
        content={
            <>
                <p>This is a premium feature.</p>
                <p>Please contact service@auk.industries for access.</p>
            </>
        }
    >
        <Tag className="m-0 text-uppercase" color="#ddd">
            Premium
        </Tag>
    </Popover>
);
