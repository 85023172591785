import { useAppSelector } from "../../../store/hooks"
import { getWindow } from '../../../store/old/UI/Controls/Controls.selector';
import { csvDatetimeFormat } from '../../utils/helpers';
import CONSTANTS from '../../Constants';

import { oeePareto, oeeCSV } from '../../utils/oee';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import _, { round } from 'lodash';
import moment from 'moment';

export const getAssetCSV = (asset, oeeColumns) => {
    const appState = useAppSelector();

    const { lower: startDate, upper: endDate } = getWindow(appState);
    const entity = currentEntitySelector(appState);
    const {
        ui: {
            controls: {
                resolution: { res_x, res_period },
            },
        },
    } = appState;
    const {
        oee: { oee, waterfall },
        issueLabels,
        productionLabels,
    } = asset;

    // if (!oee) return;

    // asset block
    const entityRow = `Organisation, ${entity.entity_name}`;
    const assetRow = `Machine, ${asset.asset_name.toUpperCase()}`;
    const urlRow = `Data link, ${window.location.href}`;
    const assetBlock = [entityRow, assetRow, urlRow].join('\n');

    // res-range block
    const resRangeHeader = 'Time Range';
    const startDateRow = `Period start date-time, ${moment(startDate).format(
        csvDatetimeFormat
    )}`;
    const endDateRow = `Period end date-time, ${moment(endDate).format(
        csvDatetimeFormat
    )}`;
    const resolutionRow = `Data resolution, ${res_x} ${res_period}`;
    const resRangeBlock = [
        resRangeHeader,
        startDateRow,
        endDateRow,
        resolutionRow,
    ].join('\n');

    // oee block
    const oeeBlock = oeeCSV(waterfall);

    // generate total duration for oee tags (unique)
    const oeeTagColHeaders = `OEE Tag Descriptor, Total Duration (hrs), Occurrences`;
    const oeeTagRows = !issueLabels.length
        ? 'No OEE tags in this period'
        : oeePareto(issueLabels)
            .sort((a, b) => a.occurrence > b.occurrence)
            .map(
                (d) =>
                    `${d.issue && d.issue.name ? d.issue.name : 'DELETED'}, ${round(
                        d.total_duration / 3600,
                        2
                    )}, ${d.occurrence}`
            )
            .join('\n');
    const oeeTagBlock = [oeeTagColHeaders, oeeTagRows].join('\n');

    // production labels summary
    let productionOutputBlock = 'Production Output';
    const cumulativeOutputs = getLabelValueCumulativeOutput(
        flattenLabelValues(productionLabels)
    );
    const outputString = asset.labelFields
        .map(({ field }) => {
            const type = cumulativeOutputs[field];
            return type
                ? Object.keys(type)
                    .map((key) => `"${field.toUpperCase()}","${key}", ${type[key]}`)
                    .join('\n')
                : '';
        })
        .filter((str) => str)
        .join('\n');
    productionOutputBlock += '\n' + outputString;

    // generate data block
    const oeeColOrder = Object.keys(CONSTANTS.CAT);
    const _oeeColHeaders = oeeColOrder.map(
        (key) => `${oeeColumns[key].altLabel} (%)`
    );
    const _chartColHeaders = asset.charts.map((c) => c.chart_title);
    const dataColHeaders = ['Date', ..._chartColHeaders, ..._oeeColHeaders];
    const chartDataArr = asset.charts.reduce((acc, curr) => {
        const { data } = curr;
        const dataMap = data.reduce((_acc, currentDatum) => {
            _acc[moment(currentDatum.time).format(csvDatetimeFormat)] =
        currentDatum.val;
            return _acc;
        }, {});
        return acc.concat(dataMap);
    }, []);

    const dataRows = oee
        .map((datum) => {
            const time = moment(datum.time).format(csvDatetimeFormat);
            let rowStr = `${time},`;
            asset.charts.forEach((m, i) => {
                rowStr += `${chartDataArr[i][time] ? chartDataArr[i][time] : 0},`;
            });
            oeeColOrder.forEach((key) => {
                rowStr += `${round(datum.oee[key], 2)},`;
            });
            return rowStr;
        })
        .join('\n');
    const dataBlock = [dataColHeaders, dataRows].join('\n');

    return [
        assetBlock,
        resRangeBlock,
        productionOutputBlock,
        oeeBlock,
        oeeTagBlock,
        dataBlock,
    ].join('\n\n');
};

export const getAssetLabelsCSV = (asset) => {
    let data = '';

    const issueTopic = 'Issues';
    const issueColHeaders = 'From,To,OEE Category,Issue,Defects';
    const issueLabels = asset.issueLabels
        .sort((a, b) => a.from - b.from)
        .reduce((acc, curr) => {
            const { name } = curr.issue;
            const mainIssue = curr.issue.oeeCategory;
            const from = moment(curr.from).format(csvDatetimeFormat);
            const to = curr.isComplete
                ? moment(curr._to).format(csvDatetimeFormat)
                : '-';
            return (acc + `${from},${to},${mainIssue},"${name}",${
                curr.defects || ''
            }\n`);
        }, '');

    data += [issueTopic, issueColHeaders, issueLabels].join('\n') + '\n';

    const productionColHeaders =
    'From,To,' +
    asset.labelFields.map((t) => t.field.toUpperCase()).join(',') +
    ',Output' +
    '\n';
    const productionLabels = asset.productionLabels
        .filter((l) => l.isComplete)
        .sort((a, b) => a.from - b.from)
        .map((l) => {
            const { attributes, output } = l;
            const from = moment(l.from).format(csvDatetimeFormat);
            const to = l.isComplete ? moment(l.to).format(csvDatetimeFormat) : '-';
            return (
                asset.labelFields
                    .map(({ field }) =>
                        attributes[field] ? `"${attributes[field]}"` : '-'
                    )
                    .reduce((acc, curr) => {
                        return (acc + `${curr},`);
                    }, `${from},${to},`) + `${output}`
            );
        })
        .join('\n');

    data += productionColHeaders + productionLabels;

    return data;
};

const flattenLabelValues = (labels) => {
    return _.flattenDeep(
        labels.map((l) => {
            return Object.keys(l.attributes).map((type) => ({
                label_id: l.label_id,
                type,
                value: l.attributes[type],
                output: l.output,
            }));
        })
    );
};

const getLabelValueCumulativeOutput = (arrValues) => {
    return arrValues.reduce((acc, curr) => {
        const { type, output, value } = curr;
        if (acc[type] === undefined) acc[type] = {};
        const cumulative = acc[type][value];
        acc[type][value] = cumulative ? cumulative + output : output;
        return acc;
    }, {});
};
