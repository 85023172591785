/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { values } from 'lodash';

// SELECTORS
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { minDevicePushRate } from '../../../store/old/Devices/Devices.selector';
import {
    controlsState,
    getWindow,
} from '../../../store/old/UI/Controls/Controls.selector';
import { shiftsSelector } from '../../../store/old/Schedules/Schedules.selector';

// HELPERS
import CONSTANTS from '../../Constants';
import { CUSTOM_SPAN } from '../../../store/old/UI/Controls/Controls.constants';
import {
    generateSpanObj,
    getBrowserTz,
    getRangeFromCustomSpan,
    getRangeFromTimeBlock,
    withinStreamingWindow,
} from '../../utils/controls';
import { generateUrlQuery } from '../../utils/url';
import { eventEmitter } from '../../auxStore';
import withRouter from '../../Hooks/withRouter';

class UseStreamingSimple extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.fetchOnMount) return;

        // const now = moment();
        // const isStreaming = now.isBetween(this.props.controls.startDate, this.props.controls.endDate);
        // this.props.setControls({ streaming: isStreaming });

        eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH);
        // if (isStreaming) this.initializeStreaming();
        if (this.props.controls.streaming) this.initializeStreaming();
    }

    componentDidUpdate(prevProps, prevState) {
        const { controls: prevControls } = prevProps;
        const { controls: currControls } = this.props;

        if (currControls.startDate.isAfter(currControls.endDate)) return;

        const streamOn = !prevControls.streaming && currControls.streaming;
        const streamOff = prevControls.streaming && !currControls.streaming;
        const isStreaming = currControls.streaming;
        // const rangeChanged = !prevControls.startDate.isSame(currControls.startDate) || 
        //     !prevControls.endDate.isSame(currControls.endDate);
        const paramsChanged = generateUrlQuery(prevControls) !== generateUrlQuery(currControls) ||
            prevControls.span !== currControls.span ||
            prevControls.sku_oee !== currControls.sku_oee;

        // if (rangeChanged) {
        //     const now = moment();
        //     this.props.setControls({
        //         streaming: now.isBetween(currControls.startDate, currControls.endDate),
        //     });
        // }

        if (paramsChanged) eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH);
        if (streamOn) return this.initializeStreaming();
        if (streamOff) return this.stopStreaming();
        if (isStreaming && paramsChanged) return this.initializeStreaming();
    }

    componentWillUnmount() {
        this.stopStreaming();
    }

    stopStreaming = () => {
        this.intervalId && clearInterval(this.intervalId);
    };

    initializeStreaming = () => {
        this.stopStreaming();
        this.intervalId = setInterval(() => {
            const { startDate, endDate, resolution } = this.props.controls;
            const now = moment();

            if (!this.props.tabVisibility) return;

            if (!withinStreamingWindow(startDate, endDate, now, resolution)) {
                return this.changeStreamingWindow(now);
            }

            eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH);
        }, this.streamIntervalMs);
    };

    changeStreamingWindow() {
        let {
            controls: { span, startDate, endDate, resolution },
            entity: { tzStartDifference },
        } = this.props;

        const now = moment();

        let newStart, newEnd;

        if (span === CUSTOM_SPAN) {
            [newStart, newEnd] = getRangeFromCustomSpan(startDate, endDate, now);
        } else {
            [newStart, newEnd] = getRangeFromTimeBlock(
                now,
                generateSpanObj(span),
                getBrowserTz(),
                tzStartDifference
            );
        }

        const query = generateUrlQuery({
            startDate: newStart, 
            endDate: newEnd,
            resolution,
            span
        });

        this.props.router.navigate(`${this.props.router.location.pathname}${query}`);
    }

    get streamIntervalMs() {
        const {
            resolution: { res_x, res_period },
        } = this.props.controls;

        const intervalMs = Math.max(
            moment.duration(res_x, res_period).as('milliseconds'),
            this.props.minPushRate * 1000
        );

        const maxInterval = moment.duration(1, 'weeks').as('milliseconds');
        return Math.min(intervalMs, maxInterval);
    }

    render() {
        const { controls, window } = this.props;
        return <>{this.props.render({ controls, window })}</>;
    }
}

const mapStateToProps = (appState) => {
    return {
        controls: controlsState(appState),
        tabVisibility: appState.ui.window.visibility,
        entity: currentEntitySelector(appState),
        minPushRate: minDevicePushRate(appState),
        window: getWindow(appState),
        shifts: shiftsSelector(appState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const UseStreaming = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UseStreamingSimple));

const withStreaming = (Component, options = {}) => {
    return (componentProps) => {
        return (
            <UseStreaming
                fetchOnMount={options.fetchOnMount === false ? false : true}
                render={(props) => <Component {...componentProps} {...props} />}
            />
        );
    };
};

export default withStreaming;
