import React from 'react';
import { useSelector } from 'react-redux';

export function connectInitializedApp(Component, AltComponent) {
    return function WrapperComponent(props) {
        const init = useSelector((rootState) => rootState.app.initialized);

        return init ? (
            <Component {...props} />
        ) : AltComponent ? (
            <AltComponent />
        ) : null;
    };
}
