/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'reactstrap';

import Select from '../../components/Select';
import { Permission } from '../../components/Permission';
import translate from '../../utils/translate';

export default class ModalGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: '',
            group_id: '',
            groupNameValid: false,
            formErrors: { groupName: '' },
            formValid: false,
            groupMembers: [],
        };

        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        const groupMemberProps = props.groupMembers;

        const newGroupMemberProps = groupMemberProps.map(
            (userItem) => userItem.user || userItem
        );

        this.setState(
            {
                groupMembers: newGroupMemberProps,
                groupName: props.grName,
                group_id: props.group_id,
                groupNameValid: false,
                formErrors: { groupName: '' },
                formValid: false,
            },
            () => this.validateField(this.state.groupName)
        );
    }

    handleChange = (e) => {
        const value = e.target.value;
        this.setState({ groupName: e.target.value }, () => {
            this.validateField(value);
        });
    };

    handleChangeSelect(e) {
        this.setState({ groupMembers: e });
    }

    validateField(value) {
        let fieldValidationErrors = this.state.formErrors;
        let groupNameValid = this.state.groupNameValid;

        groupNameValid = value.length >= 2;
        fieldValidationErrors.groupName = groupNameValid ? '' : ' is invalid';

        this.setState(
            {
                formErrors: fieldValidationErrors,
                groupNameValid: groupNameValid,
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({ formValid: this.state.groupNameValid });
    }

    get footerCreate() {
        const { toggle, handleCreate } = this.props;
        const { groupName, formValid, groupMembers } = this.state;
        return (
            <div className="panel-footer">
                <button onClick={toggle} className="btn b-cancel">
                    {translate('cancel')}
                </button>
                <button
                    className="btn b-save"
                    disabled={!formValid}
                    onClick={() => handleCreate(groupMembers, groupName)}
                >
                    {translate('done')}
                </button>
            </div>
        );
    }

    get footerEdit() {
        const { toggleEdit, handleDelete, handleUpdate } = this.props;
        const { groupName, groupMembers, group_id } = this.state;
        return (
            <div className="panel-footer">
                <button onClick={toggleEdit} className="btn b-cancel">
                    {translate('done')}
                </button>

                <Permission forResource resource="users" canDo="full">
                    <button
                        className="btn b-delete"
                        onClick={() => handleDelete(group_id)}
                    >
                        {translate('delete')}
                    </button>
                </Permission>

                <button
                    className="btn b-save"
                    onClick={() => handleUpdate(groupMembers, groupName, group_id)}
                >
                    {translate('save')}
                </button>
            </div>
        );
    }

    render() {
        const { userList, toggle, show, showEdit, toggleEdit } = this.props;
        const { groupName, groupNameValid } = this.state;

        const groupNameInput = (
            <input
                type="text"
                className={`form-control ${
                    groupName === '' ? '' : groupNameValid ? 'is-valid' : 'is-invalid'
                }`}
                placeholder="E.g. Procurement"
                value={groupName}
                onChange={(event) => this.handleChange(event)}
            />
        );

        return (
            <Modal
                isOpen={!showEdit ? show : showEdit}
                toggle={!showEdit ? toggle : toggleEdit}
                backdrop="static"
            >
                <div className="panel modal--user-groups">
                    <div className="panel-header">
                        <p className="panel-title">
                            {!showEdit ? translate(['create', 'group']) : translate(['manage', 'group'])}
                        </p>
                    </div>
                    <div className="panel-body">
                        <table className="table t-current">
                            <tbody>
                                <tr>
                                    <td className="right td">
                                        <label className="check">
                                            {translate(['group', 'name'])}
                                        </label>
                                    </td>
                                    <td className="td">{groupNameInput}</td>
                                </tr>
                                <tr>
                                    <td className="right td">
                                        <label className="check">
                                            {translate('user')}
                                        </label>
                                    </td>
                                    <td className="td">
                                        <Select
                                            value={this.state.groupMembers}
                                            options={userList}
                                            getOptionLabel={(opt) =>
                                                `${opt.name_first} ${opt.name_last}`
                                            }
                                            getOptionValue={(opt) => opt.user_id}
                                            onChange={this.handleChangeSelect}
                                            placeholder={<span>{translate('choose')}{" "}{translate('user')}</span>}
                                            isMulti
                                            hideSelectedOptions
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {!showEdit ? this.footerCreate : this.footerEdit}
                </div>
            </Modal>
        );
    }
}

ModalGroups.propTypes = {
    userList: PropTypes.array,
};
