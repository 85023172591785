/* eslint-disable */
import * as d3 from "d3"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useSelector } from "react-redux"
import { ColumnChart, LabelsChart } from "../../../Charts/v1"
import { ColumnChartTooltip } from "../../../Charts/v1/tooltips/templates"
import { api_getAssetLabels } from "../../../../store/old/Labels/Labels.services"
import { generateContinuousDomain } from "../../../utils/charts"
import { Label, parseLabelArguments } from "../../../models"

const fetchAssetLabels = (assetId, startDate, endDate) =>
    api_getAssetLabels(assetId, {
        types: ["sku"],
        date_range: {
            upper: endDate.toISOString(),
            lower: startDate.toISOString(),
        },
    });

const BlockOutputChart = forwardRef((props, ref) => {
    const { startDate, endDate } = useSelector((state) => state.ui.controls);
    const [labels, setLabels] = useState([]);

    const getAssetLabels = () => {
        fetchAssetLabels(props.assetId, startDate, endDate).then((data) => {
            setLabels(data.map((label) => new Label(...parseLabelArguments(label))));
        });
    };

    useImperativeHandle(ref, () => ({ getAssetLabels }), []);

    useEffect(() => {
        getAssetLabels();
    }, [props.assetId, startDate.toString(), endDate.toString()]);

    return <div style={{ flexGrow: 1 }}>
        <div style={{ height: 18, flexShrink: 0 }}>
            <LabelsChart
                className="w-100 production-labels-chart"
                {...props.labelsProps}
                xScale={d3.scaleTime()}
                xDomain={[startDate, endDate]}
                data={labels}
                onDoubleClick={props.onLabelPress}
            />
        </div>
        <div className="w-100 oee-chart">
            <ColumnChart
                xScale={d3.scaleTime()}
                yScale={d3.scaleLinear()}
                data={props.output}
                margin={{ top: 10, left: 35, right: 15, bottom: 20 }}
                xDomain={[startDate, endDate]}
                yDomain={generateContinuousDomain(props.output, (d) => d.val)}
                xAccessor={(d) => d.time}
                yAccessor={(d) => d.val}
                htmlTooltip={ColumnChartTooltip}
                onBrushEnd={props.onBrushEnd}
                useBrush
            />
        </div>
    </div>
});

BlockOutputChart.displayName = "BlockOutputChart"

export default BlockOutputChart;
