/* eslint-disable react/prop-types */

import { isEmpty, pickBy, round, values } from 'lodash';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import { SaveFilled } from '@ant-design/icons';
import moment from 'moment';

// HELPERS
import CONSTANTS from '../../Constants';
import { flash } from '../../components/Flash';
import {
    regexMatch,
    uiDatetimeFormatWithSeconds,
} from '../../utils/helpers';
import {
    checkFutureLabel,
    compileLabel,
    findOverlappingLabels,
    generateStopLabel,
    getSpan,
    labelDisplayText,
} from '../../utils/labels';

// REDUX
import { skusSelector } from '../../../store/old/Sku/Sku.selector';

// COMLPONENTS
import AukButton from '../../components/AukButton';
import LabelWrapper from '../../components/LabelWrapper';
import WithBarcode from '../../components/WithBarcode';
import { DangerZone, DangerZoneItem } from '../../components/DangerZone';
import {
    Labeller,
    LabellerBody,
    LabellerBodyItem,
    LabellerHeader,
    LabellerHeaderItem,
    LabelRangeZoom,
    StopLabelIcon,
} from './components';

// INTERFACES
import { LabelNotesInput, LabelsContext } from '.';
import withLabelling from '../../Wrappers/HOCs/withLabelling';

export const ProductionLabeller = withLabelling((props) => {
    const { asset, openDataDelete, onSuccess, onRemoveSuccess } = useContext(LabelsContext);
    const { createLabel, updateLabel, deleteLabel, stopLabel, showLabelNotes } = props;
    const skus = useSelector(skusSelector);

    const {
        range,
        selection,
        onClickProductionLabelItem,
        chartSelection,
        brushedData,
    } = props;

    const { asset_id } = asset;
    const [formData, setFormData] = useState({});
    const [notes, setNotes] = useState(selection ? selection.notes : '');

    const [brushStart, brushEnd] = range;

    const key = useMemo(() => (selection ? selection.label_id : ''), [selection]);
    useEffect(() => {
        selection && setNotes(selection.notes);
    }, [selection]);

    const brushSummary = useMemo(() => {
        if (!chartSelection) return null;

        const { mode } = chartSelection;

        if (mode !== CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT) {
            const value =
        brushedData.reduce((acc, curr) => (acc += curr.int * curr.val), 0) / // totalValue
        brushedData.reduce((acc, curr) => (acc += curr.int), 0); // total time

            return { title: 'Average', value: round(value, 2) };
        }

        const value = brushedData.reduce((acc, curr) => (acc += curr.val), 0);
        return { title: 'Total', value: round(value, 2) };
    }, [chartSelection, range, brushedData]);

    const spanDuration = useMemo(() => getSpan(range), [range]);

    const handleSave = useCallback(() => {
        const values = {
            ...pickBy(formData, (v) => v && `${v}`.trim()),
        };

        if (isEmpty(values))
            return flash({
                message: 'Inputs required to commence tagging',
                status: 'warning',
            });

        if (values.sku && !skus[values.sku])
            return flash({
                message: 'Inputs required to commence tagging',
                status: 'warning',
            });

        if (showLabelNotes) values.notes = notes;

        const overlappedLabels = findOverlappingLabels(
            asset.productionLabels,
            brushStart,
            brushEnd,
            selection
        );

        if (overlappedLabels.length) {
            return flash({
                message: 'Cannot overwrite existing label(s)',
                status: 'warning',
            });
        }

        const requestPayload = compileLabel({
            label_id: selection ? selection.label_id : null,
            from: brushStart,
            to: brushEnd,
            values,
        });

        if (checkFutureLabel(requestPayload))
            return flash({
                message: 'Cannot create label in future time.',
                status: 'warning',
            });

        const saveHandler = selection ? updateLabel : createLabel;
        saveHandler(asset_id, requestPayload, onSuccess);
    }, [formData, range, notes, showLabelNotes]);

    const handleDelete = () => {
        if (!selection) return;
        if (!selection.isComplete) {
            return flash({
                message: 'Please stop label before deleting.',
                status: 'warning',
            });
        }
        deleteLabel(asset_id, selection, typeof onRemoveSuccess !== "undefined" ? onRemoveSuccess : onSuccess);
    };

    return (
        <Labeller className="production-labeller" id="productionLabeller" key={key}>
            <LabellerHeader className="flex-column">
                <LabellerHeaderItem label="Span:">{spanDuration}</LabellerHeaderItem>
                <LabellerHeaderItem label="Range">
                    <LabelRangeZoom
                        asset={asset}
                        range={range}
                        disableZoom={selection ? !selection.isComplete : false}
                    />
                </LabellerHeaderItem>
            </LabellerHeader>
            <LabellerBody>
                {brushSummary ? (
                    <LabellerBodyItem
                        header={`${
                            chartSelection
                                ? `${chartSelection.chart_title} Data Selection`
                                : 'Data Selection'
                        }`}
                        addonHeader={[
                            <DangerZoneItem
                                key={key}
                                title="Delete device data"
                                description="Delete highlighted data."
                                handleClick={openDataDelete}
                                action="Delete"
                                confirmText="Are you sure ? This action is irreversible."
                            />,
                        ]}
                    >
                        {brushSummary.title}: {brushSummary.value}
                    </LabellerBodyItem>
                ) : null}
                {selection ? (
                    <LabellerBodyItem header={'Label Selection'}>
                        <ProductionLabelListItem
                            data={selection}
                            onStop={(d) =>
                                stopLabel(asset.asset_id, generateStopLabel(d), (l) =>
                                    onSuccess(l)
                                )
                            }
                            onClick={onClickProductionLabelItem}
                        />
                    </LabellerBodyItem>
                ) : null}
                {selection ? (
                    <LabellerBodyItem header={'SKU Standard Time'}>
                        {selection.skuStdTime ? `${selection.skuStdTime} s` : '-'}
                    </LabellerBodyItem>
                ) : null}
                <LabellerBodyItem header={selection ? 'Edit' : 'Create'}>
                    <ProductionFields data={selection} onChange={setFormData} />
                </LabellerBodyItem>
                {showLabelNotes ? (
                    <LabellerBodyItem header="Notes">
                        <LabelNotesInput
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </LabellerBodyItem>
                ) : null}
                <AukButton.Blue
                    className="my-3"
                    icon={<SaveFilled />}
                    onClick={handleSave}
                >
          Save
                </AukButton.Blue>
                {selection && (
                    <LabellerBodyItem header="Last Modified">
                        <div className="labeller__last-modified">
                            {selection.lastModified}
                        </div>
                    </LabellerBodyItem>
                )}
                {selection ? (
                    <DangerZone header="Delete">
                        <DangerZoneItem
                            title="Label"
                            description="Delete selected label."
                            handleClick={handleDelete}
                            action="Delete"
                            confirmText="Are you sure? This may affect your OEE."
                        />
                    </DangerZone>
                ) : null}
            </LabellerBody>
        </Labeller>
    );
});

const initProductionFieldsState = (asset, currentLabel) => {
    return asset.labelFields.reduce((acc, curr) => {
        let value = '';

        if (currentLabel) {
            const fieldValue = currentLabel.getValue(curr.field);
            if (curr.field === 'sku') {
                value = fieldValue ? fieldValue.sku_id : '';
            } else {
                value = fieldValue || '';
            }
        }

        return {
            ...acc,
            [curr.field]: value,
        };
    }, {});
};

export const ProductionFields = (props) => {
    const { asset } = useContext(LabelsContext);
    const { data, onChange } = props;
    const { labelFields: fields } = asset;

    const skus = useSelector(skusSelector);

    const [state, setState] = useState(initProductionFieldsState(asset, data));
    const [reader, setReader] = useState(null);

    useEffect(() => onChange && onChange(state), [state]);

    const handleChange = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const handleScan = (e) => {
        if (!reader) return;

        let value = e;
        if (reader === 'sku') {
            const found = values(skus).find((s) => s.code === e);
            value = found ? found.sku_id : null;
        }

        handleChange(reader, value);
        setReader(null);
    };

    const skuOptions = useMemo(
        () =>
            values(skus).map((s) => ({
                value: s.sku_id,
                label: `[${s.code}] ${s.name}`,
            })),
        [skus]
    );

    return (
        <>
            {fields &&
        fields.map(({ field }, i) => {
            const component =
            field === 'sku' ? (
                <ProductionFieldItemSelect
                    field={field}
                    options={skuOptions}
                    value={skus[state[field]] ? state[field] : ''} // check if sku exists
                    handleChange={handleChange}
                />
            ) : (
                <ProductionFieldItemInput
                    field={field}
                    value={state[field]}
                    handleChange={handleChange}
                />
            );
            return (
                <WithBarcode
                    key={i}
                    handleClick={() => setReader(field)}
                    handleScan={handleScan}
                >
                    {component}
                </WithBarcode>
            );
        })}
        </>
    );
};

const ProductionFieldItemSelect = (props) => {
    const { field, options, handleChange, value } = props;
    return (
        <LabelWrapper label={field} className="production-label-field-input">
            <Select
                className="w-100"
                dropdownClassName="labeller-sku-select-dropdown"
                onChange={(e) => handleChange(field, e)}
                value={value}
                showSearch
                filterOption={(inp, opt) => regexMatch(opt.title, inp)}
                showArrow={false}
            >
                {options.map((o) => (
                    <Select.Option key={o.value} value={o.value} title={o.label}>
                        {o.label}
                    </Select.Option>
                ))}
            </Select>
        </LabelWrapper>
    );
};

const ProductionFieldItemInput = (props) => {
    const { field, handleChange, value } = props;
    return (
        <LabelWrapper label={field} className="production-label-field-input">
            <Input
                onChange={(e) => handleChange(field, e.target.value)}
                value={value}
            />
        </LabelWrapper>
    );
};

export const ProductionLabelListItem = (props) => {
    const { data, onStop, onClick = (l) => {} } = props;

    return (
        <div
            className="label-vlist-item d-flex align-items-center px-2 justify-content-between"
            onClick={() => onClick(data)}
        >
            <div className="label-vlist-item__descriptor d-flex flex-column h-100">
                <div className="d-flex">{labelDisplayText(data)}</div>
                <div style={{ fontSize: 10 }}>
                    {moment(data.from).format(uiDatetimeFormatWithSeconds)} -{' '}
                    {data.isComplete
                        ? moment(data.to).format(uiDatetimeFormatWithSeconds)
                        : 'On-going'}
                </div>
                <div style={{ fontSize: 10 }}>
          Output: {data.isComplete ? data.output : 'On-going'}
                </div>
            </div>
            {!data.isComplete && (
                <StopLabelIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        onStop(data);
                    }}
                />
            )}
        </div>
    );
};
