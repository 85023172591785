import { Layout } from "antd"
import React from "react"
import "./NavTemplate.scss"
import Header from "../../../legacy/components/Layout/Header"
import Sidebar from "../../../legacy/components/Sidebar"
import ControlPanel from "../../../legacy/components/ControlPanel/ControlPanel"
import withUrlQuery from "../../../legacy/Wrappers/HOCs/withUrlQuery"

const NavTemplate = (props: Props) => {
    return <Layout className="layout-auth">
        <Header/>
        <Layout className="layout-auth">
            <Layout.Sider width={50}>
                <Sidebar/>
            </Layout.Sider>

            <Layout.Content className="layout-auth__content">
                <ControlPanel/>
                {props.children}
            </Layout.Content>
        </Layout>
    </Layout>
}

interface Props {
    children: React.ReactNode
}

export default withUrlQuery(NavTemplate)
