import { cloneDeep, round } from 'lodash';
import moment from 'moment';

import { store } from '../../store';
import { LabelConstants as K } from '../../store/old/Labels/Labels.constants';
import { SimpleIssue } from './Issue';
import { SimpleSku } from './Sku';
import { uiDatetimeFormat } from '../utils/helpers';

const getAssetBlockId = (asset_id) => {
    const appState = store.getState();
    return appState.assets.assets[asset_id].block.block_id;
};

export const parseLabelArguments = (l) => {
    return [
        l.label_id,
        l.asset_id,
        l.from ? new Date(l.from) : l.from,
        l.to ? new Date(l.to) : l.to,
        l.value,
        l.output,
        l.aggr,
        l.defects,
        l.label_values,
        l.updated_at,
        l.user,
        l.override,
        l.block_id || getAssetBlockId(l.asset_id),
        l.effective_duration,
    ];
};

export class Label {
    label_id;
    asset_id;
    from;
    to;
    value;
    output;
    aggr;
    defects;
    label_values;
    updated_at;
    user;
    override;
    block_id;

    // pareto
    effective_duration;

    issue;
    sku;
    notes;

    constructor(
        label_id,
        asset_id,
        from,
        to = null,
        value,
        output,
        aggr = null,
        defects = null,
        label_values,
        updated_at,
        user,
        override = false,
        block_id,
        effective_duration
    ) {
        this.label_id = label_id;
        this.asset_id = asset_id;
        this.from = from;
        this.to = to;
        this.value = value;
        this.output = output;
        this.aggr = aggr;
        this.defects = defects;
        this.label_values = label_values || [];
        this.updated_at = updated_at;
        this.user = user;
        this.override = override;
        this.block_id = block_id;
        this.effective_duration = effective_duration;

        this.notes = this.getValue('notes') || '';
        this.issue = this.getValue('issue');
        this.sku = this.getValue('sku');
    }

    get _to() {
    // set to most recent time for forward tags
        return this.to || new Date();
    }

    get isComplete() {
        return !!this.to;
    }

    get color() {
        if (!this.isComplete) return '#5d8a6b';
        if (this.issue) return this.issue.color || '#222222';
        return '#616161';
    }

    get asset() {
        return store.getState().assets.assets[this.asset_id];
    }

    get block() {
        return store.getState().blocks.blocks[this.block_id];
    }

    get attributes() {
        return this.label_values.reduce((acc, curr) => {
            const { type } = curr;
            if (type === 'notes') return acc;

            switch (type) {
            case K.TYPES.SKU: {
                const { code, name } = this._getSkuValue(curr);
                acc[type] = `(${code || ''}) ${name}`;
                break;
            }
            case K.TYPES.ISSUE: {
                const { name } = this._getIssueValue(curr);
                acc[type] = name;
                break;
            }
            default:
                acc[type] = curr.value || '';
                break;
            }
            return acc;
        }, {});
    }

    get duration() {
        return this.getDuration('seconds');
    }

    getDuration(unit = 'minutes') {
        if (!this.isComplete) return '';

        const from = moment(this.from);
        const to = moment(this.to);
        return round(moment.duration(to.diff(from)).as(unit), 2);
    }

    clone() {
        return cloneDeep(this);
    }

    getValue(type) {
        const result = this.label_values.find((value) => value.type === type);
        if (!result) return;

        switch (type) {
        case K.TYPES.SKU: {
            return this._getSkuValue(result);
        }
        case K.TYPES.ISSUE: {
            return this._getIssueValue(result);
        }
        default:
            return result.value;
        }
    }

    _getSkuValue(item) {
        return SimpleSku(item.sku);
    }

    _getIssueValue(item) {
        return SimpleIssue(item.issue);
    }

    get lastModified() {
        return `${this.user.name} on ${moment(this.updated_at).format(
            uiDatetimeFormat
        )}`;
    }

    get skuStdTime() {
        const sku_value = this.label_values.find(({ type }) => type === 'sku');

        if (!sku_value) return '';
        return sku_value.sku && sku_value.sku.std_time
            ? sku_value.sku.std_time.toFixed(2)
            : '';
    }
}
