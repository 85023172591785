/* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react';
import { Form, Modal, Select } from 'antd';
import { UserNameWithIcon, UsersCondensed } from '../../components/Avatar';
import AukButton from '../../components/AukButton';
import './WithBlockUsers.scss';
import { useDispatch, useSelector } from 'react-redux';
import { arrayUsers } from '../../../store/old/User/User.selector';
import { defaultFilterOption, getMapFromArr } from '../../utils/helpers';
import { keys } from 'lodash';
import { UsergroupAddOutlined } from '@ant-design/icons';
import AukTooltip from '../../components/AukTooltip';
import { updateBlockUsers } from '../../../store/old/Blocks/Blocks.action';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

const WithBlockUsers = (props) => {
    const { block } = props;

    return (
        <div className="with-block-users">
            {props.children}
            <div className="block-users-wrapper" onClick={(e) => e.stopPropagation()}>
                <BlockUsers block={block} />
                <BlockUsersMobile users={block.users} />
            </div>
        </div>
    );
};

const BlockUsers = (props) => {
    const { block } = props;
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <div className="add-block-users" style={{ flexShrink: 0, width: 50 }}>
                <AukTooltip.Help title="Add person(s) in charge">
                    <AukButton.Volcano
                        style={{ height: 40 }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowModal(true);
                        }}
                    >
                        <UsergroupAddOutlined />
                    </AukButton.Volcano>
                </AukTooltip.Help>
                <BlockUsersModal
                    key={showModal ? 'true' : 'false'}
                    block={block}
                    visible={showModal}
                    onCancel={(e) => {
                        e && e.stopPropagation();
                        setShowModal(false);
                    }}
                />
            </div>
            <div className="block-users">
                {block.users.map((user, i) => (
                    <UserNameWithIcon key={i} withTooltip user={user} />
                ))}
            </div>
        </>
    );
};

const BlockUsersMobile = (props) => {
    return (
        <div className="block-users--mobile">
            <UsersCondensed users={props.users} />
        </div>
    );
};

const BlockUsersModal = (props) => {
    const { block, visible, onCancel } = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const _users = useSelector(arrayUsers);
    const { entity_id } = useSelector(currentEntitySelector);

    const users = useMemo(() => _users.map((u) => ({
        label: u.nameFull,
        value: u.user_id,
    })), [_users])

    const getBlockUsersPayload = useCallback(
        (formValues) => {
            const blockUsersMap = getMapFromArr(block.user_blocks, 'user_id');
            const valuesSet = new Set(formValues.block_users);

            const create = formValues.block_users.filter((v) => !blockUsersMap[v]);

            const remove = keys(blockUsersMap)
                .filter((k) => !valuesSet.has(+k))
                .map((k) => blockUsersMap[k].user_block_id);

            return { create, remove };
        },
        [block]
    );

    return (
        <Modal
            className="auk-modal"
            title={`Set person(s) in charge (${block.label})`}
            visible={visible}
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        const { create, remove } = getBlockUsersPayload(values);
                        if (!create.length && !remove.length) {
                            return onCancel();
                        }

                        dispatch(
                            updateBlockUsers(entity_id, block.block_id, create, remove, onCancel)
                        );
                    })
                    .catch((info) => {
                        console.error('Validate Failed:', info);
                    });
            }}
        >
            <Form
                name="blockUsersForm"
                id="blockUsersForm"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                className="block-users-form"
                style={{ height: '40vh' }}
            >
                <Form.Item
                    name="block_users"
                    initialValue={block.users
                        .filter((u) => u.user_id !== -1)
                        .map((u) => u.user_id)}
                    label="Personnel In-charge"
                >
                    <Select
                        options={users}
                        mode="multiple"
                        allowClear
                        filterOption={defaultFilterOption}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default WithBlockUsers;
