/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import _, { isEmpty, omit } from 'lodash';
import { connect } from 'react-redux';

import CONSTANTS from '../../Constants';
import UtilizationPresentation from './Utilization.component';

import { utilizationQueryString, utilizationState } from '../../../store/old/UI/Utilization/Utilization.selector';
import {
    utilizationFetchData,
    utilizationUpdateRange,
    utilizationUpdateResolution,
} from '../../../store/old/UI/Utilization/Utilization.action';
import { UtilizationConstants as K } from '../../../store/old/UI/Utilization/Utilization.constants';
import { getQueryParams } from '../../utils/service';
import { entityBlock } from '../../../store/old/Blocks/Blocks.selector';
import withRouter from '../../Hooks/withRouter';
import { setURLQuery } from '../../utils/url';

class UtilizationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.readFromQuery();
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sku_oee !== this.props.sku_oee) {
            this.refresh();
        }
    }

    componentWillUnmount() {
        this.props.cancelTask()
    }

    readFromQuery() {
        const query = omit(getQueryParams(window.location.search), ['entity_id']);
        if (!isEmpty(query)) this.updateUIStore(query);
    }

    updateUIStore(query) {
        const { from, to, res_period } = query;
        const date_range = [moment(from), moment(to)];
        const resolution = K.RESOLUTIONS.find((r) => r.res_period === res_period);
        this.props.updateResolution(resolution);
        this.props.updateRange(date_range);
    }

    refresh() {
        const { entityBlock, utilizationStore, router } = this.props;

        this.props.getData(entityBlock.entity_id, entityBlock.block_id, () => {
            const query = utilizationQueryString(utilizationStore, entityBlock.entity_id);

            setURLQuery(query)
        });
    }

    render() {
        return (
            <UtilizationPresentation
                {...(this.props)}
                refresh={this.refresh.bind(this)}
            />
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        user: appState.auth.user,
        blocks: appState.blocks,
        entityBlock: entityBlock(appState),
        utilizationStore: utilizationState(appState),
        oeeColors: appState.preference.entity.oee_colors,
        oee2: appState.ui.controls.oee2,
        sku_oee: appState.ui.controls.sku_oee,
        oeeCategories: appState.ui.oee.categories,
        data: appState.data.blocks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (entity_id, block_id, callback) =>
            dispatch(utilizationFetchData(entity_id, block_id, callback)),
        updateRange: (range) =>
            dispatch(utilizationUpdateRange(range)),
        updateResolution: (resolution) =>
            dispatch(utilizationUpdateResolution(resolution)),
        cancelTask: () => dispatch({ type: CONSTANTS.EVENTS.CANCEL_TASK })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UtilizationContainer));
