/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import withRouter from '../../Hooks/withRouter';


// COMPONENTS
import AukButton from '../../components/AukButton';
import AukTooltip from '../../components/AukTooltip';
import Collapsible from '../../components/Collapsible';
import CSVDownloadPrefixer from '../../components/PromptCSVDownloadPrefixer';
import VTable, {
    CustomFilterDropdown,
    CustomFilterIcon,
} from '../../components/VTable';
import RangeSelect from './components/RangeSelect';
import { RolePermission } from '../../components/Permission';

// CONSTANTS
import CONSTANTS from '../../Constants';
import { LabelConstants as K } from '../../../store/old/Labels/Labels.constants';

// SELECTORS
import { arrayAssets } from '../../../store/old/Assets/Assets.selector';

// HELPERS
import { saveCSV } from '../../utils/csv';
import { regexMatch, fileDatetimeFormat, csvDatetimeFormat } from '../../utils/helpers';
import { getDefaultResolution } from '../../utils/controls';

import './Search.scss';
import { ArrowRightOutlined } from '@ant-design/icons';
import { labelDisplayText } from '../../utils/labels';
import { SPAWrapper } from '../../components/SPAWrapper';
import { generateUrlQuery } from '../../utils/url';
import { Link } from 'react-router-dom';
import translate from '../../utils/translate';

const FILTER = {
    ISSUE: K.TYPES.ISSUE,
    SKU: K.TYPES.SKU,
    'W/O': K.TYPES['W/O'],
    'S/N': K.TYPES['S/N'],
};

const csvHeaderColumns = (showLabelNotes) =>
    showLabelNotes
        ? 'Type,Result,Notes,From,To,Duration(min),Machine,Output\n'
        : 'Type,Result,From,To,Duration(min),Machine,Output\n';

const getResult = (record, filter) => labelDisplayText(record);

const getType = (record) =>
    record.label_values
        .filter(({ type }) => type !== 'notes')
        .map(({ type }) => type)
        .join(' | ');

const getDate = (record, accessor) =>
    moment(record[accessor]).format(csvDatetimeFormat);

const getDateTo = (record) =>
    record.to ? getDate(record, 'to') : '(ON-GOING)';

const getAssetName = (record) => (record.asset ? record.asset.asset_name : '');

const getOutput = (record) => (record.issue ? '' : record.output);

class LabelSearchComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCSVExportPrompt: false,
            data: [],
        };

        this.assetFilterOptions = props.arrayAssets.map((a) => ({
            value: a.asset_name,
            text: a.asset_name,
        }));
        this.typeFilterOptions = [
            { value: FILTER.ISSUE, text: translate(FILTER.ISSUE) },
            { value: FILTER.SKU, text: FILTER.SKU.toUpperCase() },
            { value: FILTER['W/O'], text: FILTER['W/O'].toUpperCase() },
            { value: FILTER['S/N'], text: FILTER['S/N'].toUpperCase() },
        ];

        this.changeDaterange = this.changeDaterange.bind(this);
        this.download = this.download.bind(this);
        // this.navigate = this.navigate.bind(this);
        this.toggleShowCSVExportPrompt = this.toggleShowCSVExportPrompt.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.labels !== this.props.labels) {
            this.setState({ data: this.props.labels });
        }
    }

    toggleShowCSVExportPrompt() {
        this.setState({ showCSVExportPrompt: !this.state.showCSVExportPrompt });
    }

    changeDaterange(dateRange) {
        this.setState({ dateRange });
    }

    download(fileName) {
        const { showLabelNotes } = this.props;
        const rows = this.state.data.map((d) => {
            const result = getResult(d);
            const type = getType(d).toUpperCase();
            const from = getDate(d, 'from');
            const to = getDateTo(d);
            const duration = d.getDuration();
            const asset_name = getAssetName(d);
            const output = getOutput(d);
            const { notes } = d;

            return showLabelNotes
                ? `"${type}","${result}","${notes}",${from},${to},${duration},"${asset_name}",${output}`
                : `"${type}","${result}",${from},${to},${duration},"${asset_name}",${output}`;
        });

        const csvString = csvHeaderColumns(showLabelNotes) + rows.join('\n');

        saveCSV(csvString, `${fileName}_${this.range}.csv`);
    }

    getLabelUrl(l) {
        const { asset } = l;
        const startDate = moment(l.from);
        const endDate = moment(l._to);
        const durationSeconds = endDate.diff(startDate, 'seconds');
        const resolution = getDefaultResolution(durationSeconds);
        if (!resolution) return;

        const query = generateUrlQuery({ startDate, endDate, resolution, span: 'Custom' });
        return `${CONSTANTS.URLS.ASSET}/${asset.block_id}${query}`
    }

    // navigate(l) {
    //     const { asset } = l;
    //     const start = moment(l.from);
    //     const end = moment(l._to);
    //     const durationSeconds = end.diff(start, 'seconds');
    //     const resolution = getDefaultResolution(durationSeconds);

    //     if (!resolution) return;

    //     const pathname = `${CONSTANTS.URLS.ASSET}/${asset.block_id}`;
    //     const cpStateUpdateObject = {
    //         startDate: start,
    //         endDate: end,
    //         resolution,
    //         span: 'Custom',
    //     };

    //     this.props.setCPStateAndPushUrl(cpStateUpdateObject, pathname);
    // }

    get range() {
        return (
            this.state.dateRange &&
      this.state.dateRange
          .map((d) => moment(d).format(fileDatetimeFormat))
          .join('_')
        );
    }

    get columns() {
        return [
            {
                title: translate('type'),
                width: 120,
                render: (text, record, index) => {
                    const value = getType(record);
                    return (
                        <span
                            className="text-uppercase text-overflow-ellipsis"
                            title={value}
                        >
                            {value}
                        </span>
                    );
                },
                filters: this.typeFilterOptions,
                onFilter: (value, record) => regexMatch(getType(record), value),
            },
            {
                title: translate('result'),
                width: 180,
                render: (text, record, index) => {
                    const value = getResult(record);
                    return (
                        <span
                            className="text-uppercase text-overflow-ellipsis"
                            title={value}
                        >
                            {value}
                        </span>
                    );
                },
                sorter: (a, b) => {
                    const first = getResult(a);
                    const second = getResult(b);
                    return first.localeCompare(second);
                },
                onFilter: (value, record) =>
                    regexMatch(getResult(record), value, { escape: true }),
                filterDropdown: CustomFilterDropdown,
                filterIcon: (filtered) => <CustomFilterIcon active={filtered} />,
            },
            {
                title: 'Notes',
                width: 120,
                render: (text, record, index) => (
                    <span
                        className="text-uppercase text-overflow-ellipsis"
                        title={record.notes}
                    >
                        {record.notes}
                    </span>
                ),
                sorter: (a, b) => a.notes.localeCompare(b.notes),
                filterSearch: true,
                onFilter: (value, record) =>
                    regexMatch(record.notes, value, { escape: true }),
                filterDropdown: CustomFilterDropdown,
                filterIcon: (filtered) => <CustomFilterIcon active={filtered} />,
                hide: !this.props.showLabelNotes,
            },
            {
                title: translate('from'),
                render: (text, record, index) => getDate(record, 'from'),
                sorter: (a, b) => new Date(a.from) - new Date(b.from),
                width: 150,
            },
            {
                title: translate('to'),
                render: (text, record, index) => getDateTo(record),
                sorter: (a, b) => new Date(a._to) - new Date(b._to),
                width: 150,
            },
            {
                title: (
                    <span>
                        {translate('duration')} ({translate('minutes')})
                    </span>
                ),
                render: (text, record, index) => record.getDuration(),
                width: 150,
            },
            {
                title: translate('machine'),
                render: (text, record, index) => {
                    const value = getAssetName(record);
                    return (
                        <span className="text-overflow-ellipsis" title={value}>
                            {value}
                        </span>
                    );
                },
                sorter: (a, b) => {
                    const firstAsset = a.asset ? a.asset.asset_name : '';
                    const secondAsset = b.asset ? b.asset.asset_name : '';
                    return firstAsset.localeCompare(secondAsset);
                },
                filters: this.assetFilterOptions,
                onFilter: (value, record) =>
                    record.asset.asset_name.indexOf(value) === 0,
                width: 120,
            },
            {
                title: 'Output',
                render: (text, record, index) => getOutput(record),
                width: 80,
            },
            {
                width: 40,
                render: (text, record, index) => (
                    <Link to={this.getLabelUrl(record)}>
                        <ArrowRightOutlined />
                    </Link>
                ),
            },
        ].filter((col) => !col.hide);
    }

    render() {
        const {
            changeDaterange,
            columns,
            download,
            range,
            toggleShowCSVExportPrompt,
            props: { getLabels, labels },
            state: { data, filter, showCSVExportPrompt },
        } = this;

        return (
            <SPAWrapper className="labels-search">
                <div className="content-container">
                    <Collapsible isOpen={true} label="Select Date Range">
                        <div className="d-flex m-0 mb-2">
                            <div style={{ flexGrow: 1 }}>
                                <RangeSelect
                                    handleSubmit={(dateRange) => {
                                        changeDaterange(dateRange);
                                        getLabels(dateRange[0], dateRange[1]);
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    paddingTop: '1em',
                                    paddingBottom: 15,
                                    paddingRight: 7,
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    height: 91,
                                    background: '#fff',
                                    borderBottom: '1px solid #ddd',
                                }}
                            >
                                <RolePermission accessLevel="editor">
                                    <AukTooltip.Help title={translate('export')}>
                                        <AukButton.Outlined
                                            className="auk-button--round"
                                            onClick={toggleShowCSVExportPrompt}
                                            disabled={!labels.length}
                                        >
                                            <i className="fas fa-download" />
                                        </AukButton.Outlined>
                                    </AukTooltip.Help>
                                </RolePermission>
                            </div>
                        </div>
                    </Collapsible>
                    <div style={{ flexGrow: 1 }}>
                        <VTable
                            rowHeight={40}
                            columns={columns}
                            dataSource={labels}
                            rowKey={({ label_id }) => label_id}
                            onChange={(_p, _f, _s, extra) =>
                                this.setState({ data: extra.currentDataSource })
                            }
                        />
                    </div>
                </div>
                <CSVDownloadPrefixer
                    show={showCSVExportPrompt}
                    toggle={toggleShowCSVExportPrompt}
                    defaultPrefix="labels"
                    save={download}
                    date={range}
                />
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        arrayAssets: arrayAssets(appState),
        showLabelNotes: !!(
            appState.preference.entity.options &&
      appState.preference.entity.options.label_notes
        ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LabelSearchComponent));
