import { Colors } from '../../../legacy/Charts/Colors';
import { updateStoreRedux } from '../../../legacy/utils/redux';
import { PreferenceConstants as K } from './Preference.constants';

const getBasePreferences = () => {
    return {
        language: 'en',
        dark: false,
        time: {
            resolution: 4,
            period: 4,
        },
        default_view: '/dashboard',
        zoom_level: 1,
        oee: {
            oee2: true,
        },
        options: null,
    };
};

const defaultOEEColors = () => [
    {
        id: 1,
        th: 35,
        color: Colors.red[0],
    },
    {
        id: 2,
        th: 65,
        color: Colors.yellow[0],
    },
    {
        id: 3,
        th: 100,
        color: Colors.green[0],
    },
];

const initialReducer = {
    entity: {
        ...getBasePreferences(),
        oee_colors: defaultOEEColors(),
    },
    user: { ...getBasePreferences() },
    error: {},
    asset: {
        apply_to: 'asset',
        alerts: [],
        panels: {
            showChart: true,
            showOEE: true,
            showWO: true,
            showSKU: true,
        },
        assets: [],
        preference_id: undefined,
    },
};

export const preferencesReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.CHANGE_USER_LANGUAGE) {
        return updateStoreRedux(state, {
            user: { ...state.user, language: action.payload.language },
        });
    }

    if (
        action.type === K.ACTIONS.SET_ENTITY_PREFERENCES ||
    action.type === K.ACTIONS.GET_ENTITY_PREFERENCES_SUCCESS ||
    action.type === K.ACTIONS.CREATE_ENTITY_PREFERENCES_SUCCESS ||
    action.type === K.ACTIONS.UPDATE_ENTITY_PREFERENCES_SUCCESS
    ) {
        return updateStoreRedux(state, {
            entity: action.payload.entity,
        });
    }

    if (
        action.type === K.ACTIONS.SET_USER_PREFERENCES ||
    action.type === K.ACTIONS.GET_USER_PREFERENCES_SUCCESS ||
    action.type === K.ACTIONS.CREATE_USER_PREFERENCES_SUCCESS ||
    action.type === K.ACTIONS.UPDATE_USER_PREFERENCES_SUCCESS
    ) {
        return updateStoreRedux(state, {
            user: action.payload.user,
        });
    }

    if (
        action.type === K.ACTIONS.GET_ENTITY_PREFERENCES_FAILURE ||
    action.type === K.ACTIONS.CREATE_ENTITY_PREFERENCES_FAILURE ||
    action.type === K.ACTIONS.UPDATE_ENTITY_PREFERENCES_FAILURE ||
    action.type === K.ACTIONS.GET_USER_PREFERENCES_FAILURE ||
    action.type === K.ACTIONS.CREATE_USER_PREFERENCES_FAILURE ||
    action.type === K.ACTIONS.UPDATE_USER_PREFERENCES_FAILURE
    ) {
        return updateStoreRedux(state, {
            error: action.payload.error,
        });
    }

    if (action.type === K.ACTIONS.SET_ASSET_OPERATOR_PREFERENCES) {
        return updateStoreRedux(state, {
            asset: action.payload.asset || Object.assign(initialReducer.asset),
        });
    }

    return updateStoreRedux(state, {});
};
