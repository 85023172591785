/* eslint-disable react/prop-types */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';

// COMPONENTS
import { Permission } from '../../../components/Permission';

// ACTION COMPONENT
import UpdatePolicyAction from './PolicyAction';


class PermissionTableRow extends Component {
    blockSelectionRef = createRef();
    updatePolicyActionRef = createRef();

    constructor(props) {
        super(props);
    }

    render() {
        const {
            policy,
            groups,
            openModalDelete,
            openModalEdit,
        } = this.props;

        return (
            <>
                <tr>
                    <td className="policy-name">
                        <div className="data">{policy.policy_name}</div>
                    </td>
                    <td className="groups-list">
                        <div className="data">
                            {policy.groups &&
                policy.groups.map((groupId) => {
                    return <p key={groupId}>- {groups[groupId].group_name}</p>;
                })}
                        </div>
                    </td>
                    <td className="actions">
                        <div className="data">
                            <Permission resource="permissions" forResource canDo="edit">
                                <span onClick={() => openModalEdit(policy)}>
                                    <i className="far fa-2x fa-edit" />
                                </span>
                            </Permission>

                            <Permission resource="permissions" forResource canDo="full">
                                <span onClick={() => openModalDelete(policy)}>
                                    <i className="far fa-2x fa-trash-alt" />
                                </span>
                            </Permission>
                        </div>
                    </td>
                </tr>
                <UpdatePolicyAction ref={this.updatePolicyActionRef} />
            </>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        authUser: rootState.auth.user,
        users: rootState.user.list,
        groups: rootState.group.groups,
    };
};

export default connect(
    mapStateToProps,
    null
)(PermissionTableRow);
