export const usersState = (appState) =>
    appState.user.list;

export const arrayUsers = (appState) => {
    const usersMap = usersState(appState);
    return Object.keys(usersMap)
        .map((user_id) => usersMap[+user_id])
        .sort((a, b) => {
            if (a.nameFull < b.nameFull) return -1;
            if (a.nameFull > b.nameFull) return 1;
            return 0;
        });
};
