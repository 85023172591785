import { round } from 'lodash';
import CONSTANTS from '../Constants';

export const parseTsData = (arr) => {
    return arr
        .map((d) => {
            d.time = new Date(d.time);
            return d;
        })
        .filter((d) => d.val !== null)
        .sort((a, b) => a.time - b.time);
};

export const TimeSeriesFactory = (d) => {
    const data = parseTsData(d);
    data.total = data.reduce((acc, curr) => (acc += curr.val), 0);
    data.last = data.length ? round(data[data.length - 1].val, 2) : '-';
    data.aggregate = function (mode, units) {
        const { total, last } = this;
        switch (mode) {
        case CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT:
            return `Total: ${total.toLocaleString()} ${units}`;
        case CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE:
            return `Last: ${last} ${units}`;
        default:
            return '';
        }
    };

    data.__defineGetter__('cumulative', function () {
        delete this.cumulative;
        let temp = 0;
        return (this.cumulative = data.map((d) => ({
            time: d.time,
            int: d.int,
            val: (temp += d.val),
        })));
    });

    return data;
};
