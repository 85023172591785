/* eslint-disable react/prop-types */
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { DatePicker, Select, Switch } from 'antd';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {
    CalendarOutlined,
    LeftOutlined,
    RightOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
} from '@ant-design/icons';
import CONSTANTS from '../../Constants';
import { CUSTOM_SPAN } from '../../../store/old/UI/Controls/Controls.constants';
import { uiDatetimeFormat } from '../../utils/helpers';
import {
    generateSpanObj,
    getAllowableResolutions,
    getAppropriateResolution,
    getBrowserTz,
    getRangeFromCustomSpan,
    getRangeFromTimeBlock,
    isControlledView,
    RESOLUTIONS_MAP,
} from '../../utils/controls';
import { errorFlash } from '../Flash';
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { minDevicePushRate } from '../../../store/old/Devices/Devices.selector';
import { ToggleOEE2, ToggleOEESKU } from '../../../store/old/UI/Controls/Controls.action';
import AukButton from '../AukButton';
import GatewaySettings from './GatewaySettings';
import { LocationBreadcrumbs } from '../Breadcrumbs';
import AukTooltip from '../AukTooltip';
import './ControlPanel.scss';
import Banner from '../Banner';
import withRouter from '../../Hooks/withRouter';
import { generateUrlQuery } from '../../utils/url';

const MINUTE_SECONDS = moment.duration(1, 'minute').as('seconds');
const HOUR_SECONDS = moment.duration(1, 'hour').as('seconds');
const DAY_SECONDS = moment.duration(1, 'day').as('seconds');
const YEAR_SECONDS = moment.duration(1, 'year').as('seconds');
const rangeValid = (startDate, endDate) => {
    const rangeSeconds = moment.duration(endDate.diff(startDate)).as('seconds');
    return startDate.isSameOrBefore(endDate) && rangeSeconds <= YEAR_SECONDS;
};

class ControlPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.getStateFromProps(props.controls),
            banner: undefined,
        };

        this.fetchBannerData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.controls, this.props.controls)) {
            return this.setState(this.getStateFromProps(this.props.controls));
        }
    }

    async fetchBannerData() {
        try {
            const response = await fetch(
                CONSTANTS.CMS_API + 'system-banner-authenticated',
                { method: 'GET' }
            );

            const banner = await response.json();
            this.setState({ banner });
        } catch (e) {
            errorFlash(e);
        }
    }

    navigate(query) {
        this.props.router.navigate(`${this.props.router.location.pathname}${query}`)
    }

    getStateFromProps = (reduxControls) => {
        const {
            startDate,
            endDate,
            span,
            resolution,
        } = reduxControls;
        return {
            startDate,
            endDate,
            span,
            resolution,
        };
    };

    handleChangeResolution = (value) => {
        const { startDate, endDate } = this.state;
        if (!rangeValid(startDate, endDate)) return;
        
        const resolution = RESOLUTIONS_MAP[value]
        this.setState({ resolution }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        })
    };

    handleChangeStartDate = (startDate) => {
        const { endDate } = this.state;
        const { controls } = this.props;
        const span = CUSTOM_SPAN;

        if (!rangeValid(startDate, endDate)) {
            return this.setState({ startDate, resolution: undefined, span });
        }

        const rangeSeconds = moment.duration(endDate.diff(startDate)).as('seconds');
        const resolution = getAppropriateResolution(
            rangeSeconds,
            controls.resolution
        );

        this.setState({ startDate, resolution, span }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        })
    };

    handleChangeEndDate = (endDate) => {
        const { startDate } = this.state;
        const { controls } = this.props;
        const span = CUSTOM_SPAN;

        if (!rangeValid(startDate, endDate)) {
            return this.setState({ endDate, resolution: undefined, span });
        }

        const rangeSeconds = moment.duration(endDate.diff(startDate)).as('seconds');
        const resolution = getAppropriateResolution(
            rangeSeconds,
            controls.resolution
        );

        this.setState({ endDate, resolution, span }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        })
    };

    handleChangeSpan = (selection) => {
        const {
            entity: { tzStartDifference },
            controls,
        } = this.props;

        const now = moment();
        let startDate, endDate;
        const anchor = controls.streaming ? now : controls.startDate;

        // fixed time block
        const _span = generateSpanObj(selection);
        [startDate, endDate] = getRangeFromTimeBlock(
            anchor,
            _span,
            getBrowserTz(),
            tzStartDifference
        );

        const rangeSeconds = moment.duration(endDate.diff(startDate)).as('seconds');
        const resolution = getAppropriateResolution(
            rangeSeconds,
            controls.resolution
        );

        this.setState({ startDate, endDate, resolution, span: selection }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        })
    };

    handleClickBackArrow = () => {
        const { rangeSeconds } = this;
        // const { span } = this.state;

        const startDate = this.state.startDate
            .clone()
            .subtract(rangeSeconds, 'seconds');
        const endDate = this.state.endDate
            .clone()
            .subtract(rangeSeconds, 'seconds');

        this.setState({ startDate, endDate }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        })
    };

    handleClickForwardArrow = () => {
        const { rangeSeconds } = this;
        // const { span } = this.state;

        const startDate = this.state.startDate.clone().add(rangeSeconds, 'seconds');
        const endDate = this.state.endDate.clone().add(rangeSeconds, 'seconds');

        this.setState({ startDate, endDate }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        })
    };

    handleClickLive = () => {
        const {
            entity: { tzStartDifference },
        } = this.props;
        const { span } = this.state;

        let startDate, endDate;
        const now = moment();
        if (span === CUSTOM_SPAN) {
            [startDate, endDate] = getRangeFromCustomSpan(
                this.state.startDate,
                this.state.endDate,
                now
            );
        } else {
            [startDate, endDate] = getRangeFromTimeBlock(
                now,
                generateSpanObj(span),
                getBrowserTz(),
                tzStartDifference
            );
        }


        this.setState({ startDate, endDate }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        })
    };

    handleClickLast = () => {
        const {
            entity: { tzStartDifference },
        } = this.props;
        const { span } = this.state;

        let startDate, endDate;
        const now = moment();
        if (span === CUSTOM_SPAN) {
            [startDate, endDate] = getRangeFromCustomSpan(
                this.state.startDate,
                this.state.endDate,
                now,
                -1
            );
        } else {
            const { size, unit } = generateSpanObj(span);
            [startDate, endDate] = getRangeFromTimeBlock(
                now,
                { size, unit },
                getBrowserTz(),
                tzStartDifference
            ).map((d) => d.subtract(size, unit));
        }

        this.setState({ startDate, endDate }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        })
    };

    // handleExtendStartDate = () => {
    //     console.log('handle extend start date')
    //     const span = CUSTOM_SPAN;
        
    //     const startDate = this.state.startDate.clone().subtract(this.rangeSeconds, 'seconds');
    //     const rangeSeconds = moment.duration(this.state.endDate.diff(startDate)).as('seconds');

    //     if (!rangeValid(startDate, this.state.endDate)) {
    //         return this.setState({
    //             startDate,
    //             resolution: undefined,
    //             span,
    //         });
    //     }

    //     const resolution = getAppropriateResolution(
    //         rangeSeconds,
    //         this.props.controls.resolution
    //     );

    //     this.setState({ startDate, resolution, span }, () => {
    //         this.navigate(generateUrlQuery(this.localControlsState))
    //     });
    // };

    handleExtendStartDateBackward = () => {
        const span = CUSTOM_SPAN;
        const startDate = this.state.startDate.clone().subtract(this.rangeSeconds, 'seconds');

        const rangeSeconds = moment.duration(this.state.endDate.diff(startDate)).as('seconds');

        if (!rangeValid(startDate, this.state.endDate)) {
            return this.setState({
                startDate,
                resolution: undefined,
                span,
            });
        }

        const resolution = getAppropriateResolution(
            rangeSeconds,
            this.props.controls.resolution
        );

        this.setState({ startDate, resolution, span }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        });
    };

    handleExtendStartDateForward = () => {
        const span = CUSTOM_SPAN;

        const startDate = this.state.startDate.clone().add(this.rangeSeconds / 2, 'seconds');

        const rangeSeconds = moment.duration(this.state.endDate.diff(startDate)).as('seconds');

        if (!rangeValid(startDate, this.state.endDate)) {
            return this.setState({
                startDate,
                resolution: undefined,
                span,
            });
        }

        const resolution = getAppropriateResolution(
            rangeSeconds,
            this.props.controls.resolution
        );

        this.setState({ startDate, resolution, span }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        });
    };

    handleExtendEndDateBackward = () => {
        const span = CUSTOM_SPAN;

        const endDate = this.state.endDate.clone().subtract(this.rangeSeconds / 2, 'seconds');

        const rangeSeconds = moment.duration(endDate.diff(this.state.startDate)).as('seconds');

        if (!rangeValid(this.state.startDate, endDate)) {
            return this.setState({
                endDate,
                resolution: undefined,
                span,
            });
        }
        const resolution = getAppropriateResolution(
            rangeSeconds,
            this.props.controls.resolution
        );

        this.setState({ endDate, resolution, span }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        });
    };

    handleExtendEndDateForward = () => {
        const span = CUSTOM_SPAN;
        const endDate = this.state.endDate.clone().add(this.rangeSeconds, 'seconds');

        const rangeSeconds = moment.duration(endDate.diff(this.state.startDate)).as('seconds');

        if (!rangeValid(this.state.startDate, endDate)) {
            return this.setState({
                endDate,
                resolution: undefined,
                span,
            });
        }
        const resolution = getAppropriateResolution(
            rangeSeconds,
            this.props.controls.resolution
        );

        this.setState({ endDate, resolution, span }, () => {
            this.navigate(generateUrlQuery(this.localControlsState))
        });
    };

    getRangeString(durationSeconds) {
        let days, hours, minutes;

        days = Math.floor(durationSeconds / DAY_SECONDS);
        durationSeconds = durationSeconds - days * DAY_SECONDS;

        hours = Math.floor(durationSeconds / HOUR_SECONDS);
        durationSeconds = durationSeconds - hours * HOUR_SECONDS;

        minutes = durationSeconds / MINUTE_SECONDS;

        const dayString = days ? `${days} ${days > 1 ? 'days' : 'day'} ` : '';
        const hourString = hours ? `${hours} ${hours > 1 ? 'hours' : 'hour'} ` : '';
        const minuteString = minutes
            ? `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} `
            : '';

        return dayString + hourString + minuteString;
    }

    get localControlsState() {
        return {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            span: this.state.span,
            resolution: this.state.resolution
        }
    }

    get rangeSeconds() {
        const { startDate, endDate } = this.state;
        return moment.duration(endDate.diff(startDate)).as('seconds');
    }

    get isLive() {
        return this.props.controls.streaming;
    }

    get isLast() {
        const {
            controls: { startDate, endDate, streaming },
        } = this.props;
        const now = moment();

        const [start, end] = getRangeFromCustomSpan(startDate, endDate, now, -1);
        return !streaming && startDate.isSame(start) && endDate.isSame(end);
    }

    get isValidRange() {
        const { startDate, endDate } = this.state;
        return rangeValid(startDate, endDate);
    }

    get shouldDisplayControlPanel() {
        const { pathname } = this.props.router.location;
        return !(pathname.match('planner') || pathname.match('production'));
    }

    get shouldDisplayOEE() {
        const {
            ASSET,
            DASHBOARD,
            PARETO,
            TREND,
            UTILIZATION,
            BLOCK,
            ASSET_MANAGEMENT,
        } = CONSTANTS.URLS;

        const { href } = window.location;

        const assetRegex = new RegExp(ASSET);
        const assetManagementRegex = new RegExp(ASSET_MANAGEMENT);
        const blockRegex = new RegExp(BLOCK);
        const paretoRegex = new RegExp(PARETO);
        const dashboardRegex = new RegExp(DASHBOARD);
        const trendRegex = new RegExp(TREND);
        const utilizationRegex = new RegExp(UTILIZATION);

        return (
            (href.match(assetRegex) && !href.match(assetManagementRegex)) ||
      href.match(blockRegex) ||
      href.match(utilizationRegex) ||
      href.match(dashboardRegex) ||
      href.match(paretoRegex) ||
      href.match(trendRegex)
        );
    }

    get shouldDisplayTime() {
        return isControlledView(window.location.href);
    }

    get spanTooltipTitle() {
        if (this.state.span === CUSTOM_SPAN) {
            return `Span: Custom (${this.getRangeString(this.rangeSeconds)})`;
        }

        return `Span: ${this.state.span}`;
    }

    render() {
        if (!this.shouldDisplayControlPanel) return null;

        const {
            entity: { timezone },
            controls: { oee2, sku_oee },
            routes,
            toggleOEE2,
            toggleOEESKU,
        } = this.props;

        const { startDate, endDate, banner } = this.state;
        const { getRangeString, rangeSeconds } = this;

        const isValidRange = rangeValid(startDate, endDate);

        return (
            <div className="control-panel-wrapper">
                <div className="control-panel">
                    <InfoBar timezone={timezone} routes={routes} />
                    <div className="cp-main d-flex">
                        {this.shouldDisplayTime && (
                            <div className="time-range no-select">
                                <div className="time-range__nav">
                                    <BackArrow
                                        onClick={this.handleClickBackArrow}
                                        disabled={!isValidRange}
                                        title={`Previous ${getRangeString(rangeSeconds)}`}
                                    />
                                    <div className="time-range__dates-select">
                                        <DateSelect
                                            isValid={isValidRange}
                                            handleChange={this.handleChangeStartDate}
                                            value={this.state.startDate}
                                            suffix={
                                                <>
                                                    <AukTooltip.Help
                                                        title={`Move start date back (${getRangeString(
                                                            rangeSeconds
                                                        )})`}
                                                        mouseEnterDelay={0.5}
                                                    >
                                                        <AukButton.Outlined
                                                            className="h-100 p-0 mr-1"
                                                            style={{ border: 0 }}
                                                            size="small"
                                                            disabled={!isValidRange}
                                                        >
                                                            <StepBackwardOutlined
                                                                onClick={this.handleExtendStartDateBackward}
                                                            />
                                                        </AukButton.Outlined>
                                                    </AukTooltip.Help>
                                                    <AukTooltip.Help
                                                        title={`Move start date forward (${getRangeString(
                                                            rangeSeconds / 2
                                                        )})`}
                                                        mouseEnterDelay={0.5}
                                                    >
                                                        <AukButton.Outlined
                                                            className="h-100 p-0"
                                                            style={{ border: 0 }}
                                                            size="small"
                                                            disabled={!isValidRange}
                                                        >
                                                            <StepForwardOutlined
                                                                onClick={this.handleExtendStartDateForward}
                                                            />
                                                        </AukButton.Outlined>
                                                    </AukTooltip.Help>
                                                </>
                                            }
                                            warn={{
                                                title: 'Invalid range / range cannot be greater than one year',
                                                placement: 'top',
                                            }}
                                        />
                                        <DateSelect
                                            isValid={isValidRange}
                                            handleChange={this.handleChangeEndDate}
                                            value={this.state.endDate}
                                            suffix={
                                                <>
                                                    <AukTooltip.Help
                                                        title={`Move end date back (${getRangeString(
                                                            rangeSeconds / 2
                                                        )})`}
                                                        mouseEnterDelay={0.5}
                                                    >
                                                        <AukButton.Outlined
                                                            className="h-100 p-0 mr-1"
                                                            style={{ border: 0 }}
                                                            size="small"
                                                            disabled={!isValidRange}
                                                        >
                                                            <StepBackwardOutlined
                                                                onClick={this.handleExtendEndDateBackward}
                                                            />
                                                        </AukButton.Outlined>
                                                    </AukTooltip.Help>
                                                    <AukTooltip.Help
                                                        title={`Move end date forward (${getRangeString(
                                                            rangeSeconds
                                                        )})`}
                                                        mouseEnterDelay={0.5}
                                                    >
                                                        <AukButton.Outlined
                                                            className="h-100 p-0"
                                                            style={{ border: 0 }}
                                                            size="small"
                                                            disabled={!isValidRange}
                                                        >
                                                            <StepForwardOutlined
                                                                onClick={this.handleExtendEndDateForward}
                                                            />
                                                        </AukButton.Outlined>
                                                    </AukTooltip.Help>
                                                </>
                                            }
                                            warn={{
                                                title:
                          'Invalid range / range cannot be greater than one year',
                                                placement: 'bottom',
                                            }}
                                        />
                                    </div>
                                    <ForwardArrow
                                        onClick={this.handleClickForwardArrow}
                                        disabled={!isValidRange}
                                        title={`Next ${getRangeString(rangeSeconds)}`}
                                    />
                                </div>
                                <div className="time-range__config">
                                    <div className="time-range__hotkeys time-control-inp">
                                        <LastButton
                                            onClick={this.handleClickLast}
                                            active={this.isLast}
                                            disabled={!isValidRange}
                                            title={`Go to last ${getRangeString(rangeSeconds)}`}
                                        />
                                        <LiveButton
                                            onClick={this.handleClickLive}
                                            active={this.isLive}
                                            disabled={!isValidRange}
                                            title={`Data streaming: ${this.isLive ? 'ON' : 'OFF'}`}
                                        />
                                    </div>
                                    <div className="time-range__span-res time-control-inp">
                                        <div className="time-range__span-res-item">
                                            <SpanSelect
                                                onChange={this.handleChangeSpan}
                                                value={this.state.span}
                                                disabled={!isValidRange}
                                                title={this.spanTooltipTitle}
                                            />
                                        </div>
                                        <div className="time-range__span-res-divider">/</div>
                                        <div className="time-range__span-res-item">
                                            <ResolutionSelect
                                                title="Resolution"
                                                rangeSeconds={this.rangeSeconds}
                                                value={this.state.resolution}
                                                handleChange={this.handleChangeResolution}
                                                disabled={!isValidRange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.shouldDisplayOEE && (
                            <div className="oee-switches">
                                <OEEToggle value={oee2} onChange={toggleOEE2} />
                                <OEESKUToggle value={sku_oee} onChange={toggleOEESKU} />
                            </div>
                        )}
                    </div>
                    <GatewaySettings />
                </div>
                <Banner display={banner && banner.display} data={banner} withModal />
            </div>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        entity: currentEntitySelector(appState),
        controls: controlsState(appState),
        minPushRate: minDevicePushRate(appState),
        user: appState.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleOEE2: () => dispatch(ToggleOEE2()),
        toggleOEESKU: () => dispatch(ToggleOEESKU()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ControlPanel));

const InfoBar = ({ routes }) => {
    return (
        <div className="cp-info">
            <LocationBreadcrumbs />
            <Timer />
        </div>
    );
};

const Timer = () => {
    const [time, setTime] = useState(moment());
    useEffect(() => {
        const timerId = setInterval(() => setTime(moment()), 1000);
        return () => clearInterval(timerId);
    }, []);

    return (
        <div className="control-panel-item current-time">
            {time.format('YYYY-MMM-DD HH:mm:ss')}
        </div>
    );
};

const OEEToggle = ({ value, onChange }) => {
    return (
        <AukTooltip.Help title="Click to change OEE" mouseEnterDelay={0.8}>
            <Switch
                onChange={onChange}
                checkedChildren="OEE-2"
                unCheckedChildren="OEE-1"
                checked={value}
            />
        </AukTooltip.Help>
    );
};

const OEESKUToggle = ({ value, onChange }) => {
    return (
        <AukTooltip.Help title="Click to change OEE-SKU" mouseEnterDelay={0.8}>
            <Switch
                onChange={onChange}
                checkedChildren="SKU-ON"
                unCheckedChildren="SKU-OFF"
                checked={value}
            />
        </AukTooltip.Help>
    );
};

const ResolutionSelect = ({ rangeSeconds, value, handleChange, disabled, title }) => {
    const options = useMemo(() => {
        return getAllowableResolutions({ duration: rangeSeconds }).map((r) => {
            const str = `${r.res_x} ${r.res_period}`;
            return { value: str, label: str, title: '' };
        });
    }, [rangeSeconds]);

    const selectValue = value ? `${value.res_x} ${value.res_period}` : null

    return (
        <AukTooltip.Help title={title} mouseEnterDelay={1}>
            <div className="item time-control-inp">
                <Select
                    className="w-100"
                    size="small"
                    options={options}
                    value={selectValue}
                    onChange={handleChange}
                    disabled={disabled}
                    showArrow={false}
                />
            </div>
        </AukTooltip.Help>
    );
};

const DateSelect = ({ handleChange, value, suffix, isValid = true, warn }) => {
    const [highlight, setHighlight] = useState(true);

    const datepickerProps = {
        allowClear: false,
        format: uiDatetimeFormat,
        showTime: { format: 'HH:mm' },
        size: 'small',
        suffix,
    };

    useEffect(() => {
        setHighlight(true);
    }, [value]);

    useEffect(() => {
        if (!highlight) return;
        if (highlight) {
            setTimeout(() => setHighlight(false), 1000);
        }
    }, [highlight]);

    return (
        <div className="control-panel-item time-control-inp">
            <div
                className={`date-select-group ${
                    isValid ? '' : 'date-select-group-invalid'
                } ${highlight ? 'date-select-group--highlight' : ''}`}
            >
                <div className="date-select-group__prefix">
                    {isValid ? (
                        <CalendarOutlined />
                    ) : (
                        <AukTooltip.Warn title={warn.title} placement={warn.placement} />
                    )}
                </div>
                <DatePicker
                    {...datepickerProps}
                    value={value}
                    onChange={handleChange}
                    suffixIcon={null}
                />
                <div className="date-select-group__suffix">{suffix}</div>
            </div>
        </div>
    );
};

const BackArrow = (props) => {
    return (
        <AukTooltip.Help title={props.title} mouseEnterDelay={0.5}>
            <AukButton.Outlined
                icon={<LeftOutlined />}
                size="small"
                onClick={props.onClick}
                disabled={props.disabled}
                className="mr-1 h-100"
            />
        </AukTooltip.Help>
    );
};

const ForwardArrow = (props) => {
    return (
        <AukTooltip.Help title={props.title} mouseEnterDelay={0.5}>
            <AukButton.Outlined
                icon={<RightOutlined />}
                size="small"
                onClick={props.onClick}
                disabled={props.disabled}
                className="ml-1 h-100"
            />
        </AukTooltip.Help>
    );
};

const SpanSelectOptions = [
    { label: '15 minutes', value: '15 minutes', disabled: false },
    { label: '1 hour', value: '1 hours', disabled: false },
    { label: '1 day', value: '1 days', disabled: false },
    { label: '1 week', value: '1 weeks', disabled: false },
    { label: '1 month', value: '1 months', disabled: false },
    { label: '3 months', value: '3 months', disabled: false },
    { label: '1 year', value: '1 years', disabled: false },
    { label: CUSTOM_SPAN, value: CUSTOM_SPAN, disabled: true },
];

const { Option } = Select;
const SpanSelect = (props) => {
    return (
        <AukTooltip.Help title={props.title} mouseEnterDelay={1}>
            <Select
                size="small"
                onChange={props.onChange}
                placeholder="Please select"
                value={props.value || '1 day'}
                disabled={props.disabled}
                allowClear={false}
                style={{ flexGrow: 1 }}
                optionLabelProp="label"
                showArrow={false}
            >
                {SpanSelectOptions.map((opt, i) => {
                    return (
                        <Option
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            disabled={opt.disabled}
                        />
                    );
                })}
            </Select>
        </AukTooltip.Help>
    );
};

const LastButton = (props) => {
    const { active, onClick, disabled } = props;
    return (
        <AukTooltip.Help title={props.title} mouseEnterDelay={0.8}>
            <AukButton.ToggleBlue
                active={active}
                size="small"
                className="mr-1 w-50"
                onClick={onClick}
                disabled={disabled}
            >
        Last
            </AukButton.ToggleBlue>
        </AukTooltip.Help>
    );
};

const LiveButton = (props) => {
    const { active, onClick, disabled } = props;
    return (
        <AukTooltip.Help title={props.title} mouseEnterDelay={0.8}>
            <AukButton.ToggleGreen
                active={active}
                size="small"
                className="w-50"
                onClick={onClick}
                disabled={disabled}
            >
                <div className="d-flex align-items-center">
                    {active && <div className="circle blink mr-2" />}
                    <div>Live</div>
                </div>
            </AukButton.ToggleGreen>
        </AukTooltip.Help>
    );
};
