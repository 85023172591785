/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Breadcrumb } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AssetShiftTargetModal from './AssetShiftTargetModal';
import BlocksTreeSelect from '../../../components/BlocksTreeSelect';
import CONSTANTS from '../../../Constants';
import ShiftCalendarCard from './ShiftCalendarCard';
import withAsset from '../../../Wrappers/HOCs/withAsset';
import { SPAWrapper } from '../../../components/SPAWrapper';
import { withEntity } from '../../../Wrappers/HOCs/withEntity';
import { fetchAssetShifts } from '../../../../store/old/Schedules/Schedules.action';
import { WeekCalendar } from '../../../components/Calendar';
import { defaultFilterOption } from '../../../utils/helpers';
import { getBlocksTreeData } from '../../../utils/blocks';
import { userSelector } from '../../../../store/old/Authentication/Authentication.selector';
import { hasRolePermission } from '../../../components/Permission';
import { flash } from '../../../components/Flash';

import './index.scss';

const AssetShifts = ({ asset, entity }) => {
    const { block_id, asset_id } = asset;
    const { entity_id } = entity;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(userSelector);
    const shifts = useSelector(
        (appState) => appState.schedules.assets[asset_id] || []
    );

    const [week, setWeek] = useState(null);
    const [showTargetModal, setShowTargetModal] = useState(false);
    const [shiftSelection, setShiftSelection] = useState(null);
    const now = moment();

    const fetchShifts = useCallback(() => {
        if (week) {
            const [startsAt, endsAt] = week;

            dispatch(
                fetchAssetShifts(entity_id, asset_id, {
                    starts_at: startsAt.valueOf(),
                    ends_at: endsAt.valueOf(),
                })
            );
        }
    }, [week]);

    useEffect(() => {
        fetchShifts();
    }, [week]);

    const assetUrl = useMemo(() => `${CONSTANTS.URLS.ASSET}/${block_id}`, []);

    const events = useMemo(
        () =>
            shifts
                .map((s) => {
                    return {
                        ...s,
                        styles: (hasElapsed) => ({
                            backgroundColor: hasElapsed ? '#C5DFC9' : '#7ce28d',
                        }),
                        onClick: () => {
                            if (!hasRolePermission(user.role_name, 'editor')) {
                                flash({
                                    message: 'You do not have access to this resource',
                                    status: 'warning',
                                });
                                return;
                            }

                            setShiftSelection(s);
                            setShowTargetModal(true);
                        },
                        render: (hasElapsed) => (
                            <ShiftCalendarCard
                                hasElapsed={hasElapsed}
                                data={s}
                                onSuccess={fetchShifts}
                            />
                        ),
                    };
                })
                .sort((a, b) => a.from.unix() - b.from.unix()),
        [shifts]
    );

    return (
        <SPAWrapper className="asset-shifts">
            <div className="asset-shifts__header">
                <div>
                    <Link to={assetUrl}>Back to asset</Link>
                </div>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={CONSTANTS.URLS.DASHBOARD}>Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <BlocksTreeSelect
                                value={block_id}
                                onChange={(id) =>
                                    navigate(`${CONSTANTS.URLS.ASSET}/${id}/planner`)
                                }
                                style={{ width: 240 }}
                                treeDefaultExpandAll
                                showSearch
                                filterTreeNode={defaultFilterOption}
                                getTreeData={(node) =>
                                    getBlocksTreeData(node, {
                                        disabledAccessor: (n) => !n.asset || n.isRestricted,
                                    })
                                }
                            />
                        </Breadcrumb.Item>

                        <Breadcrumb.Item>Planner</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="asset-shifts__body">
                <WeekCalendar
                    current={now}
                    events={events}
                    onNavigate={(w) => setWeek(w)}
                />
            </div>
            <AssetShiftTargetModal
                key={shiftSelection ? 1 : 2}
                data={shiftSelection}
                visible={showTargetModal}
                onCancel={() => {
                    setShowTargetModal(false);
                    setShiftSelection(null);
                }}
                onSuccess={() => fetchShifts()}
            />
        </SPAWrapper>
    );
};

export default withEntity(withAsset(AssetShifts));
