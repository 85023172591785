/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Dropdown } from 'antd';
import translate from '../utils/translate';


const AukButtonBlue = (props) => (
    <Button {...props} className={`auk-button ${props.className}`} type="primary">
        {props.children}
    </Button>
);

const AukButtonNavy = (props) => (
    <Button
        {...props}
        className={`auk-button auk-button--solid-dark-blue ${props.className}`}
    >
        {props.children}
    </Button>
);

const AukButtonRed = (props) => (
    <Button
        {...props}
        className={`auk-button auk-button--solid-red ${props.className}`}
    >
        {props.children}
    </Button>
);

const AukButtonVolcano = (props) => (
    <Button
        {...props}
        className={`auk-button auk-button--volcano ${props.className}`}
    >
        {props.children}
    </Button>
);

const AukButtonGreen = (props) => (
    <Button
        {...props}
        className={`auk-button auk-button--solid-green ${props.className}`}
    >
        {props.children}
    </Button>
);

const AukButtonOutlined = (props) => (
    <Button {...props} className={`auk-button ${props.className}`}>
        {props.children}
    </Button>
);

const AukButtonReset = (props) => (
    <AukButtonOutlined {...props}>
        <span className="mr-2">
            <i className="fas fa-undo" />
        </span>
        {translate('reset')}
    </AukButtonOutlined>
);

const AukButtonSave = (props) => (
    <AukButtonBlue {...props}>
        <span className="mr-2">
            <i className="fas fa-save" />
        </span>
        {translate('save')}
    </AukButtonBlue>
);

const AukButtonUpload = (props) => (
    <AukButtonBlue {...props}>
        <span className="mr-2">
            <i className="fas fa-upload" />
        </span>
        {translate('upload')}
    </AukButtonBlue>
);

const AukButtonClose = (props) => (
    <AukButtonOutlined {...props}>
        <span className="mr-2">
            <i className="fas fa-times" />
        </span>
        {translate('close')}
    </AukButtonOutlined>
);

const AukButtonCancel = (props) => (
    <AukButtonOutlined {...props}>
        <span className="mr-2">
            <i className="fas fa-times" />
        </span>
        {translate('cancel')}
    </AukButtonOutlined>
);

const AukButtonDelete = (props) => (
    <AukButtonRed {...props}>
        <span className="mr-2">
            <i className="fas fa-trash" />
        </span>
        {translate('delete')}
    </AukButtonRed>
);

const AukButtonDropdown = (props) => {
    const icon = props.icon || <i className="fas fa-ellipsis-h" />;

    return (
        <Dropdown.Button
            {...props}
            className={`auk-dropdown-button auk-button ${props.className || ''}`}
            icon={icon}
        />
    );
};

const AukButtonToggleBlue = (props) => {
    const _props = { ...props, active: undefined };
    return (
        <Button
            {..._props}
            className={`auk-toggle-button auk-button ${props.className}`}
            type={props.active ? 'primary' : 'default'}
        >
            {props.children}
        </Button>
    );
};

const AukButtonToggleGreen = (props) => {
    const _props = { ...props, active: undefined };
    const Btn = props.active ? AukButtonGreen : AukButtonOutlined;
    return (
        <Btn
            {..._props}
            className={`auk-toggle-button auk-button auk-toggle-button--green ${props.className}`}
        >
            {props.children}
        </Btn>
    );
};

const AukButton = {
    Blue: AukButtonBlue,
    Red: AukButtonRed,
    Outlined: AukButtonOutlined,
    Save: AukButtonSave,
    Upload: AukButtonUpload,
    Cancel: AukButtonCancel,
    Close: AukButtonClose,
    Delete: AukButtonDelete,
    Reset: AukButtonReset,
    Dropdown: AukButtonDropdown,
    Navy: AukButtonNavy,
    Volcano: AukButtonVolcano,
    Green: AukButtonGreen,
    ToggleBlue: AukButtonToggleBlue,
    ToggleGreen: AukButtonToggleGreen,
};

export default AukButton;
