/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withEntity } from '../../Wrappers/HOCs/withEntity';
import { SPAWrapper } from '../../components/SPAWrapper';

import './index.scss';
import AukFilterSelectMulti from '../../components/AukFilterSelectMulti';
import { FilterOutlined } from '@ant-design/icons';
import { AutoSizer, List } from 'react-virtualized';
import { debounce, keys } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { blocksSelector, entityBlock } from '../../../store/old/Blocks/Blocks.selector';
import { depthFirstTraversal, getAllLeaves, uiReadableDatetimeFormat } from '../../utils/helpers';
import { fetchAssetsProduction } from '../../../store/old/Assets/Assets.action';
import { ShopfloorLineCard, calcLineCardHeight } from './ShopFloorCards';
import { getBlocksTreeData } from '../../utils/blocks';
import { NoData } from '../../components/None';
import moment from 'moment';
import { LocationBreadcrumbs } from '../../components/Breadcrumbs';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';

const overscanCount = 3;
const pollMs = 60000;

const memo = { visited: new Set() };
const setVisited = (val) => (memo.visited = val);

const ShopfloorBreadcrumbs = () => {
    return <LocationBreadcrumbs />;
};

const ShopFloor = (props) => {
    const listComponentRef = useRef();
    const dispatch = useDispatch();
    const blocks = useSelector(blocksSelector);
    const rootBlock = useSelector(entityBlock);
    const tabVisible = useSelector((appState) => appState.ui.window.visibility);

    const { entity_id } = rootBlock;
    const [listWidth, setListWidth] = useState(0);
    const [sectionRendered, setSectionRendered] = useState({ up: 0, down: 0 });
    const [_filterSelection, setFilterSelection] = useState(
        keys(blocks).map((blockId) => +blockId)
    );
    const [shiftProductionData, setShiftProductionData] = useState({});
    const [time, setTime] = useState(moment()); // poll timer

    const filterOptions = useMemo(
        () => getBlocksTreeData(rootBlock),
        [rootBlock]
    );

    const blocksAssetsTreeMap = useMemo(() => {
        const result = {};

        depthFirstTraversal(rootBlock, (node) => {
            const assets = getAllLeaves(node, (leaf) => leaf.asset_id).map(
                ({ asset }) => asset
            );
            result[node.block_id] = assets;
        });

        return result;
    }, [blocks, rootBlock]);

    const getAssetIds = useCallback((_linesSelected, _visited) => {
        const _assetIds = _linesSelected.reduce(
            (acc, curr) =>
                acc.concat(
                    ...blocksAssetsTreeMap[curr.block_id].map((a) => a.asset_id)
                ),
            []
        );
        const assetsNotVisited = _assetIds.filter((id) => !_visited.has(id));
        return assetsNotVisited.join(',');
    }, []);

    const getData = useCallback((_assetIds, cb) => {
        dispatch(
            fetchAssetsProduction(
                entity_id,
                { with: 'current_shift', asset_ids: _assetIds },
                (res) => cb && cb(res)
            )
        );
    }, []);

    const getLinesSelected = useCallback(
        (_nodes) => {
            const _assets = _nodes
                .filter((id) => blocks[id].asset)
                .map((id) => blocks[id]);

            const linesSet = new Set();

            _assets.forEach((assetBlock) => {
                if (assetBlock.parent_block_id)
                    linesSet.add(
                        assetBlock.parent_block_id !== rootBlock.block_id
                            ? assetBlock.parent_block_id
                            : assetBlock.block_id
                    );
            });

            const lines = Array.from(linesSet).sort(
                (a, b) => blocks[a].hierarchy_level - blocks[b].hierarchy_level
            );

            let resultSet = new Set();
            lines.forEach((l) => {
                if (blocks[l].parent_block_id) {
                    const parents = blocks[l].hierarchy.filter(
                        (b) => b.hierarchy_level > 1
                    );

                    const isDisplayingParent = parents.reduce(
                        (acc, curr) => (acc ? acc : resultSet.has(curr.block_id)),
                        false
                    );

                    !isDisplayingParent && resultSet.add(l);
                }
            });

            return Array.from(resultSet)
                .map((blockId) => blocks[blockId])
                .sort((a, b) => {
                    if (a.hierarchy_level !== b.hierarchy_level)
                        return a.hierarchy_level - b.hierarchy_level;
                    return a.order - b.order;
                });
        },
        [blocks]
    );

    const getFilteredLineAssets = useCallback(
        (_line, _blockAssetsTreeMap, _filterList) => {
            const filterSet = new Set(_filterList);
            return _blockAssetsTreeMap[_line.block_id].filter((a) =>
                filterSet.has(a.block_id)
            );
        },
        []
    );

    const getLinesInViewport = useCallback((_section, _lines) => {
        return _lines.slice(_section.up, _section.down);
    }, []);

    const linesSelected = useMemo(
        () => getLinesSelected(_filterSelection),
        [_filterSelection]
    );

    const _onRowsRendered = ({ startIndex, stopIndex }) => {
        const up = Math.max(0, startIndex - overscanCount),
            down = stopIndex + overscanCount;

        setSectionRendered({ up, down });
    };

    const _getRowHeight = useCallback(
        (rowData, width) => {
            const numDisplayedAssets = getFilteredLineAssets(
                rowData,
                blocksAssetsTreeMap,
                _filterSelection
            ).length;

            return calcLineCardHeight(width, numDisplayedAssets);
        },
        [blocksAssetsTreeMap, _filterSelection]
    );

    const _rowRenderer = useCallback(
        (rowData, { key, style }) => {
            const assets = getFilteredLineAssets(
                rowData,
                blocksAssetsTreeMap,
                _filterSelection
            );

            return (
                <div key={key} style={style}>
                    <ShopfloorLineCard
                        data={{
                            line: rowData,
                            assets,
                            production: shiftProductionData,
                        }}
                    />
                </div>
            );
        },
        [blocksAssetsTreeMap, _filterSelection, shiftProductionData]
    );

    // recompute row heights when screen size changes
    useEffect(() => {
        if (listComponentRef && listComponentRef.current) {
            listComponentRef.current.recomputeRowHeights();
        }
    }, [listWidth, _filterSelection]);

    // poll with memoization
    useDidUpdateEffect(() => {
        const timerId = setTimeout(() => setTime(moment()), pollMs);
        return () => clearTimeout(timerId);
    }, [time]);

    // re-initialize poll timer when filter is changed
    useEffect(() => setTime(moment()), [linesSelected]);

    useEffect(() => {
        if (!tabVisible) return;

        const linesInViewport = getLinesInViewport(sectionRendered, linesSelected);
        const assetIds = getAssetIds(linesInViewport, new Set());
        if (!assetIds) return;

        setVisited(new Set(assetIds.split(',').map(id => +id)));
        getData(assetIds, (res) => {
            setShiftProductionData({
                ...shiftProductionData,
                ...res.reduce(
                    (acc, curr) => ({
                        ...acc,
                        [curr.asset_id]: curr.current_shift,
                    }),
                    []
                ),
            });
        });
    }, [time]);

    const fetchOnSectionRendered = useCallback(
        debounce((_linesInViewport) => {
            const assetIds = getAssetIds(_linesInViewport, memo.visited);
            if (!assetIds) return;

            setVisited(new Set(Array.from(memo.visited).concat(assetIds.split(',').map(id => +id))));
            getData(assetIds, (res) => {
                setShiftProductionData({
                    ...shiftProductionData,
                    ...res.reduce(
                        (acc, curr) => ({
                            ...acc,
                            [curr.asset_id]: curr.current_shift,
                        }),
                        []
                    ),
                });
            });
        }, 500),
        [shiftProductionData]
    );

    const shouldFetchOnSectionRendered = linesSelected && sectionRendered;
    useEffect(() => {
        const linesInViewport = getLinesInViewport(sectionRendered, linesSelected);
        fetchOnSectionRendered(linesInViewport);
    }, [shouldFetchOnSectionRendered]);

    useEffect(() => {
        return () => {
            memo.visited = new Set();
        };
    }, []);

    return (
        <SPAWrapper className="shopfloor-dashboard">
            <div className="shopfloor-dashboard__header">
                <div className="pb-2">
                    <ShopfloorBreadcrumbs />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <AukFilterSelectMulti
                        className="shopfloor-dashboard__filter"
                        data={[filterOptions]}
                        treeDefaultExpandedKeys={[rootBlock.block_id]}
                        treeNodeFilterProp="label"
                        onChange={setFilterSelection}
                        maxTagCount={0}
                        allowClear={false}
                        suffixIcon={
                            <>
                                <FilterOutlined className="mr-2" /> Select Lines
                            </>
                        }
                    />
                    <div>{time.format(uiReadableDatetimeFormat)}</div>
                </div>
            </div>
            <div className="shopfloor-dashboard__body">
                {_filterSelection.length ? (
                    <AutoSizer onResize={({ width }) => setListWidth(width)}>
                        {({ width, height }) => {
                            return (
                                <List
                                    ref={listComponentRef}
                                    width={width}
                                    height={height}
                                    rowCount={linesSelected.length}
                                    onRowsRendered={_onRowsRendered}
                                    rowHeight={({ index }) =>
                                        _getRowHeight(linesSelected[index], width)
                                    }
                                    rowRenderer={({ key, style, index }) =>
                                        _rowRenderer(linesSelected[index], { key, style })
                                    }
                                />
                            );
                        }}
                    </AutoSizer>
                ) : (
                    <NoData
                        className="w-100 h-100"
                        description="Please select a line/asset"
                    />
                )}
            </div>
        </SPAWrapper>
    );
};

export default withEntity(ShopFloor);
