import { call, put, takeLatest } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
// import { changeLanguage } from '@aukindustries/auk-components';

import { User, parseUserArguments } from '../../../legacy/models';

// ACTION
import {
    AuthenticateSuccess,
    AuthenticateFailure,
    LogOutSuccess,
    LogOutFailure,
    FetchUserSuccess,
    FetchUserFailure,
    ChangePasswordSuccess,
    ChangePasswordFailure,
    ResetPasswordSuccess,
    ResetPasswordFailure,
    VerifyEmailSuccess,
    VerifyEmailFailure,
    ResetPasswordWithCodeSuccess,
    ResetPasswordWithCodeFailure,
    SignUpSuccess,
    SignUpFailure,
    Authenticate,
    UpdateUserInformation,
} from './Authentication.action';
import { setUserPreferences } from '../Preference/Preference.action';
import {
    setActiveEntity,
} from '../Entity/Entity.action';

// SESSION STORE
import { session } from '../../../legacy/auxStore';

// REDUX
import { store } from '../..';


// CONSTANT
import { AuthConstants as K } from './Authentication.constants';

// AUTHENTICATION SERVICES
import {
    api_authenticateUser,
    api_logoutUser,
    api_updateUserPassword,
    api_resetUserPassword,
    api_verifyUserEmail,
    api_resetUserPasswordWithCode,
    api_signupUser,
} from './Authentication.services';
import { api_getUser, api_updateUser } from '../User/User.services';
import { errorFlash, flash } from '../../../legacy/components/Flash';


// LOGIN FLOW
function* handleSignIn(data) {
    try {
        const response = yield api_authenticateUser({
            username: data.username,
            password: data.password,
        });

        session.authenticate(response);

        const newUser = new User(
            response.user_id,
            response.email,
            response.name_first,
            response.name_last,
            response.mobile,
            response.country_code,
            response.role_name,
            response.appointment,
            response.entity_id,
            response.confirmed,
            response.user_img,
            response.user_img_id,
            response.policy
        );

        yield put(AuthenticateSuccess(newUser));
        if (data.callback) {
            data.callback(newUser);
        }
    } catch (error) {
        errorFlash(error);
        yield put(AuthenticateFailure(error));
    }
}

export function* login() {
    yield takeLatest(
        K.ACTIONS.LOGIN_REQUEST,
        handleSignIn
    );
}

// LOGOUT FLOW
function* handleLogOut(data) {
    try {
        yield api_logoutUser();
        session.logout();
        yield put(LogOutSuccess());
        if (data.callback) {
            data.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(LogOutFailure(error));
    }
}

export function* logoutSaga() {
    yield takeLatest(
        K.ACTIONS.LOGOUT_REQUEST,
        handleLogOut
    );
}

// FETCH USER INFORMATION FLOW
function* handleFetchUserInformation(data) {
    try {
        const user_id = session.user_id;

        const response = yield call(api_getUser, user_id);

        const currentEntityId = store.getState().entity.active;
        const entity_id = currentEntityId !== -1 ? currentEntityId : response.entity_id;
        currentEntityId !== entity_id && (yield put(setActiveEntity(entity_id)));

        !isEmpty(response.preference) &&
      (yield put(setUserPreferences(response.preference)));

        if (response.preference && response.preference.language) {
            // yield put(changeLanguage(response.preference.language));
        }

        const newUser = new User(...parseUserArguments(response));
        yield put(FetchUserSuccess(newUser));

        if (data.callback) {
            data.callback(newUser);
        }
    } catch (error) {
        errorFlash(error);
        yield put(FetchUserFailure(error));
    }
}

export function* fetchUserInformation() {
    yield takeLatest(
        K.ACTIONS.FETCH_USER_INFORMATION_REQUEST,
        handleFetchUserInformation
    );
}

// CHANGE PASSWORD FLOW
function* handleChangePassword(request) {
    try {
        yield api_updateUserPassword({
            user_id: request.user_id,
            data: request.data,
        });
        yield put(ChangePasswordSuccess());
        if (request.callback) {
            request.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(ChangePasswordFailure(error));
    }
}

export function* changePassword() {
    yield takeLatest(
        K.ACTIONS.CHANGE_PASSWORD_REQUEST,
        handleChangePassword
    );
}

// RESET PASSWORD FLOW
function* handleResetPassword(request) {
    try {
        yield api_resetUserPassword({ email: request.email });
        yield put(ResetPasswordSuccess());
        if (request.callback) {
            request.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(ResetPasswordFailure(error));
    }
}

export function* resetPassword() {
    yield takeLatest(
        K.ACTIONS.RESET_PASSWORD_REQUEST,
        handleResetPassword
    );
}

// VERIFY EMAIL FLOW
function* handleVerifyEmail(request) {
    try {
        yield api_verifyUserEmail({
            user_id: request.user_id,
            data: { email: request.email, token: request.token },
        });
        yield put(VerifyEmailSuccess());
        if (request.callback) {
            request.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(VerifyEmailFailure(error));
    }
}

export function* verifyEmailSaga() {
    yield takeLatest(
        K.ACTIONS.VERIFY_EMAIL_REQUEST,
        handleVerifyEmail
    );
}

// RESET PASSWORD WITH CODE
function* handleResetPasswordWithCode(action) {
    try {
        const response = yield api_resetUserPasswordWithCode({
            email: action.payload.email,
            reset_code: action.payload.reset_code,
            password: action.payload.password,
            confirm_password: action.payload.confirm_password,
        });
        yield put(ResetPasswordWithCodeSuccess());
        flash({ message: response.message, status: 'success' });

        if (action.callback) {
            action.callback();
        }
    } catch (error) {
        errorFlash(error);
        yield put(ResetPasswordWithCodeFailure(error));
    }
}

export function* resetPasswordWithCodeSaga() {
    yield takeLatest(
        K.ACTIONS.RESET_PASSWORD_WITH_CODE_REQUEST,
        handleResetPasswordWithCode
    );
}

// SIGN UP FLOW
function* handleSignUp(request) {
    try {
        yield api_signupUser(request.payload);

        yield put(SignUpSuccess());

        yield put(
            Authenticate(
                {
                    username: request.payload.email,
                    password: request.payload.password,
                },
                () => {
                    if (request.callback) {
                        request.callback();
                    }
                }
            )
        );
    } catch (error) {
        errorFlash(error);
        yield put(SignUpFailure(error));
    }
}

export function* signUpSaga() {
    yield takeLatest(
        K.ACTIONS.SIGNUP_REQUEST,
        handleSignUp
    );
}

function* handleUpdateUser(action) {
    try {
        const { payload } = action;
        const res = yield call(api_updateUser, payload.user_id, payload.user);
        yield put(UpdateUserInformation(payload.user));

        flash({ message: 'User information updated', status: 'success' });
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateUserSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_USER_REQUEST,
        handleUpdateUser
    );
}
