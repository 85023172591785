export const zh = {
    notes: '笔记',
    load: '取',
    export: '下载',
    unutilised: '未利用',
    breakdowns: '故障',
    threshold: '阈',
    oeepreview: 'OEE预览',
    heatmappreview: '热图预览',
    oeecolors: 'OEE颜色',
    color: '颜色',
    reset: '重启',
    theme: '主题',
    defaultview: '默认视图',
    defaulttimeselection: '默认时间选择',
    zoomlevel: '缩放级别',
    entity: '实体',
    preferences: '优先',
    custom: '习惯',
    enteryourmailtoreset: '输入您的邮件重置',
    goback: '回去',
    resetpassword: '重设密码',
    logout: '登出',
    ownersemail: '所有者电子邮件',
    optional: '可选的',
    industry: '行业',
    timezone: '时区',
    nocoderequesttojoin: '无码可加入',
    enterreferralcodeinstead: '输入推荐码代替',
    setup: '建立',
    company: '公司',
    referralcode: '推荐代码',
    submit: '提交',
    summary: '摘要',
    language: '语言',
    login: '登录',
    signin: '登入',
    signup: '注册',
    username: '用户名',
    password: '密码',
    forgotpassword: '忘记密码？',
    rememberme: '记得我',
    register: '寄存器',
    registration: '注册',
    firstname: '名字',
    lastname: '姓',
    email: '电子邮件',
    mobile: '移动',
    country: '国家',
    contactnumber: '联系电话',
    appointment: '约定',
    role: '角色',
    admin: '管理',
    owner: '所有者',
    editor: '编辑',
    viewer: '观众',
    confirmpassword: '确认密码',
    iacceptthe: '我接受',
    useragreement: '用户协议',
    alreadyregisteredlogin: '已经登记了？登录',
    softwareasaservicetermsofuse: '软件作为一种服务使用条款',
    dashboard: '仪表板',
    streaming: '主流媒体',
    resolution: '解析度',
    seconds: '秒',
    second: '第二',
    minutes: '分钟',
    minute: '分钟',
    hours: '小时',
    hour: '小时',
    weeks: '周',
    week: '周',
    months: '个月',
    month: '月',
    quarters: '住处',
    quarter: '25美分硬币',
    years: '年份',
    year: '年',
    from: '起点',
    to: '至',
    start: '开始',
    end: '结束',
    display: '显示',
    oee1: 'OEE-1',
    oee2: 'OEE2',
    timeseries: '时间序列',
    gateway: '网关',
    statusasof: '地位的',
    active: '活性',
    offline: '离线',
    mergeasonemachine: '合并一体机',
    drawarrowstoformprocessmap: '绘制箭头形式过程图',
    openblockeditor: '打开块编辑器',
    createnewtile: '创建新的瓷砖',
    summarytile: '摘要瓷砖',
    selectablockthennameyoursummarytile: '选择一个块，然后命名你的总结瓷砖',
    name: '名称',
    blockselector: '块选择',
    asset: '财富',
    therearenolabelstaggedinthisperiod: '有没有标记在这个时期标签',
    standardtimes: '标准时间（s）',
    total: '总',
    last: '持续',
    oeeinput: 'OEE输入',
    chartcolor: '图的颜色',
    issueeditor: '编辑发行',
    issuemangement: '发行管理CHINESE',
    cumulativeoutput: '累计输出',
    tag: '标签',
    label: '标签',
    valueval: '值（VAL）',
    time: '时间',
    januaryjan: '一月（一月）',
    februaryfeb: '月（2月）',
    marchmar: '月（3月）',
    aprilapr: '月（4月）',
    may: '可能',
    junejun: '月（6月）',
    julyjul: '月（7月）',
    augustaug: '月（8月）',
    septembersep: '月（9月）',
    octoberoct: '月（10月）',
    novembernov: '月（11月）',
    decemberdec: '十二月（十二月）',
    day: '天',
    days: '天',
    mondaymonm: '周一（周一）M',
    tuesdaytuet: '周二（星期二）笔',
    wednesdaywedw: '周三（周三）w',
    thursdaythut: '星期四（星期四）笔',
    fridayfrif: '周五（星期五）的F',
    saturdaysats: '星期六（星期六）s',
    sundaysuns: '周日（星期日）s',
    edit: '编辑',
    add: '加',
    new: '新',
    create: '创造',
    search: '搜索',
    close: '关',
    cancel: '取消',
    save: '保存',
    saved: '保存',
    clear: '明确',
    enter: '输入',
    navigator: '航海家',
    mandatoryfields: '必须填写',
    select: '选择',
    merge: '合并',
    delete: '删除',
    done: '完成',
    filter: '过滤',
    table: '表',
    columns: '列',
    rows: '行',
    cell: '细胞',
    page: '页',
    previous: '以前',
    next: '下一个',
    serialnumber: '序列号',
    manuallyinputserialnumber: '手动输入序列号',
    updateasset: '更新资产',
    machine: '机',
    brand: '牌',
    model: '模型',
    nodes: '节点',
    fusions: '融合',
    chartorder: '图秩序',
    primary: '主',
    adddevicechannel: '添加设备 - 通道',
    pushratesec: '推进速率（秒）',
    voltagev: '电压（V）',
    sensor: '传感器',
    mode: '模式',
    digitalcount: '数字计数',
    digitalstate: '数字状态',
    analogvoltage: '模拟电压',
    charttitle: '图表标题',
    unitmeasure: '测量单位',
    attribute: '属性',
    autocycletime: '自动循环时间',
    unittransform: '单位变换',
    leaveblankifnotapplicable: '留空如果不适用',
    mandatoryfieldsleaveblankifchannelnotinuse:
    '必填字段（留空，如果通道未使用）',
    fusedchart: '电熔图',
    fusionname: '融合名称',
    charttype: '图表类型',
    column: '柱',
    line: '线',
    state: '州',
    expression: '表达',
    combination: '组合',
    currentcharts: '当前图表',
    expressionconstructor: '表达式构造',
    validoperators: '有效的运营商',
    leaveblanktokeepexistingexpression: '留作空白，以保持现有的表达',
    oee: 'OEE',
    overallequipmenteffectiveness: '整体设备效率',
    legend: '传说',
    loading: '载入中',
    availability: '可用性',
    performance: '性能',
    quality: '质量',
    unscheduled: '未计划',
    planneddowntime: '计划停机',
    changeoversetup: '转换/设置',
    breakdown: '分解',
    unutilized: '未利用',
    speedloss: '速度损失',
    minorstops: '轻微停止',
    rework: '重工',
    rejectscrap: '拒绝/废料',
    nodata: '没有数据',
    blockeditor: '块编辑器',
    sort: '分类',
    logicblocks: '逻辑块',
    sequence: '序列',
    and: '和',
    or: '要么',
    utilizationheatmap: '利用热图',
    startdate: '开始日期',
    enddate: '结束日期',
    daily: '日常',
    weekly: '每周',
    monthly: '每月一次',
    quarterly: '季刊',
    yearly: '每年',
    trendanalysis: '趋势分析',
    metricselection: '度量选择',
    metric: '公',
    data: '数据',
    target: '目标',
    periodselection: '周期选择',
    span: '跨度',
    every: '一切',
    repeat: '重复',
    repeats: '重复',
    addsingle: '添加单',
    paretoanalysis: '帕累托分析',
    calendartime: '日历时间',
    scheduledtime: '计划的时间',
    operatingtime: '工作时间',
    netruntime: '净运行时间',
    effective: '有效',
    loadingloss: '加载损失',
    availabilityloss: '可用性损失',
    performanceloss: '性能损失',
    qualityloss: '质量损失',
    notagsinthiscategory: '此类别中没有标签',
    overall: '总体',
    tagged: '标记',
    occurrence: '发生',
    skumanagement: 'SKU管理',
    category: '类别',
    skuname: 'SKU名称',
    skucode: 'SKU码',
    unitofmeasurementuom: '测量的单元（UOM）',
    description: '描述',
    csvupload: '上传CSV',
    template: '模板',
    notifications: '通知',
    newnotification: '新通知',
    monitoring: '监控',
    topic: '话题',
    frequency: '频率',
    recipients: '收件人',
    notifyby: '通过通知',
    sms: '短信',
    alert: '警报',
    report: '报告',
    greaterthan: '比...更棒',
    lessthan: '少于',
    greaterthanorequal: '大于或等于',
    lessthanorequal: '小于或等于',
    equal: '等于',
    range: '范围',
    continuousduration: '持续时间',
    usermanagement: '用户管理',
    user: '用户',
    users: '用户',
    invitenew: '邀请新',
    status: '状态',
    resend: '重发',
    revoke: '撤消',
    invite: '邀请',
    invitees: '受邀者',
    addinviteesbypressingenterorclickingonthesign:
    '按Enter键或点击“+”号添加被邀请者',
    schedules: '时间表',
    shift: '转移',
    profile: '简介',
    changepassword: '更改密码',
    deleteaccount: '删除帐户',
    userguide: '用户指南',
    overviewandbasiccontrol: '概述和基本控制',
    rangeandresolution: '范围和分辨率',
    editingoptions: '编辑选项',
    mergetiles: '合并瓷砖',
    detailedassetview: '详细的资产视图',
    assetoverview: '资产概述',
    summarystatistics: '汇总统计',
    dataanalysis: '数据分析',
    oeeanoverview: 'OEE的概述',
    oeetoggle: 'OEE切换',
    addingamachine: '添加机',
    nodeconfiguration: '节点配置',
    deleteupdateassets: '删除更新资产',
    oeesummarytile: 'OEE总结瓦',
    oeetags: 'OEE标签',
    issuemanagement: '问题管理',
    labelsandfiltering: '标签和过滤',
    useraccess: '用户访问',
    group: '组',
    groups: '组',
    joincompany: '加入公司',
    notification: '通知',
    createnotification: '创建通知',
    node: '节点',
    downtime: '停机',
    downtimes: '停机时间',
    repeatstartdate: '重复开始日期',
    repeatenddate: '重复结束日期',
    optional: '可选的',
    createplan: '创建计划',
    today: '今天',
    starttime: '开始时间',
    endtime: '时间结束',
    chart: '图表',
    reject: '拒绝',
    accept: '接受',
    filterbydaterange: '按日期范围过滤器',
    filterbymostrecent: '通过最近的过滤器',
    result: '结果',
    type: '类型',
    duration: '持续时间',
    by: '通过',
    title: '标题',
    assigned: '分配',
    action: '行动',
    track: '跟踪',
    issue: '问题',
    priority: '优先',
    requestor: '请求者',
    opened: '打开',
    assignee: '代理人',
    root: '根',
    cause: '原因',
    identified: '鉴定',
    defined: '定义',
    date: '日期',
    resolve: '解决',
    resolved: '解决',
    targetresolvedate: '目标的决心日期',
    procurement: '采购',
    choose: '选择',
    analyses: '分析',
    source: '资源',
    selection: '选择',
    historical: '历史的',
    mostrecent: '最近的',
    regression: '回归',
    trendline: '趋势线',
    upperbound: '上界',
    lowerbound: '下界',
    preview: '预习',
    period: '期',
    series: '系列',
    axis: '轴',
    youhaveno: '你有没有',
    code: '码',
    all: '所有',
    upload: '上传',
    regressionanalysis: '回归分析',
    issuetracker: '问题跟踪',
    labelsearch: '标签搜索',
    gatewaysetup: '网关设置',
    setupgateway: '设置网关',
    radiomesh: '广播网',
    targetsetting: '目标设定',
    updatetrackedissue: '更新跟踪问题',
    shownewtrackedissue: '显示新跟踪的问题',
    low: '低',
    mid: '中',
    high: '高',
    linear: '线性',
    exponential: '指数',
    logarithmic: '对数的',
    quadratic: '二次',
    polynomial: '多项式',
    general: '一般',
    datataggingenrichment: '数据标记富集',
    hardware: '硬件',
    nodemanagement: '节点管理',
    createeditnotification: '创建编辑通知',
    conditions: '条件',
    addsku: '添加SKU',
    editsku: '编辑SKU',
    setupagateway: '设置网关',
    deviceserialnumber: '设备序列号',
    repeatsweekly: '每周重复',
    repeatsdaily: '每天重复',
    repeatsmonthly: '每月重复',
    repeatsannually: '每年重复',
    repeatsevery: '每天重复',
    hourly: '每小时',
    biweekly: '双周',
};
