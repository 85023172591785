/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import {
    CreateLabel,
    DeleteLabel,
    StopLabel,
    UpdateLabel,
} from '../../../store/old/Labels/Labels.action';

const withLabelling = (
    Component
) => {
    return (componentProps) => {
        const dispatch = useDispatch();
        const showLabelNotes = useSelector(
            (appState) =>
                !!(
                    appState.preference.entity.options &&
          appState.preference.entity.options.label_notes
                )
        );

        const createLabel = (
            asset_id,
            label,
            cb
        ) => dispatch(CreateLabel(asset_id, label, cb));
        const updateLabel = (
            asset_id,
            label,
            cb
        ) => dispatch(UpdateLabel(asset_id, label, cb));
        const deleteLabel = (
            asset_id,
            label,
            cb
        ) => dispatch(DeleteLabel(asset_id, label, cb));
        const stopLabel = (
            asset_id,
            label,
            cb
        ) => dispatch(StopLabel(asset_id, label, cb));
        return (
            <Component
                {...componentProps}
                createLabel={createLabel}
                updateLabel={updateLabel}
                deleteLabel={deleteLabel}
                stopLabel={stopLabel}
                showLabelNotes={showLabelNotes}
            />
        );
    };
};

export default withLabelling;
