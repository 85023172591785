/* eslint-disable react/prop-types */
import * as d3 from 'd3';
import React from 'react';
import { chartOeeKeys, oeeChartAccessors } from '../utils/oee';
import { getStackColumnLabels, StackColumnChart } from './v1';
import { OEEChartTooltip } from './v1/tooltips/templates';

const OEEStackColumnChart = ({
    margin,
    data = [],
    xDomain = [new Date(), new Date()],
    brush = {},
}) => {
    return (
        <StackColumnChart
            xScale={d3.scaleTime()}
            yScale={d3.scaleLinear()}
            data={data}
            margin={margin}
            xDomain={xDomain}
            yDomain={[0, 100]}
            keys={chartOeeKeys}
            getDataLabels={getStackColumnLabels}
            htmlTooltip={OEEChartTooltip}
            yAccessor={oeeChartAccessors.y}
            colorAccessor={oeeChartAccessors.color}
            useDataLabels={data.length < 20}
            {...brush}
        />
    );
};

export default OEEStackColumnChart;
