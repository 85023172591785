import React from "react"
import { Outlet } from "react-router-dom"
// import PlainTemplate from "../Template/PlainTemplate/PlainTemplate"
import LanguageButton from "../Button/LanguageButton/LanguageButton"

const LayoutUnauthenticated = (props: any) => {
    // return <PlainTemplate><Outlet/></PlainTemplate>
    return <div >
        <LanguageButton className="language"/>
        <Outlet/>
    </div>
}

export default LayoutUnauthenticated
