/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'reactstrap';

const ConfirmationModal = (props) => (
    <Modal
        isOpen={props.showConfirmation}
        toggle={props.toggleConfirmation}
        backdrop="static"
    >
        <div className="panel confirmation-modal">
            <div className="panel-header">
                <p className="panel-title">
                    <i
                        className="fas fa-exclamation fa-2x"
                        style={{ color: 'rgb(204, 51, 51)' }}
                    />
                    <span style={{ marginLeft: 15 }}>{props.action}</span>
                </p>
            </div>
            <div className="panel-body">
                <div className="container" style={{ padding: 20 }}>
                    {props.message}
                </div>
            </div>
            <div className="panel-footer">
                <button onClick={props.toggleConfirmation} className="btn b-cancel">
          CANCEL
                </button>
                <button onClick={props.onConfirmation} className="btn b-delete">
          PROCEED
                </button>
            </div>
        </div>
    </Modal>
);

export default ConfirmationModal;
