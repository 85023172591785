import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../../store/hooks"
import { DashboardConstants as K } from '../../../../store/old/UI/Dashboard/Dashboard.constants';
import { DashboardContext } from '../DashboardContext';
import AukButton from '../../../components/AukButton';
import BlockCSVExportModal from '../../Blocks/BlockCSVExportModal';
import { entityBlock } from '../../../../store/old/Blocks/Blocks.selector';
import {
    ChangeDashboardMode,
    ChangeDashboardViewMode,
} from '../../../../store/old/UI/Dashboard/Dashboard.action';
import { hasRolePermission } from '../../../components/Permission';
import translate from '../../../utils/translate';

export const ControlButtons = () => {
    const {
        store,
        authUser,
        canViewDashboard,
        canEditDashboard,
        canFullyEditDashboard,
    } = useContext(DashboardContext);

    const dispatch = useDispatch();
    const block = useSelector(entityBlock);
    const [exportVisible, setExportVisibility] = useState(false);
    const changeMode = (m) => dispatch(ChangeDashboardMode(m));

    const { mode } = store;
    const isViewing = mode === K.MODES.VIEW;
    const hasEditorRights = hasRolePermission(authUser.role_name, 'editor');

    return (
        <>
            <div className="control-buttons">
                <AukButton.Outlined
                    className={`auk-button--round`}
                    type={isViewing ? 'primary' : 'default'}
                    onClick={() => changeMode(K.MODES.VIEW)}
                    disabled={!canViewDashboard}
                >
                    <i className="fas fa-eye" />
                </AukButton.Outlined>
                <AukButton.Outlined
                    className={`auk-button--round`}
                    type={isViewing ? 'default' : 'primary'}
                    disabled={!(canEditDashboard || canFullyEditDashboard)}
                    onClick={() => changeMode(K.MODES.EDIT)}
                >
                    <i className="fas fa-pencil-alt" />
                </AukButton.Outlined>
                <AukButton.Outlined
                    className="auk-button--round"
                    onClick={() => setExportVisibility(true)}
                    disabled={!canViewDashboard || !hasEditorRights}
                >
                    <i className="fas fa-download" />
                </AukButton.Outlined>
            </div>
            <BlockCSVExportModal
                visible={exportVisible}
                onCancel={() => setExportVisibility(false)}
                block={block}
            />
        </>
    );
};

const VIEW_MODES = [
    { id: 1, label: 'Time', value: K.VIEW.TIME_SERIES },
    { id: 2, label: 'Sum', value: K.VIEW.SUMMARY },
    { id: 3, label: 'Label', value: K.VIEW.LABEL },
];

export const ViewOptions = () => {
    const dispatch = useAppDispatch();
    const { store } = useContext(DashboardContext);
    const { viewMode, mode } = store;

    const changeViewMode = (m) => dispatch(ChangeDashboardViewMode(m))
    const isViewing = mode === K.MODES.VIEW

    if (isViewing) {
        return null
    }

    return <div className="subcontrols">
        <div className="view-row">
            {VIEW_MODES.map((m, i) => {
                const active = viewMode === m.value;

                return (
                    <AukButton.Outlined
                        size="small"
                        key={i}
                        type={active ? 'primary' : 'default'}
                        onClick={() => changeViewMode(m.value)}
                    >
                        {translate(m.label)}
                    </AukButton.Outlined>
                );
            })}
        </div>
    </div>
};
