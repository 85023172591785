import { mapValues, round } from 'lodash';
import CONSTANTS from '../Constants';
import { store } from '../../store';

export const OEEFactory = ({ oee = [], effective_labels = [] }) => {
    // oee === OEE1/TEEP
    const teep = oeeTimeSeriesParser(oee);
    return {
        oee: teep,
        effective_labels, // used for asset pareto analysis
        get oee2() {
            delete this.oee2;
            return (this.oee2 = oee2TimeSeries(teep));
        },

        get aggregate_oee() {
            delete this.aggregate_teep;
            return (this.aggregate_teep = oeeReducedPercent(teep));
        },

        get aggregate_oee2() {
            delete this.aggregate_oee2;

            const { waterfall } = this;
            if (!waterfall.length) {
                return (this.aggregate_oee2 = { totalSeconds: 0 });
            }

            const productionTime = waterfall.find(({ key }) => key === 'pt').duration;
            const categories = waterfall.filter(
                ({ type, loss, key }) =>
                    (type === 'loss' && loss !== 'loa') || key === 'ef'
            );

            const result = categories.reduce(
                (acc, curr) => {
                    return {
                        ...acc,
                        [curr.key]: (curr.duration / productionTime) * 100,
                    };
                },
                {
                    totalSeconds: productionTime,
                }
            );

            return (this.aggregate_oee2 = result);
        },

        get overall() {
            delete this.overall;

            const { factors } = this;

            const loading = factors[2].value;
            const availability = factors[3].value;
            const performance = factors[4].value;
            const quality = factors[5].value;
            const final_effective = (availability * performance * quality) / 10000;

            return (this.overall = {
                loading: round(loading, 2),
                availability: round(availability, 2),
                performance: round(performance, 2),
                quality: round(quality, 2),
                final_effective: round(final_effective, 2),
            });
        },

        get waterfall() {
            delete this.waterfall;
            return (this.waterfall = oeeWaterfall(teep));
        },

        get factors() {
            delete this.factors;
            return (this.factors = oeeFactors(this.waterfall));
        },
    };
};

export const getMaskedOEE = (masks) => {
    return Object.keys(CONSTANTS.CAT).map((k) => {
        if (masks[k]) {
            const { abbreviation: masked, mask } = masks[k];
            const prefix = `${masked.toUpperCase()}`;
            return {
                ...CONSTANTS.CAT[k],
                key: k,
                prefix,
                name: `[${prefix}] ${mask}`,
                altLabel: mask,
            };
        }
        return { ...CONSTANTS.CAT[k], key: k };
    });
};

const mapLabelsToIssue = (labels) => {
    return labels.reduce((acc, curr) => {
        if (!curr.issue) {
            return acc;
        }

        const {
            issue: { issue_id },
        } = curr;
        acc[issue_id] = acc[issue_id] ? acc[issue_id].concat(curr) : [curr];

        return acc;
    }, {});
};

// export const OEE_ABBREVIATIONS = {
//   Effective: 'ef',
//   'Un-utilised': 'uu',
//   Unscheduled: 'us',
//   'Planned Downtime': 'pd',
//   Breakdown: 'bd',
//   'Setup/ Changeover': 'st',
//   'Minor Stops': 'ms',
//   'Speed Losses': 'sl',
//   Rejects: 'rj',
//   Rework: 'rw',
//   'No Data': 'na',
// };

// export const override = {
//   us: ['uu', 'pd', 'bd', 'st', 'ms'],
//   pd: ['uu', 'us', 'bd', 'st', 'ms'],
//   bd: ['uu', 'us', 'pd', 'st', 'ms'],
//   st: ['uu', 'us', 'pd', 'bd', 'ms'],
//   ms: ['uu'],
//   sl: [],
//   rj: ['ef', 'rw'],
//   rw: ['ef', 'rj'],
// };

export const oee2TimeSeries = (oee1) => {
    const oee2 = oee1.map((d) => {
        const { pd, us, na } = d.oee;
        const loadingPercent = pd + us + na;
        const scheduledPercent = 100 - loadingPercent;

        return {
            ...d,
            oee: {
                ...mapValues(d.oee, (v) => (v / scheduledPercent || 0) * 100),
                pd: 0,
                us: 0,
                na: 0,
            },
            effInt: (scheduledPercent * d.int) / 100,
        };
    });
    return oee2;
};

export const oeeTimeSeriesParser = (data) => {
    return data
        .map((d) => {
            d.time = new Date(d.time);
            d.effInt = d.int;
            return d;
        })
        .map((d) => {
            const waterfall = oeeWaterfall([d]);
            const factors = oeeFactors(waterfall);
            return {
                ...d,
                waterfall,
                factors,
            };
        })
        .sort((a, b) => a.time - b.time);
};

export const oeeReduced = (data) => {
    const categories = Object.keys(CONSTANTS.CAT);

    const result = data.reduce((acc, curr) => {
        categories.forEach((cat) => {
            const durationSeconds = ((curr.oee[cat] / 100) * curr.effInt) / 1000;
            acc[cat] = acc[cat] ? acc[cat] + durationSeconds : durationSeconds;
        });
        return acc;
    }, {});

    return result;
};

export const oeeReducedPercent = (data) => {
    const reduced = oeeReduced(data);
    const totalSeconds = Object.keys(reduced).reduce(
        (acc, curr) => (acc += reduced[curr]),
        0
    );

    const result = Object.keys(reduced).reduce((acc, curr) => {
        acc[curr] = (reduced[curr] / totalSeconds) * 100;
        return acc;
    }, {});

    result.totalSeconds = totalSeconds;

    return result;
};

export const oeeWaterfall = (data) => {
    if (!data.length) return [];

    const oeeCondensed = oeeReduced(data);

    let total_time = data.reduce((acc, curr) => (acc += curr.effInt / 1000), 0);
    let time_remaining = total_time;

    return CONSTANTS.OEE.WATERFALL_CHART.map((item) => {
        if (item.type === 'loss') {
            item.duration = oeeCondensed[item.key];
            time_remaining -= item.duration;
        } else {
            item.duration = time_remaining;
        }
        item.color = CONSTANTS.CAT[item.key]
            ? CONSTANTS.CAT[item.key].color
            : '#f2f2f2';
        item.percent_duration = (item.duration / total_time) * 100;
        return item;
    });
};

export const oeeFactors = (data) => {
    // data is of oeeWaterfall form
    const dataMap = data.reduce(
        (acc, curr) => ({ ...acc, [curr.key]: curr.percent_duration }),
        {}
    );
    const { ct, pt, gt, nt, ef } = dataMap;
    const l = pt / ct || 0;
    const a = gt / pt || 0;
    const p = nt / gt || 0;
    const q = ef / nt || 0;

    const percent = (n) => n * 100;

    return [
        { label: 'OEE1 / TEEP (%)', value: percent(l * a * p * q) },
        { label: 'OEE2 / OEE (%)', value: percent(a * p * q) },
        { label: 'Loading Factor (%)', value: percent(l) },
        { label: 'Availability Factor (%)', value: percent(a) },
        { label: 'Performance Factor (%)', value: percent(p) },
        { label: 'Quality Factor (%)', value: percent(q) },
    ];
};

export const oeePareto = (data) => {
    const issueLabels = mapLabelsToIssue(data.sort((a, b) => b.from - a.from));

    return Object.keys(issueLabels)
        .map((issue_id) => {
            const labelArray = issueLabels[issue_id];

            const total_duration = labelArray.reduce(
                (acc, curr) => acc + curr.duration,
                0
            ); // in seconds

            const total_effective_duration = labelArray.reduce(
                (acc, curr) => acc + (curr.effective_duration || 0),
                0
            ); // in seconds

            const occurrence = labelArray.length;
            const issue = labelArray[0].issue;

            return { issue, total_duration, occurrence, total_effective_duration };
        })
        .filter((d) => d.total_duration)
        .sort((a, b) => b.total_effective_duration - a.total_effective_duration);
};

export const oeeHeadersCSV = Object.keys(CONSTANTS.CAT)
    .map((k) => ({ key: k, ...CONSTANTS.CAT[k] }))
    .sort((a, b) => a.csvOrder - b.csvOrder);

export const onlyTEEP = new Set(['ct', 'us', 'pd']);

export const oeeCSV = (data) => {
    const { masks } = store.getState().ui.oee;

    data = data.concat(data.splice(1, 1)); // move no data column to bottom
    const factors = oeeFactors(data)
        .map((d) => `${d.label}, ${round(d.value, 2)}`)
        .join('\n');
    const factorsHeader = 'OEE Summary, Percent';
    const factorsBlock = [factorsHeader, factors].join('\n');

    const scheduledTime = data.find(({ key }) => key === 'pt');

    const categoriesHeader =
    'OEE Category, Percent (OEE1 / TEEP), Percent (OEE2), Duration (hrs)';
    const categories = data
        .map((d, index) => {
            const { type, key, name, percent_duration, duration } = d;
            const abbr = (masks[key] ? masks[key].abbreviation : key).toUpperCase();
            const category = masks[key] ? masks[key].mask : name;
            const percentTEEP = round(percent_duration, 2);
            const percentOEE = onlyTEEP.has(key)
                ? 'NA'
                : round((duration / scheduledTime.duration) * 100, 2);
            const durationHrs = round(duration / 3600, 2);

            const str = `[${abbr}] ${category}, ${percentTEEP}, ${percentOEE}, ${durationHrs}`;
            return type !== 'loss' && index ? `\n${str}` : str;
        })
        .join('\n');
    const categoriesBlock = [categoriesHeader, categories].join('\n');

    return [factorsBlock, categoriesBlock].join('\n\n');
};

export const oeeChartAccessors = {
    y: (d, key) => d.oee[key],
    color: (d) => CONSTANTS.CAT[d.key].color,
};

export const chartOeeKeys = Object.keys(CONSTANTS.CAT).sort((a, b) => {
    if (a === 'ef') return 1;
    return 0;
});
